<mat-toolbar class="header">
    <img class="header-brand-logo" src="./assets/gem_500x500.svg">
    <div>
        <span class="header-brand-title"> gem-lpb.com </span>
    </div>
    <button mat-button disableRipple>
        <mat-icon>person</mat-icon>
        {{curruser.username}}({{curruser.email}})
    </button>
    <button mat-icon-button routerLink="/explorer" matTooltip="explorer">
        <mat-icon>folder</mat-icon>
    </button>
    <span class="header-spacer"></span>
    <button mat-icon-button [matMenuTriggerFor]="shoppingMenu"
        matTooltip="shopping">
        <mat-icon>shopping_cart</mat-icon>
    </button>
    <mat-menu #shoppingMenu="matMenu">
        <buttoon mat-menu-item routerLink="/shopping/quota">
            <mat-icon>storage</mat-icon>
            Disk Quota
        </buttoon>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/shopping/gemlpbapp">
            <mat-icon>apps</mat-icon>
            GemLPB
        </button>
    </mat-menu>
    <button mat-icon-button [matMenuTriggerFor]="settingMenu"
        matTooltip="setting">
        <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #settingMenu="matMenu">
        <button mat-menu-item routerLink="/setting/profile">
            <mat-icon>person</mat-icon>
            Profile
        </button>
        <button mat-menu-item routerLink="/setting/preference">
            <mat-icon>settings</mat-icon>
            Preference
        </button>
        <button mat-menu-item routerLink="/setting/mnggroup">
            <mat-icon>people</mat-icon>
            Manage Group
        </button>
        <button mat-menu-item routerLink="/setting/mnglic">
            <mat-icon>star</mat-icon>
            Manage License
        </button>
    </mat-menu>
    <button mat-icon-button (click)="logout()" matTooltip="logout">
        <mat-icon>exit_to_app</mat-icon>
    </button>
</mat-toolbar>
<alert></alert>
