<button mat-icon-button class="dialog-close-button" (click)="cancel()">
    <mat-icon>close</mat-icon>
</button>
<div mat-dialog-title>
    End User Registration
</div>
<div mat-dialog-content>
    <form [formGroup]="form" (onSubmit)="ok()">
        <mat-form-field>
            <mat-label>Tool</mat-label>
            <mat-select matInput required formControlName="product">
                <mat-option *ngFor="let prod of model.product_opts" [value]="prod">
                    {{prod}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="fieldError('product')">
                {{fieldError('email')}}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>End User Email</mat-label>
            <input matInput required formControlName="email">
            <mat-error *ngIf="fieldError('email')">
                {{fieldError('email')}}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Key</mat-label>
            <mat-select matInput required formControlName="keytype">
                <mat-option value="anypc"
                    matTooltip="the user can run on any computer">
                    anypc</mat-option>
                <mat-option value="mac"
                    matTooltip="the user can run on a specific pc">
                    mac</mat-option>
                <mat-option value="host"
                    matTooltip="the user can run on a specific pc">
                    host</mat-option>
                <mat-option value="*">vary</mat-option>
            </mat-select>
        </mat-form-field>
        <ng-container *ngIf="fieldValue('keytype')==='mac'">
            <mat-form-field>
                <mat-label>mac address</mat-label>
                <input matInput required
                    formControlName="keyvalue"
                    placeholder="12-34-56-78-9A-BC">
                <mat-hint>type 'ipconfig /all' on cmd window on win-pc</mat-hint>
                <mat-error *ngIf="fieldError('keyvalue')">
                    {{fieldError('keyvalue')}}
                </mat-error>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="fieldValue('keytype')==='host'">
            <mat-form-field>
                <mat-label>hostname</mat-label>
                <input matInput required
                    formControlName="keyvalue"
                    placeholder="mypc1">
                <mat-hint>type 'hostname' on cmd window on win-pc</mat-hint>
                <mat-error *ngIf="fieldError('keyvalue')">
                    {{fieldError('keyvalue')}}
                </mat-error>
            </mat-form-field>
        </ng-container>
        <mat-form-field>
            <mat-label>Security Code</mat-label>
            <input matInput formControlName="seccode">
            <mat-hint>revise the code to revise client license</mat-hint>
        </mat-form-field>
        <!-- 2021-07-03 turned off by introducing key field
        <mat-form-field>
            <mat-label>Hostname (Regular Expression, optional)</mat-label>
            <input matInput [(ngModel)]="model.hostname_re">
            <mat-hint>Example: (mypc1|mypc2)</mat-hint>
        </mat-form-field>
        -->
        <!-- turned off considering it's too complex to specify a RE that handles both IPv4 and IPv6.
        <mat-form-field>
            <mat-label>IP-address (Regular Expression, optional)</mat-label>
            <input matInput [(ngModel)]="model.ipaddr_re">
        </mat-form-field> -->
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="ok()" cdkFocusInitial>
        OK
    </button>
</div>
