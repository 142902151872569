import { ChangePrefDtoI, UserDroI } from 'gemlib/dist_fe/dtodro';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, AbstractControl, ValidationErrors, ValidatorFn, FormControl } from '@angular/forms';

import { AlertService} from '@app/helper/alert.service';
import { AcctService } from '@app/acctservice/acct.service';

@Component({
    templateUrl: 'preference.component.html',
    styleUrls: ['preference.component.css']
})
export class PreferenceComponent {
    form: FormGroup;
    curruser: UserDroI;
    private autodir_keys = ['projs','upload','trash','backup']; // order is significant
    iscollapsed_projs = true;
    iscollapsed_upload = true;
    iscollapsed_backup = true;
    iscollapsed_trash = true;
    iscollapsed_maxtime = true;
    gemchk_gfmt_see_source = true;
    loading = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private alertService: AlertService,
        private acctService: AcctService
        ){
            this.curruser = acctService.curruser;
        }
    ngOnInit() {
        const one_hour_at_least = function(name:AbstractControl):ValidationErrors|null{
            if (Number.parseInt(name.value)>=1) return null;
            else return {'one-hour-at-least':true};
        }

        const foldernamevalidator = function(name:AbstractControl):ValidationErrors|null{
            if (!name.value) return null; // empty string "" is understood as false.
            else return new RegExp('^[a-z][a-z0-9_]{0,29}$').test(name.value)?null:{'formaterr':true};
        };
        const noproj_nobackup = function(): ValidatorFn{
            return (control:AbstractControl):ValidationErrors|null=>{
                const keys = ['projs','upload','trash','backup'];
                const vals = [];
                for(let i = 0 ; i < keys.length ; i++) vals[i] = control.get(keys[i]).value;
                var err = {};
                for(let i = 0 ; i < keys.length ; i++){
                    for(let j = i+1 ; j < keys.length ; j++){
                        if (vals[i] && vals[j] && vals[i]===vals[j]){
                            Object.defineProperty(err,keys[i],{value:'duplicated'});
                            Object.defineProperty(err,keys[j],{value:'duplicated'});
                            // { 'proj': 'duplicated', 'upload': 'duplicated', ..}
                        }
                    }
                }
                if (!vals[0]){
                    // if 'projs' directory is not specified
                    if (vals[2]) Object.defineProperty(err,keys[2],{value:'No project, no '+keys[2]});
                    if (vals[3]) Object.defineProperty(err,keys[3],{value:'No project, no '+keys[3]});
                    // then 'trash' or 'backup' cannot be specified
                    // { 'trash': 'No project, no trash', .. }
                }
                return Object.keys(err).length>0? err:null;
            }
        }
        

        this.form = this.formBuilder.group({
            projs: [this.curruser.pref?.autodir?.projs?
                this.curruser.pref.autodir.projs:'',
                [foldernamevalidator]],
            upload: [this.curruser.pref?.autodir?.upload?
                this.curruser.pref.autodir.upload:'',
                [foldernamevalidator]],
            trash: [this.curruser.pref?.autodir?.trash?
                this.curruser.pref.autodir.trash:'',
                [foldernamevalidator]],
            backup: [this.curruser.pref?.autodir?.backup?
                this.curruser.pref.autodir.backup:'',
                [foldernamevalidator]],
            jwt_expires_in_hours: [this.curruser.pref?.jwt_expires_in_hours?
                this.curruser.pref.jwt_expires_in_hours:'24',
                [one_hour_at_least]],
            },{
                validators: noproj_nobackup()
            });
        this.gemchk_gfmt_see_source = this.curruser.pref.gemchk_gfmt_see_source;
    }
    fieldError(field:string):string{
        if (!this.form.controls[field].errors) return '';
        if (this.autodir_keys.includes(field)) return 'invalid name';
        else return '1 hour at least.';
    }
    crossFieldError(field:string):string{
        if (!this.form.errors) return '';
        else if (!this.form.errors[field]) return '';
        else return this.form.errors[field];
    }

    onSubmit() {
        this.form.markAllAsTouched();
        this.alertService.clear();
        if (this.form.invalid) return;
        console.log('onSubmit() called');

        const dto:ChangePrefDtoI = {
            jwt_expires_in_hours : Number.parseInt(this.form.controls.jwt_expires_in_hours.value),
            gemchk_gfmt_see_source : this.gemchk_gfmt_see_source,
            autodir : {
                projs : this.form.controls.projs.value.trim(),
                upload : this.form.controls.upload.value.trim(),
                trash : this.form.controls.trash.value.trim(),
                backup : this.form.controls.backup.value.trim()
            }
        };
        this.acctService.change_pref(dto)
        .subscribe(
            () => {
                this.loading = false;
                this.router.navigate(['/']);
            },
            (error:HttpErrorResponse|Error) => {
                this.alertService.error(error);
                this.loading = false;
            }
        );
    }
}
