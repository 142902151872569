<button mat-icon-button class="dialog-close-button" (click)="cancel()">
    <mat-icon>close</mat-icon>
</button>
<div mat-dialog-title>
    {{model.title}}
</div>
<div mat-dialog-content>
    <ng-container *ngFor="let label of model.labels; let i = index;">
        <mat-form-field>
            <mat-label>{{label}}</mat-label>
            <input matInput [(ngModel)]="model.values[i]">
        </mat-form-field>
    </ng-container>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="ok()" cdkFocusInitial>
        OK
    </button>
</div>
