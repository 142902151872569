import { UserDroI } from 'gemlib/dist_fe/dtodro';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AcctService, Group } from '@app/acctservice/acct.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'group-table',
    templateUrl: 'group-table.component.html',
    styleUrls:['group-table.component.css']
})
export class GroupTableComponent implements OnInit {
    curruser: UserDroI;
    defaultGroupname: string;
    groups: Group[] = [];
    seldGroup: Group;
    @Output('change') change = new EventEmitter();
    groupTableCols = ["groupname","mbrcnt","usedkb","totalkb","expire"];

    constructor(
        private acctService: AcctService,
        private route: ActivatedRoute
    ){
        this.curruser = acctService.curruser;
        this.defaultGroupname = this.curruser.username;
    }
    onChange(){
        if (!this.seldGroup) return;
        this.change.emit(this.seldGroup);
    }

    ngOnInit() {
        var param = this.route.snapshot.queryParams['group'];
        if (param) this.defaultGroupname = param;
        this.refresh();
    }
    private refresh(){
        this.acctService.get_groups()
        .subscribe((groups:Group[])=>{
            this.groups = groups;
            this.seldGroup = this.findgroupbyname(this.defaultGroupname);
            if (!this.seldGroup){
                this.defaultGroupname = this.curruser.username;
                this.seldGroup = this.findgroupbyname(this.defaultGroupname);
            }
        },(err)=>{
        },()=>{
        });
    }
    private findgroupbyname(groupname:string):Group{
        for(let i = 0 ; i < this.groups.length ; i++){
            if (this.groups[i].groupname == groupname){
                return this.groups[i];
            }
        }
        return null;
    }
}