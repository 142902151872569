import { Component} from '@angular/core';
// main nav-bar for before-login status
@Component({
    selector: 'header0',
    templateUrl: 'header0.component.html', 
    styleUrls: ['header0.component.css']
})
export class Header0Component{
    constructor() {
    }
}