<header0></header0>
<div class="page-body">
    <div class="page-container-small">
        <mat-grid-list cols="1">
            <mat-card>
                <mat-card-title class="mat-title">
                    Registration step 2 of 2
                </mat-card-title>
                <mat-card-content>
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div>
                            <mat-form-field>
                                <mat-label>E-mail</mat-label>
                                <input type="email" matInput formControlName="email" readonly>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <mat-label>Security Code</mat-label>
                                <input type="text" matInput formControlName="seccode">
                                <mat-hint>
                                    find it in your email
                                </mat-hint>
                                <mat-error *ngIf="fieldError('seccode')">
                                    {{fieldError('seccode')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <mat-label>Password</mat-label>
                                <input type="password" matInput formControlName="password">
                                <mat-error *ngIf="fieldError('password')">
                                    {{fieldError('password')}}
                                </mat-error>    

                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <mat-label>Password (type again)</mat-label>
                                <input type="password" matInput formControlName="password2">
                                <mat-error *ngIf="fieldError('password2')">
                                    {{fieldError('password2')}}
                                </mat-error>    
                            </mat-form-field>
                            <!--
                                cross-field error message cannot be put in <mat-error>.
                                and better to be out of <mat-form-field> to avoid too ugly result.
                            -->
                            <div *ngIf="!fieldError('password2') && crossFieldError('nomatch')"
                                class="mat-caption place-cross-field-error-at-above-field">
                                {{crossFieldError("nomatch")}}
                            </div>
                        </div>
                    </form>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button color="primary"
                        [disabled]="loading" (click)="onSubmit()">
                        <mat-spinner *ngIf="loading"></mat-spinner>
                        Register
                    </button>
                </mat-card-actions>
                <div>
                    Security code not found in mailbox? Try <button mat-stroked-button color="warn" (click)="reissue_seccode()">reissue sec code</button>
                </div>
        </mat-card>
        </mat-grid-list>
    </div>
</div>
<div class="footer">
	<a class="footer" href="https://gemdt.com" target="_top">Gem Design Technologies, Inc.</a>
</div>
