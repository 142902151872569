import { Injectable} from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AcctService } from '@app/acctservice/acct.service';
import { AlertService } from './alert.service';
import { Router } from '@angular/router';

/**
 * insert JWT token in every http request, after logged in.
 */
@Injectable()
export class JwtTokenSendInterceptor implements HttpInterceptor{
    constructor(private acctService: AcctService){}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        const userDro = this.acctService.curruser;
        const isLoggedIn = userDro && userDro.token;
        const isApiUrl = req.url.startsWith(environment.apiUrl);
        if (isLoggedIn && isApiUrl){
            req = req.clone({
                setHeaders:{ Authorization: `Bearer ${userDro.token}`}
            });
        }
        return next.handle(req);
    }
}
/**
 * watch JWT veryfication error response from the server,
 * and force logging out.
 */
@Injectable()
export class JwtErrorReceiveInterceptor implements HttpInterceptor{
    constructor(
        private acctService: AcctService,
        private alertService: AlertService,
        private router: Router
        ){}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        return next.handle(req).pipe(
            catchError((err:HttpErrorResponse) => {
                if (err.status == 401){ // Unauthorized error
                    this.acctService.logout();
                    this.alertService.error(err,true); // maybe overide
                    location.reload();
                        // refresh the current page,
                        // which leads logged-out page if the page is for logged-in-user only.
                }
                return throwError(err);
            })
        )
    }
}