import { UserDroI } from 'gemlib/dist_fe/dtodro';
import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { AcctService } from '@app/acctservice/acct.service';
@Component({
    selector: 'header1',
    templateUrl: 'header1.component.html',
    styleUrls:['header1.component.css']
})
export class Header1Component{
    curruser:UserDroI;
    constructor(
        private acctService: AcctService,
        private router:Router
    ){
        this.acctService.curruser_obse.subscribe(x => this.curruser = x);
    }
    logout() {
        this.acctService.logout();
        this.router.navigate(['/loggedout']);
    }
}