import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AcctService } from '@app/acctservice/acct.service';
import { FileService } from '@app/fileservice/file.service';
import { AppRoutingModule } from './app-routing.module';
import { JwtTokenSendInterceptor, JwtErrorReceiveInterceptor} from '@app/helper/interceptor';
import { AlertComponent } from './helper/alert.component';
import { GroupTableComponent } from './helper/group-table.component';
import { Header0Component } from './helper/header0.component';
import { Header1Component } from './helper/header1.component';
import { Header2Component } from './helper/header2.component';
import { ExplorerComponent } from './explorer/explorer.component';
import { TxinDialogComponent } from './helper/txindialog.component';
import { LoginComponent } from './loginmenu/login.component';
import { Forgotpw1Component } from './loginmenu/forgotpw1.component';
import { Forgotpw2Component } from './loginmenu/forgotpw2.component';
import { Register1Component } from './loginmenu/register1.component';
import { Register2Component } from './loginmenu/register2.component';
import { LoggedoutComponent } from './loginmenu/loggedout.component';
import { ProfileComponent } from './settingmenu/profile/profile.component';
import { ChangeUnameComponent } from './settingmenu/profile/chguname.component';
import { ChangeEmailComponent } from './settingmenu/profile/chgemail.component';
import { ChangePasswordComponent } from './settingmenu/profile/chgpw.component';
import { DeleteAccountComponent } from './settingmenu/profile/deleteacct.component';
import { PreferenceComponent } from './settingmenu/preference.component';
import { ManageGroupComponent } from './settingmenu/mnggrp.component';
import { ManageLicenseComponent } from './settingmenu/mnglic/mnglic.component';
import { RegUserDialogComponent } from './settingmenu/mnglic/reguserdialog.component';
import { AlertService } from './helper/alert.service';
import { CookieService } from 'ngx-cookie-service';
import { QuotaComponent } from './shoppingmenu/quota.component';
import { GgvComponent } from './gemgv/ggv.component';
import { GckComponent } from './gemcheck/gck.component';
import { DataService } from './dataservice/data.service';
import { MatModule } from './mat.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegLicMgrDialogComponent } from './settingmenu/mnglic/reglicmgrdialog.component';
import { GemLpbAppComponent } from './shoppingmenu/gemlpbapp.component';

@NgModule({
  declarations: [
    // entry point
    AppComponent,
    // helpers
    AlertComponent,
    Header0Component,
    Header1Component,
    Header2Component,
    TxinDialogComponent,
    GroupTableComponent,
    // loging related pages
    LoginComponent,
    Forgotpw1Component,
    Forgotpw2Component,
    Register1Component,
    Register2Component,
    LoggedoutComponent,
    // explorer
    ExplorerComponent,
    GgvComponent,
    GckComponent,
    // setting menus
    ProfileComponent,
    PreferenceComponent,
    ChangeUnameComponent,
    ChangeEmailComponent,
    ChangePasswordComponent,
    DeleteAccountComponent,
    ManageGroupComponent,
    ManageLicenseComponent,
    RegUserDialogComponent,
    RegLicMgrDialogComponent,
    // shopping menus
    QuotaComponent,
    GemLpbAppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatModule,
  ],
  providers: [
    AlertService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtTokenSendInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtErrorReceiveInterceptor,
        multi: true
    },
    AcctService,
    FileService,
    CookieService,
    Title,
    DataService,
],
  bootstrap: [AppComponent]
})
export class AppModule { }
