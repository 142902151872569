<header0></header0>
<div class="page-body">
    <div class="page-container-small">
        <mat-grid-list cols="1">
            <mat-card>
                <mat-card-title>
                    Reset password step 1 of 2
                </mat-card-title>
                <mat-card-content>
                    <form [formGroup]="form" (ngSubmit)="ngOnSubmit()">
                        <div>
                            <mat-form-field>
                                <label for="email">E-mail</label>
                                <input type="email" matInput formControlName="email">
                                <mat-error *ngIf="fieldError('email')">
                                    {{fieldError('email')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </form>
                </mat-card-content>
                <mat-card-actions>
                    <div class="form-group">
                        <button mat-raised-button color="primary" (click)="ngOnSubmit()">
                            Next
                        </button>
                    </div>
                </mat-card-actions>
            </mat-card>
        </mat-grid-list>
    </div>
</div>
<div class="footer">
	<a class="footer" href="https://gemdt.com" target="_top">Gem Design Technologies, Inc.</a>
</div>
