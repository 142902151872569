/**
 * acknowledge a file name in basename_123.ext format, where ext can be 
 * nice to LPB format such as '.c.xml'.
 */

class Fname{
    static readonly regex_u123 = new RegExp(/_[0-9]+$/);
    readonly basename:string;
    readonly ver:number;
    readonly ext:string;
    //
    private readonly two_dots_exts = [".c.xml", ".r.xml", ".m.xml"];
    private fname2base_n_ext(fname:string):[string,string]{
        for(let s of this.two_dots_exts){
            if (fname.toLocaleLowerCase().endsWith(s)){
                const idx = fname.length - s.length;
                return [fname.substring(0,idx),fname.substring(idx)];
            }
        }
        const idx = fname.lastIndexOf(".");
        if (idx>=0){
            return [fname.substring(0,idx),fname.substring(idx)];
        }else{
            return [fname,""];
        }
    }
    constructor(fname:string){
        const [base,ext] = this.fname2base_n_ext(fname);
        this.basename = base;
        this.ext = ext;
        const uidx = Fname.regex_u123.test(this.basename)? this.basename.lastIndexOf('_'): -1;
        if (uidx<0){
            this.ver = 1;
        }else{
            this.ver = Number.parseInt(this.basename.substring(uidx+1));
            this.basename = this.basename.substring(0,uidx);
        }
    }
    compare(that:Fname):number{
        if (this.basename === that.basename){
            if (this.ver === that.ver){
                return this.ext.localeCompare(that.ext);
            }else{
                return this.ver - that.ver;
            }
        }else{
            return this.basename.localeCompare(that.basename);
        }
    }
}
export class Util{
    static unixname = new RegExp('^[a-zA-Z][a-zA-Z0-9_]*$');
    static isvalidunixname(s:string):boolean{
        return this.unixname.test(s);
    }
    // constants
    static compareFname(fname1:string, fname2:string):number{
        return new Fname(fname1).compare(new Fname(fname2));
    }
    /**
     * return a new name that resembles to oldname,
     * no duplication with names1 or names2.
     * ex. file1.txt --> file1_2.txt
     */
    static newname(hopedname:string, names1:string[], names2?:string[]){
        const hopedName = new Fname(hopedname);
        let hopever = hopedName.ver;
        let errver = 0;
        for(let i = 0 ; i < names1.length ; i++){
            errver = Math.max(errver,this.newnamesub_errver(hopedName,names1[i]));
        }
        if (names2){
            for(let i = 0 ; i < names2.length ; i++){
                errver = Math.max(errver,this.newnamesub_errver(hopedName,names2[i]));
            }
        }
        if (hopever>errver) return hopedname;
        const s = hopedName.basename+"_"+Math.max(hopever,(errver+1))+hopedName.ext;
        return s;
    }
    private static newnamesub_errver(oldName:Fname, name:string):number{
        if (!name.startsWith(oldName.basename)) return 0;
        const n = new Fname(name);
        if (n.basename !== oldName.basename)  return 0;
        if (n.ext !== oldName.ext) return 0;
        return n.ver;
    }
    static kbstr(sizekb:number):string{
        if (sizekb<1000) return sizekb.toString();
        else if (sizekb<1000000)
            return Math.floor(sizekb/1000)+","+
            this.z3(sizekb%1000);
        else if (sizekb<1000000000)
            return Math.floor(sizekb/1000000)+","+
            this.z3(Math.floor((sizekb%1000000)/1000))+","+
            this.z3(sizekb%1000);
        else return Math.floor(sizekb/1000000000)+","+
            this.z3(Math.floor((sizekb%1000000000)/1000000))+","+
            this.z3(Math.floor((sizekb%1000000)/1000))+","+
            this.z3(sizekb%1000);
    }
    private static z3(k:number):string{
        if (k<10) return "00"+k;
        else if (k<100) return "0"+k;
        else return k.toString();
    }
    static yymmdd_hhmm(epochtime:number):string{
        const date = new Date(epochtime);
        return date.getFullYear()+"-"+
            ("0"+(date.getMonth()+1)).slice(-2)+"-"+
            ("0"+date.getDate()).slice(-2)+" "+
            ("0"+date.getHours()).slice(-2)+":"+
            ("0"+date.getMinutes()).slice(-2);
    }
    static yymmdd(epochtime:number):string{
        const date = new Date(epochtime);
        return date.getFullYear()+"-"+
            ("0"+(date.getMonth()+1)).slice(-2)+"-"+
            ("0"+date.getDate()).slice(-2);
    }
    static sleep(ms:number):Promise<void>{
        return new Promise(resolve=>{setTimeout(resolve,ms);})
    }
    static err2msg(err:any):string{
        if (typeof err === "string"){
            return err;
        }else if (err.error?.error?.error?.message){
            return err.error.error.error.message;
        }else if (err.error?.error?.message){
            return err.error.error.message;
        }else if (err.error?.message){
            return err.error.message;
        }else if (err.statusText){
            return err.statusText;
        }else if (err.message){
            return err.message;
        }else{
            return JSON.stringify(err);
        }
    }
}