import { GetNewsDroI, CheckFilesDtoI, GemGfmtDtoI,
    GemNfmtDtoI, QuotaPlanDroI, FileLogDroI, LicPlanDroI } from 'gemlib/dist_fe/dtodro';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Gfmt, GfmtI } from 'gemlib/dist_fe/gfmt';
import { NfmtI } from 'gemlib/dist_fe/nfmt';

@Injectable({ providedIn: 'root' })
export class DataService{
    constructor(private http: HttpClient){
    }
    get_gemlpb_licplans():Observable<LicPlanDroI[]>{
        return this.http.get<LicPlanDroI[]>(environment.apiUrl+"/data/gemlpb_licplans");
    }
    get_quota_plans():Observable<QuotaPlanDroI[]>{
        return this.http.get<QuotaPlanDroI[]>(environment.apiUrl+"/data/quota_plans");
    }
    get_news():Observable<GetNewsDroI>{
        return this.http.get<GetNewsDroI>(environment.apiUrl+"/data/news");
    }
    get_gfmt(dto:GemGfmtDtoI):Observable<Gfmt>{
        return this.http.post<Gfmt>(environment.apiUrl+"/data/get_gfmt",dto)
        .pipe(map((gfmti:GfmtI) => {
            return new Gfmt().fromI(gfmti);
        }));
    }
    get_nfmt(dto:GemNfmtDtoI):Observable<NfmtI>{
        return this.http.post<NfmtI>(environment.apiUrl+"/data/get_nfmt",dto);
    }
    check_files(dir:string, fnames:string[]):Observable<FileLogDroI[]>{
        const dto:CheckFilesDtoI = {
            dir: dir,
            fnames: fnames
        };
        return this.http.put<FileLogDroI[]>(environment.apiUrl+"/data/check",dto);
    }
}