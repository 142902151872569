import { FileLogDroI, FentDroI, UserDroI } from 'gemlib/dist_fe/dtodro';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AcctService } from '@app/acctservice/acct.service';
import { AlertService} from '@app/helper/alert.service';
import { Title } from '@angular/platform-browser';
import { DataService } from '@app/dataservice/data.service';
import { Util } from '@app/helper/util';

@Component({
    templateUrl: 'gck.component.html',
    styleUrls:['gck.component.css']
})
export class GckComponent implements OnInit {
    curruser: UserDroI;
    currdir: FentDroI;
    loading = false;
    submitted = false;
    filenames_str = "";
    filepath_str = "";
    total_error_cnt = 0;
    total_warning_cnt = 0;
    see_source = false;
    see_error = true;
    see_warning = true;
    see_info = true;
    see_verbose = false;
    see_debug = false;
    dirpath:string = "";
    fnames: string[] = [];
    filelogs: FileLogDroI[] = [];
    get currdate(){
        return Util.yymmdd_hhmm(new Date().getTime());
    }

    constructor(
        private acctService: AcctService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private dataService: DataService,
    ) {
        this.curruser = this.acctService.curruser;
    }

    ngOnInit() {
        this.dirpath = this.route.snapshot.queryParams['dir'];
        this.fnames = this.route.snapshot.queryParams['files'];
        for(let i = 0 ; i < this.fnames.length ; i++){
            if (!this.fnames[i]) this.fnames.splice(i--,1); // filename "" is added to explicitly form an array.
        }
        this.titleService.setTitle(this.fnames[0]);
        this.loading = true;

        if (this.fnames.length>0) this.filenames_str += this.fnames[0];
        if (this.fnames.length>1) this.filenames_str += ", etc ("+this.fnames.length+" files)";
        this.filepath_str = this.dirpath+'/'+this.filenames_str;

        const that = this;
        this.dataService.check_files(this.dirpath,this.fnames)
        .subscribe((filelogs:FileLogDroI[])=>{
            // console.log(fileslog);
            // console.log(JSON.stringify(fileslog));
            that.filelogs = filelogs;
            that.total_error_cnt = 0;
            that.total_warning_cnt = 0;
            for(let i = 0 ; i < filelogs.length ; i++){
                const filelog = filelogs[i];
                // console.log(filelog);
                const ecnt = filelog.logs.reduce(
                    function(acc,curr,idx){
                        return acc+(curr.type=='error'?1:0);
                    },0);
                const wcnt = filelog.logs.reduce(
                    function(acc,curr,idx){
                        return acc+(curr.type=='warning'?1:0);
                    },0);
                filelog.error_cnt = ecnt;
                filelog.warning_cnt = wcnt;
                that.total_error_cnt += ecnt;
                that.total_warning_cnt += wcnt;
            }
            // console.log(JSON.stringify(fileslog));
        },(error)=>{
            that.alertService.error(error);
            that.loading = false;
        },()=>{
            that.loading = false;
        });
    }
    logout() {
        this.acctService.logout();
        this.router.navigate(['/loggedout']);
    }
}
