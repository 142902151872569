import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Util } from './util';

@Injectable()
export class AlertService {
    private subject = new BehaviorSubject<{type:string, text:string}>({type:'success', text:''});
        // BhaviorSubject holds the last message, regardless of the subscription timing.
        // Subject holds the last message, after the subscription.
    private keepAfterRouteChange = false;
    constructor(
        private router: Router,
        private snackbar: MatSnackBar
        ) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert message
                    this.clear();
                }
            }
        });
    }

    getAlert(): Observable<{type:string, text:string}> {
        return this.subject.asObservable();
    }

    success(message: string, keepAfterRouteChange = false):void {
        if (!message) return;
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ type: 'success', text: message });
        this.snackbar.open(message,'OK',{duration:10000});
    }

    error(msgobj: HttpErrorResponse|Error|string, keepAfterRouteChange = false):void {
        this.keepAfterRouteChange = keepAfterRouteChange;
        const msg = Util.err2msg(msgobj);
        this.subject.next({ type: 'error', text: msg });
        this.snackbar.open(msg,'OK',{duration:10000});
    }

    clear() {
        // clear by calling subject.next() without parameters
        this.subject.next({type:'success', text:''});
    }
}