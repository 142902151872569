import { UserDroI } from 'gemlib/dist_fe/dtodro';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/helper/alert.service';
import { TxinDialogComponent, TxinDialogI } from '@app/helper/txindialog.component';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { AcctService, Group } from '@app/acctservice/acct.service';

/**
 * File explorer, which is the center page of the site
 */
@Component({
    templateUrl: 'mnggrp.component.html',
    styleUrls:['mnggrp.component.css']
})
export class ManageGroupComponent implements OnInit{
    curruser: UserDroI; // the user we are serving for
    currgrp: Group = null;
    groups: Group[] = [];
    currgrpname:string;
    loading: number = 0;
    group_table_cols = ["groupname","mbrcnt","usedkb","totalkb","expire"];

    constructor(
        private acctService: AcctService,
        private alertService: AlertService,
        private route: ActivatedRoute,
        private dialogFactory: MatDialog
    ) {
        this.curruser = this.acctService.curruser;
    }
    ngOnInit() {
        this.currgrpname = this.route.snapshot.queryParams['group'];
        if (!this.currgrpname) this.currgrpname = this.curruser.username;
        this.loading = 1;
        this.refresh();
    }
    private refresh(){
        this.acctService.get_groups()
        .subscribe((groups:Group[])=>{
            this.groups = groups;
            this.currgrp = this.findgroupbyname(this.currgrpname);
            if (!this.currgrp){
                this.currgrpname = this.curruser.username;
                this.currgrp = this.findgroupbyname(this.currgrpname);
            }
        },(err)=>{
            this.alertService.error(err);
            this.loading = 0;
        },()=>{
            this.loading = 0;
        });
    }
    private findgroupbyname(groupname:string):Group{
        for(let i = 0 ; i < this.groups.length ; i++){
            if (this.groups[i].groupname == groupname){
                return this.groups[i];
            }
        }
        return null;
    }
    onChangeGroup(group:Group){
        if (!group) return;
        this.currgrp = group;
        this.currgrpname = group.groupname;
    }
    // ----------- command methods ------------------------------------
    renamegroup_pressed(){
        this.alertService.clear();
        if (this.currgrp.groupname===this.curruser.username){
            this.alertService.error("cannot change the name of private groups");
            return;
        }
        if (!this.currgrp.ismanager(this.curruser.username)){
            this.alertService.error("you should be a manager to change the group name");
            return;
        }
        const dialog = this.dialogFactory.open(TxinDialogComponent,{
            width: '400px',
            data: <TxinDialogI>{
                title: "Rename "+this.currgrp.groupname,
                labels:['new group name'],
                values:[this.currgrp.groupname]
            }
        });
        dialog.afterClosed().subscribe((model:TxinDialogI|null)=>{
            if (!model) return; // canceled
            this.loading = 1;
            this.acctService.rename_group(this.currgrp.groupname,model.values[0])
            .pipe(finalize(()=>{
                this.loading = 0;
                this.refresh();
            })).subscribe(()=>{
            },(error)=>{
                this.alertService.error(error);
            },()=>{
            });
        });
    }
    addmember_pressed(){
        this.alertService.clear();
        if (this.currgrp.groupname===this.curruser.username){
            this.alertService.error("cannot add members to private group");
            return;
        }
        if (!this.currgrp.ismanager(this.curruser.username)){
            this.alertService.error("you should be a manager to add members");
            return;
        }
        const dialog = this.dialogFactory.open(TxinDialogComponent,{
            width: '400px',
            data: <TxinDialogI>{
                title: "Add members to "+this.currgrp.groupname,
                labels:['email1', 'email2','email3','email4','email5'],
                values:['','','','','']
            }
        });
        dialog.afterClosed().subscribe((model:TxinDialogI|null)=>{
            if (!model) return; // cancel
            let dupname = null;
            for(let i =0 ; i < model.values.length && !dupname ; i++){
                const email1 = model.values[i];
                if (!email1) continue;
                for(let j = i+1 ; j < model.values.length && !dupname ; j++){
                    const email2 = model.values[j];
                    if (email1.toLowerCase() === email2.toLowerCase()) dupname = email1;
                }
            }
            if (dupname){
                this.alertService.error("'"+dupname+"' is duplicated.");
                return;
            }
            const emails = model.values.filter(email=>!!email);
            this.loading = 0;
            const err_emails:string[] = [];
            for(let i = 0 ; i < emails.length ; i++){
                this.loading++;
                this.acctService.add_groupmember(this.currgrp.groupname,emails[i])
                .pipe(finalize(()=>{
                    this.loading--;
                    if (this.loading<=0){
                        if (err_emails.length>0){
                            this.alertService.error(err_emails+" could not be added");
                        }
                        this.refresh();
                            // better not to use reroute, because browser uses its cache.
                    }
                })).subscribe(()=>{
                },(error)=>{
                    err_emails.push(emails[i]);
                },()=>{
                });
            }
            if (emails.length<=0) return;

        });

        // this.fnmodalm.title = "Add members to "+this.currgrp.groupname;
        // this.fnmodalm.labels = ['email1','email2','email3','email4','email5'];
        // this.fnmodalm.values = ['','','','',''];
        // TODO modal service
        // const modalref:NgbModalRef = this.modalService.open(TxinmodalComponent, { backdrop: 'static'});
        // modalref.result.then(()=>{
        //     let dupname = null;
        //     for(let i =0 ; i < this.fnmodalm.values.length && !dupname ; i++){
        //         const email1 = this.fnmodalm.values[i];
        //         if (email1==='') continue;
        //         for(let j = i+1 ; j < this.fnmodalm.values.length && !dupname ; j++){
        //             const email2 = this.fnmodalm.values[j];
        //             if (email1.toLowerCase() === email2.toLowerCase()) dupname = email1;
        //         }
        //     }
        //     if (dupname){
        //         this.alertService.error("'"+dupname+"' is duplicated.");
        //         return;
        //     }
        //     const emails = this.fnmodalm.values.filter(email=>email!='');
        //     this.loading = 0;
        //     const err_emails = [];
        //     for(let i = 0 ; i < emails.length ; i++){
        //         this.loading++;
        //         this.acctService.add_groupmember(this.currgrp.groupname,emails[i])
        //         .pipe(finalize(()=>{
        //             this.loading--;
        //             if (this.loading<=0){
        //                 if (err_emails.length>0){
        //                     this.alertService.error(err_emails+" could not be added");
        //                 }
        //                 this.refresh();
        //                     // better not to use reroute, because browser uses its cache.
        //             }
        //         })).subscribe(()=>{
        //         },(error)=>{
        //             err_emails.push(emails[i]);
        //         },()=>{
        //         });
        //     }
        //     if (emails.length<=0) return;
        // },
        // ()=>{} // modal.dismiss('Cancel') comes here.
        // );
    }
    removemember_pressed(){
        this.alertService.clear();
        const unames = this.currgrp.members.filter(m=>{return m.checked;}).map(m=>m.username);
        if (unames.length<=0) return;
        for(let i = 0 ; i < unames.length ; i++){
            if (this.currgrp.groupname == unames[i]){
                this.alertService.error("cannot resign from your private group.");
                return;
            }
        }
        if (!this.currgrp.ismanager(this.curruser.username)){
            this.alertService.error("you should be a manager to remove member");
            return;
        }
        if (this.currgrp.members.length<=1){
            this.alertService.error("cannot remove the sole member.");
            return;
        }
        if (unames.length>=this.currgrp.members.length){
            this.alertService.error("cannot remove all members.");
            return;
        }
        this.loading = 1;
        this.acctService.remove_groupmember(this.currgrp.groupname,unames)
        .pipe(finalize(()=>{
            this.loading = 0;
            this.refresh();
        }))
        .subscribe(()=>{
        },(error)=>{
            this.alertService.error(error);
        },()=>{
        });
    }
    assnmgr_pressed(){
        this.alertService.clear();
        if (this.currgrp.managercnt()>0&&
            !this.currgrp.ismanager(this.curruser.username)){
            // if there is no manager, anyone can assign himself or others as managers
            this.alertService.error("you should be a manager to assign manager");
            return;
        }
        const unames = this.currgrp.members.filter(m=>{return m.checked;}).map(m=>m.username);
        if (unames.length<=0) return;
        this.loading = 1;
        this.acctService.assign_groupmanager(this.currgrp.groupname,unames)
        .pipe(finalize(()=>{
            this.loading = 0;
            this.refresh();
        }))
        .subscribe(()=>{
        },(error)=>{
            this.alertService.error(error);
        },()=>{
        });

    }
    unassnmgr_pressed(){
        this.alertService.clear();
        if (!this.currgrp.ismanager(this.curruser.username)){
            this.alertService.error("you should be a manager to unassign manager");
            return;
        }
        const unames = this.currgrp.members.filter(m=>{return m.checked;}).map(m=>m.username);
        if (unames.length<=0) return;
        this.loading = 1;
        this.acctService.unassign_groupmanager(this.currgrp.groupname,unames)
        .pipe(finalize(()=>{
            this.loading = 0;
            this.refresh();
        }))
        .subscribe(()=>{
        },(error)=>{
            this.alertService.error(error);
        },()=>{
        });
    }
}