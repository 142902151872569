import { LogI } from './dtodro';
/**
 * convenient classes
 */
export class GemLog implements LogI{
    static readonly error = "error";
    static readonly warning = "warning";
    static readonly debug = "debug";
    static readonly info = "info";
    static readonly verbose = "verbose";
    static readonly source = "source";
    type:string;
    message:string;
    constructor(type:string, message:string){
        this.type = type;
        this.message = message;
    }
}
export class GemUtil{

    // setTimeout is a Node feature, thus cannot used in the fend/bend common library.
    // static async sleep(ms:number):Promise<void>{
    //     setTimeout(()=>{},ms);
    // }
    static unixname = new RegExp('^[a-zA-Z][a-zA-Z0-9_]*$');
    static isvalidunixname(s:string):boolean{
        return this.unixname.test(s);
    }
    static parseInt(s:string):number{
        return Number.parseInt(s);
    }
    static unescape(s:string):string{
        if (s.startsWith('"') && s.endsWith('"')){
            return s.substring(1,s.length-1);
        }else return s;
    }
    static yymmddhhmm(epochtime:number):string{
        const date = new Date(epochtime);
        return ("0"+date.getFullYear()).slice(-2)+
            ("0"+(date.getMonth()+1)).slice(-2)+
            ("0"+date.getDate()).slice(-2)+
            ("0"+date.getHours()).slice(-2)+
            ("0"+date.getMinutes()).slice(-2);
    }
    static yyyy_mm_dd(date:Date):string{
        return ("0"+date.getFullYear()).slice(-4)+'-'+
            ("0"+(date.getMonth()+1)).slice(-2)+'-'+
            ("0"+date.getDate()).slice(-2);
    }
    static yyyy_mm_dd_hh_mm(date:Date):string{
        return ("0"+date.getFullYear()).slice(-4)+'-'+
            ("0"+(date.getMonth()+1)).slice(-2)+'-'+
            ("0"+date.getDate()).slice(-2)+' '+
            ("0"+date.getHours()).slice(-2)+':'+
            ("0"+date.getMinutes()).slice(-2);
    }
    static nearestidx(val:number,vals:number[]):number{
        let err = Number.MAX_VALUE;
        let idx = -1;
        for(let i = 0 ; i < vals.length ; i++){
            const e = Math.abs(vals[i]-val);
            if (e<err){
                err = e;
                idx = i;
            }
        }
        return idx;
    }
    static lastidx<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
        let l = array.length;
        while (l--) {
            if (predicate(array[l], l, array))
                return l;
        }
        return -1;
    }
    // /**
    //  * returns value in range [0,rule)
    //  */
    // static modulo(val:number, rule:number):number{
    //     while(val<0) val += rule;
    //     while(val>rule) val -= rule;
    //     return val;
    // }
    // static varname(v:any):string{
    //     return Object.keys(v)[0];
    // }
}
export class GpName{
    private name:string = "";
    private innn:number;
    private nnn:number;
    private immm:number;
    private mmm:number;
    private analysis():void{
    	let len = this.name.length;
    	let i = len-1;
    	this.innn = this.immm = -1;
    	this.nnn = this.mmm = 0;
    	if (i>=0&& this.name.charAt(i)==']'){
    		for(i-- ; i>=0 ; i--){
    			const ch = this.name.charAt(i);
                if (ch >= '0' && ch <= '9') this.immm = i;
                else if (ch == '['){i--;break;}
                else{this.immm=-1;i=len-1;break;}
    		}
    		if (this.immm>=0) this.mmm = Number.parseInt(this.name.substring(this.immm,len-1));
    	}
        for(; i >= 0 ; i--){
            const c = this.name.charAt(i);
            if (c >= '0' && c <= '9') this.innn = i;
            else break;
        }
        if (this.innn>=0){
        	if (this.immm>=0) this.nnn = Number.parseInt(this.name.substring(this.innn,this.immm-1));
        	else this.nnn = Number.parseInt(this.name.substring(this.innn));
        };
    }
    /** Creates a new instance of GpName */
    static make(name:string):GpName{
        return  new GpName().setName(name);
    }
    setName(name:string):GpName{
        if (!name) this.name = "";
        else this.name = name;
        this.analysis();
        return this;
    }
    public mkCopy():GpName{
    	return new GpName().copy(this);
    }
    public copy(ref:GpName):GpName{
    	this.name = ref.name;
    	this.innn = ref.innn;
    	this.immm = ref.immm;
    	this.nnn = ref.nnn;
    	this.mmm = ref.mmm;
    	return this;
    }
    /**
     * compares two names
     **/
    public compareTo(that:GpName):number {
        const s1 = this.namebody();
        const s2 = that.namebody();
        let rc = s1.localeCompare(s2);
        if (rc==0){
        	rc = this.namenum() - that.namenum();
        	if (rc==0){
        		rc = this.bitnum() - that.bitnum();
        	}
        }
        return rc;
    }
    public equals(that:GpName):boolean{
    	return this.compareTo(that)==0;
    }
    /**
     * returns the AAA part of the name 'AAAnnn', or 'AAAnnn[mmm]'.
     * "" is returned, if AAA is missing.
     **/
    public namebody():string{
    	if (this.innn==0) return "";
    	else if (this.innn>0) return this.name.substring(0,this.innn);
    	else if (this.immm==0) return "";
    	else if (this.immm>0) return this.name.substring(0,this.immm-1);
    	else return this.name;
    }
    /**
     * returns the AAAnnn part of the name AAAnnn[mmm].
     * "" is returned if AAAnnn is missing.
     */
    public bussname():string{
    	if (this.immm==0) return "";
    	else if (this.immm>0) return this.name.substring(0,this.immm-1);
    	else return this.name;
    }
    /**
     * returns integer for the nnn part of the name 'XXXnnn', where nnn is the successive number chars.
     * returns zero if there is no nnn part.
     **/
    public namenum():number{
    	return this.nnn;
    }
    public bitnum():number{
    	return this.mmm;
    }
    public isnamenum():boolean{
    	return this.innn>=0;
    }
    public isbitnum():boolean{
    	return this.immm>=0;
    }
}
