import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
    // DialogRef means dialog class, Dialog means dialog factory class.

// data model
export interface TxinDialogI {
  title: string;
  labels: string[];
  values: string[];
}

@Component({
    selector: "txindialog",
    templateUrl: "./txindialog.component.html"
})
export class TxinDialogComponent {
    constructor(
        public dialog: MatDialogRef<TxinDialogI>,
        @Inject(MAT_DIALOG_DATA) public model: TxinDialogI
    ){}
    ok(){
        this.dialog.close(this.model);
    }
    cancel(){
        this.dialog.close(null);
    }
}
