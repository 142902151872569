import { GemPoint, GemEuclid, GemBbox, GemShape,
	GemPoly, GemCircle, GemMoveTo, GemArcTo, GemArc3To} from 'gemlib/dist_fe/gemshape';
import { Gfmt, Net, Via, Padstack, Pad, Comp, Wire, Pin } from 'gemlib/dist_fe/gfmt';

export class G{
	// net type
	// static readonly nt_S = "S";
	// static readonly nt_G = "G";
	// static readonly nt_P = "P";
	// static readonly nettypes = [G.nt_S,G.nt_G,G.nt_P];
	
	// global constants
	static readonly DBU_1UM:number = 1;
	static readonly DBU_1MM:number = G.DBU_1UM*1000;
	static readonly ZOOM_FIT:number = 0.8; // user coord to pixel factor in zoom fit case
	static readonly ZOOM_BY_DOUBLECLICK:number = 2.0;
	static readonly ZOOM_BY_BUTTON:number = 1.2; // scale up ratio
	static readonly ZOOM_BY_WHEEL:number = 1.1; // scale up ratio
	static readonly PAN_BY_BUTTON:number = 0.2; // shifted portion of canvas width (or hight)
	static readonly CANVAS_WIDTH:number = 2000; // maximum canvas width
	static readonly CANVAS_HEIGHT:number = 2000; // maximum canvas height
	static readonly BACKGROUND_COLOR:string = "black";
	static readonly NONET_COLOR:string = "#887f7a";
	static readonly COMPSHAPE_COLOR:string = "#fbfaf5";
	static readonly BOARDSHAPE_COLOR:string = "#e5e4e6";
	// static readonly DEFAULT_BOARD_FILL_COLOR:string = "#2c4f54";
	static readonly TEXT_COLOR:string = "white";
	static readonly FLIPPED_COMPSHAPE_COLOR:string = "#007bbb";
	static readonly VIAMARK_COLOR:string = G.BACKGROUND_COLOR;
	static readonly PINPOS_COLOR:string = "white";
	static readonly FONT_SIZE:number = 14;
	// const WIRE_COLOR:string = "#e5826b";
	static readonly PINPOSMARKSIZE:number = 6;
	static readonly POINTMARK_COLOR:string = "#0095d9";
	static readonly POINTMARK_SIZE:number = 10;
	static readonly SELECTED_COLOR:string = "red";

	// https://www.colordic.org/w/
	static readonly black_colors:string[] = [
		"#1f3134", "#494a41", "#333631", "#393f4c", "#393e4f", "#203744",
		"#4d4c61", "#513743", "#544a47", "#583822", "#433d3c", "#432f2f",
		"#302833", "#0d0015", "#2e2930", "#2b2b2b", "#2b2b2b", "#180614",
		"#281a14", "#000b00", "#250d00", "#241a08", "#16160e", "#2c4f54",
		];
	static readonly mid_colors:string[] = [
		"#b77b57",
		"#96514d", "#e6b422", "#006e54", "#895b8a", "#8d6449", "#d9a62e",
		"#00a381", "#824880", "#e9dfe5", "#deb068", "#d3a243", "#38b48b",
		"#915c8b", "#e4d2d8", "#bf794e", "#c89932", "#00a497", "#9d5b8b",
		"#f6bfbc", "#bc763c", "#d0af4c", "#80aba9", "#7a4171", "#f5b1aa",
		"#b98c46", "#8b968d", "#5c9291", "#bc64a4", "#f5b199", "#b79b5b",
		"#6e7955", "#478384", "#b44c97", "#efab93", /*"#b77b57",*/ "#767c6b",
		"#43676b", "#aa4c8f", "#f2a0a1", "#b68d4c", "#888e7e", "#80989b",
		"#cc7eb1", "#f0908d", "#ad7d4c", "#5a544b", "#2c4f54", "#cca6bf",
		"#ee827c", "#ad7d4c", "#56564b", "#c4a3bf", "#f09199", "#ae7c4f",
		"#555647", "#47585c", "#e7e7eb", "#f4b3c2", "#ad7e4e", "#485859",
		"#dcd6d9", "#eebbcb", "#ae7c58", "#6b6f59", "#6c848d", "#d3cfd9",
		"#a86f4c", "#474b42", "#53727d", "#d3ccd6", "#e8d3d1", "#946243",
		"#5b7e91", "#c8c2c6", "#e6cde3", "#917347", "#5b6356", "#426579",
		"#a6a5c4", "#e5abbe", "#956f29", "#726250", "#4c6473", "#a69abd",
		"#e597b2", "#8c7042", "#9d896c", "#455765", "#a89dac", "#e198b4",
		"#7b6c3e", "#94846a", "#44617b", "#9790a4", "#e4ab9b", "#d8a373",
		"#897858", "#9e8b8e", "#e09e87", "#cd8c5c", "#716246", "#95859c",
		"#d69090", "#cd5e3c", "#cbb994", "#95949a", "#d4acad", "#cb8347",
		"#d6c6af", "#71686c", "#c97586", "#c37854", "#bfa46f", "#705b67",
		"#c099a0", "#c38743", "#9e9478", "#634950", "#b88884", "#c39143",
		"#a59564", "#5f414b", "#b48a76", "#bf783a", "#715c1f", "#4f455c",
		"#a86965", "#bb5535", "#c7b370", "#c1e4e9", "#5a5359", "#a25768",
		"#bb5520", "#bce2e8", "#594255", "#ec6d71", "#b55233", "#a19361",
		"#a2d7dd", "#524748", "#eb6ea5", "#aa4f37", "#8f8667", "#abced8",
		"#e95295", "#9f563a", "#887938", "#a0d8ef", "#e6eae3", "#e7609e",
		"#9f563a", "#6a5d21", "#89c3eb", "#d4dcd6", "#d0576b", "#9a493f",
		"#918754", "#84a2d4", "#d4dcda", "#c85179", "#98623c", "#a69425",
		"#83ccd2", "#d3cbc6", "#e9546b", "#965042", "#ada250", "#84b9cb",
		"#c8c2be", "#e95464", "#965036", "#938b4b", "#698aab", "#b3ada0",
		"#c85554", "#95483f", "#8c8861", "#008899", "#a99e93", "#c53d43",
		"#954e2a", "#a1a46d", "#00a3af", "#a58f86", "#e83929", "#8f2e14",
		"#726d40", "#2a83a2", "#928178", "#e60033", "#8a3319", "#928c36",
		"#59b9c6", /*"#887f7a",*/ "#e2041b", "#8a3b00", "#dccb18", "#2ca9e1",
		"#b4866b", "#d7003a", "#852e19", "#d7cf3a", "#38a1db", "#b28c6e",
		"#c9171e", "#7b4741", "#c5c56a", /*"#0095d9",*/ "#a16d5d", "#d3381c",
		"#773c30", "#c3d825", "#0094c8", "#9f6f55", "#ce5242", "#783c1d",
		"#b8d200", "#2792c3", "#8c6450", "#d9333f", "#762f07", "#e0ebaf",
		"#007bbb", "#856859", "#b94047", "#752100", "#d8e698", "#5383c3",
		"#765c47", "#ba2636", "#6c3524", "#c7dc68", "#5a79ba", "#6f514c",
		"#b7282e", "#683f36", "#99ab4e", "#4c6cb3", "#6f4b3e", "#a73836",
		"#664032", "#7b8d42", "#3e62ad", "#9e3d3f", "#6d3c32", "#69821b",
		"#1e50a2", "#543f32", "#a22041", "#aacf53", "#507ea4", "#554738",
		"#a22041", "#6c2c2f", "#b0ca71", "#19448e", "#640125", "#b9d08b",
		"#164a84", "#ede4cd", "#f8b862", "#839b5c", "#165e83", "#3f312b",
		"#e9e4d4", "#f6ad49", "#cee4ae", "#274a78", "#ebe1a9", "#f39800",
		"#82ae46", "#2a4073", "#ffffff", "#f2f2b0", "#f08300", "#a8c97f",
		"#223a70", "#fffffc", "#e4dc8a", "#ec6d51", "#9ba88d", "#192f60",
		"#f7fcfe", "#f8e58c", "#ee7948", "#c8d5bb", "#1c305c", "#f8fbf8",
		"#ddbb99", "#ed6d3d", "#c1d8ac", "#0f2350", "#fbfaf5", "#d7a98c",
		"#ec6800", "#a8bf93", "#17184b", "#f3f3f3", "#f2c9ac", "#ec6800",
		"#769164", "#f3f3f2", "#fff1cf", "#ee7800", "#d6e9ca", "#bbc8e6",
		"#eae5e3", "#fddea5", "#eb6238", "#93ca76", "#bbbcde", "#e5e4e6",
		"#fce2c4", "#ea5506", "#93b881", "#8491c3", "#dcdddd", "#ea5506",
		"#badcad", "#8491c3", "#dddcd6", "#f9c89b", "#eb6101", "#97a791",
		"#4d5aaf", "#c0c6c9", "#f7bd8f", "#e49e61", "#98d98e", "#4d5aaf",
		"#afafb0", "#f6b894", "#e45e32", "#88cb7f", "#4a488e", "#adadad",
		"#f4dda5", "#e17b34", "#69b076", "#4d4398", "#a3a3a2", "#f1bf99",
		"#dd7a56", "#6b7b6e", "#5654a2", "#9ea1a3", "#f1bf99", "#db8449",
		"#bed2c3", "#706caa", "#9fa0a0", "#efcd9a", "#d66a35", "#93b69c",
		"#68699b", "#949495", "#efcd9a", "#ffd900", "#a6c8b2", "#867ba9",
		"#888084", "#f0cfa0", "#ffd900", "#47885e", "#dbd0e6", "#7d7d7d",
		"#edd3a1", "#ffea00", "#316745", "#a59aca", "#7b7c7d", "#e0c38c",
		"#ffec47", "#68be8d", "#7058a3", "#727171", "#f3bf88", "#fef263",
		"#3eb370", "#674598", "#595857", "#f7b977", "#fcd575", "#007b43",
		"#674196", "#595455", "#f19072", "#fbd26b", "#bed3ca", "#9079ad",
		"#524e4d", "#f19072", "#f5e56b", "#92b5a9", "#745399", "#474a4d",
		"#ee836f", "#eec362", "#7ebea5", "#65318e", "#383c3c", "#eb9b6f",
		"#ebd842", "#7ebeab", "#522f60", "#e0815e", "#ffdb4f", "#028760",
		"#493759", "#df7163", "#fbca4d", "#3b7960", "#d57c6b", "#fcc800",
		"#2f5d50", "#884898", "#d0826c", "#f8b500", "#3a5b52", "#c0a2c7",
		"#ca8269", "#fabf14", "#475950", /* "#460e44",*/ "#bb5548", "#f7c114",
		/*"#00552e",*/ "#74325c", "#ab6953", "#e6b422", /* "#005243", "#55295b",*/
	];
	// global variables
	static gfmt:Gfmt;
	static canvas:HTMLCanvasElement;
	static ctx:CanvasRenderingContext2D; // canvas graphic context
	// static pzero:GemPoint = new GemPoint(0,0);
	// the features in ES6, which is not available because we set "target":"ES5",
	// in tsconfig.json
	static readonly MIN_SAFE_INTEGER = -9007199254740991;
	static readonly MAX_SAFE_INTEGER = 9007199254740991;
	static alertBug(message:string):never{
		console.error("bug: "+message);
		alert("bug :"+message);
		throw new Error("bug: "+message);
	}
	static alertError(message:string):never{
			console.error(message);
			alert(message);
			throw new Error(message);
	}
	static formatError(lineno:number, message:string):never{
	console.error("format error: "+message);
	alert("format error :"+message);
	throw new Error("format error: "+message);
	}
	static formatWarning(lineno:number, message:string):void{
			console.error(message);
	}
}
export class PointMark{
	pnt:GemPoint = new GemPoint(0,0);
	text:string;
	constructor(p:GemPoint,msg:string){
		this.pnt.copy(p);
		this.text = msg;
	}
}

/**
 * visibility related items and services
 */
export class View{
	static scale:number = 1.0; // 1.0 = the layout just fit to the canvas, 2.0 = zoom up.
	private static _canvascen:GemPoint; // the user coord that coincides with the canvas center.
	static curr_layer:number = 1;
	static subs_vis:boolean = true;
	static comp_vis:boolean = true;
	static wire_vis:boolean = true;
	static via_vis:boolean = true;
	static pinpos_vis:boolean = false;
	static viapos_vis:boolean = false;
	static route_vis:boolean = true;
	static far_lower_layer_vis:boolean = false;
	static near_lower_layer_vis:boolean = false;
	static near_upper_layer_vis:boolean = false;
	static far_upper_layer_vis:boolean = false;
	static objinfo_vis:boolean = true;
	static compname_vis:boolean = false;
	static pinname_vis:boolean = false;
	static netname_vis:boolean = false;
	static lineno_vis:boolean = false;
	static alpha_curr:number = 1.0;
	static alpha_noncurr:number = 0.1;
	static curr_mouse_ux: number = 0;
	static curr_mouse_uy: number = 0;
	static is_mouse_selmode: boolean = true; // mouse can be pad mode, or select mode.
	static is_mouse_zoommode: boolean = false; // when true, is_mouse_selmode is temporary hidden.
	static get canvascen():GemPoint{
		if (!View._canvascen) View._canvascen = new GemPoint(0,0);
		return View._canvascen;
	}
	static zoom_by_rect(probe:GemBbox):void{
		this.canvascen.copy(probe.center());
		const scalex = probe.width() / G.gfmt.bbox().width();
		const scaley = probe.height() / G.gfmt.bbox().height();
		View.scale = 1/Math.max(scalex,scaley);
		redraw();
	}

	/**
	 * calculate alpha for the layer, based on the sharpness setting.
	 * @priority -1: weaker, 0: normal, 0.1: higher, 2: highest.
	 */
	static calcAlpha(layer:number, priority:number):number{
		if (!View.is_layer_visible(layer)) return 0;
		var alpha_curr = View.alpha_curr;
		var alpha_noncurr = View.alpha_noncurr;
		var alpha_pri:number;
		if (alpha_curr>alpha_noncurr){
			alpha_curr = alpha_curr*0.9 - alpha_noncurr*0.1; // share 10 % for priority.
		}
		var alpha_pri = Math.max(alpha_curr-alpha_noncurr)*0.1/2;

		var den = 0;
		for(var lay = 1 ; lay <= G.gfmt.layerCnt() ; lay++){
			if (View.is_layer_visible(lay)) den = Math.max(den,Math.abs(View.curr_layer-lay));
		}
		var alpha_lay:number;
		if (den===0){
			alpha_lay = Math.max(alpha_curr,alpha_noncurr);
		}else{
			alpha_curr = Math.min(1.0,Math.max(0.01,alpha_curr));
			alpha_noncurr = Math.min(1.0,Math.max(0.01,alpha_noncurr));
			if (alpha_curr>alpha_noncurr){
				const r = Math.pow(Math.E,Math.log(alpha_noncurr/alpha_curr)/den);
				alpha_lay = alpha_curr*Math.pow(r,Math.abs(layer-View.curr_layer));
			}else{
				const r = Math.pow(Math.E,Math.log(alpha_curr/alpha_noncurr)/den);
				alpha_lay = alpha_noncurr*Math.pow(r,den-Math.abs(layer-View.curr_layer));
			}
		}
		var alpha = alpha_lay + alpha_pri * priority;
		return alpha;
	}
	static is_pad_visible(pad:Pad):boolean{
		if (pad.layer>0 && !View.is_layer_visible(pad.layer)) return false;
		const psk:Padstack = pad.par;
		if (psk.par instanceof Pin){
			const pin = <Pin> psk.par;
			const comp = pin.par;
			if (pad.layer==0) return (View.comp_vis && 
				View.is_layer_visible(comp.placed_layer()));
			else return (View.comp_vis && View.is_layer_visible(pad.layer));
		}else if (psk.par instanceof Via){
			const via = <Via> psk.par;
			return View.is_via_visible(via);
		}else G.alertBug("padstack's parent should be pin or padstack, but got "+psk.par);
			// library padstack has gfmt as parent, but never be drawn thus never be
			// tested about visibility.
	}
	static is_pin_visible(pin:Pin):boolean{
		return View.pinpos_vis && View.is_layer_visible(pin.par.placed_layer());
	}
	static is_wire_visible(wire:Wire):boolean{
		if (!View.wire_vis) return false;
		var lay1:number = wire.lay1>0? wire.lay1: wire.die1? wire.die1.placed_layer():0;
		var lay2:number = wire.lay2>0? wire.lay2: wire.die2? wire.die2.placed_layer():0;
		if (lay1>0&&lay2>0) return View.isvisiblelayerinrange(
			Math.min(lay1,lay2),Math.max(lay1,lay2));
		else if (lay1>0) return View.is_layer_visible(lay1);
		else if (lay2>0) return View.is_layer_visible(lay2);
		else return false;
	}
	static is_via_visible(via:Via):boolean{
		return View.isvisiblelayerinrange(via.lay1,via.lay2);
	}
	static isvisiblelayerinrange(lay1:number, lay2:number):boolean{
		for(let lay = lay1 ; lay <= lay2 ; lay++){
			if (View.is_layer_visible(lay)) return true;
		}
		return false;
	}
	// static islayerrangevisible(lay1:number, lay2:number):boolean{
	// 	if (lay1<=View.curr_layer && View.curr_layer<=lay2) return true;
	// 	else if (lay1<=View.curr_layer-1&&View.curr_layer-1<=lay2) return View.near_upper_layer_vis;
	// 	else if (lay1<=View.curr_layer+1&&View.curr_layer+1<=lay2) return View.near_lower_layer_vis;
	// 	else if (lay2<View.curr_layer-1) return View.far_upper_layer_vis;
	// 	else return View.far_lower_layer_vis;
	// }
	static is_layer_visible(layer:number):boolean{
		if (View.curr_layer==layer) return true;
		else if (G.gfmt.condlayers[layer-1].visible) return true;
		else if (layer<View.curr_layer-1) return View.far_upper_layer_vis;
		else if (layer===View.curr_layer-1) return View.near_upper_layer_vis;
		else if (layer===View.curr_layer) return true;
		else if (layer===View.curr_layer+1) return View.near_lower_layer_vis;
		else return View.far_lower_layer_vis;
	}

	static goto_layer(layer:number){
		View.curr_layer = layer;
	}
	static upratio():number{
		const fx = G.canvas.width/G.gfmt.bbox().width() * G.ZOOM_FIT;
		const fy = G.canvas.height/G.gfmt.bbox().height() * G.ZOOM_FIT;
		const f = Math.min(fx,fy) * View.scale;
		return f;
	}
	static pdist(udist:number):number{
		const dist = Math.round(udist * View.upratio());
		return dist;
	}
	static udist(pdist:number):number{
		const udist = pdist / View.upratio();
		return udist;
	}
	static pang(udegree:number):number{
		// (js memo) in HTML5 canvas, angle is measured clockwise, and the unit is radian.
		return GemEuclid.degreeModulo(-udegree)/180*Math.PI;
	}
	static px(ux:number):number{
		const px = Math.round(G.canvas.width/2+View.upratio()*(ux-View.canvascen.x))+0.5;
			// 0.5 is needed for crisp 1px line.
			// https://stackoverflow.com/questions/7530593/html5-canvas-and-line-width/7531540#7531540
		return px;
	}
	static py(uy:number):number{
		const py = Math.round(G.canvas.height/2-View.upratio()*(uy-View.canvascen.y))+0.5;
		return py;
	}
	static ux(px:number):number{
		const ux = (px - G.canvas.width/2)/View.upratio() + View.canvascen.x;
		return ux;
	}
	static uy(py:number):number{
		const uy = - (py - G.canvas.height/2)/View.upratio() + View.canvascen.y;
		return uy;
	}
}
export class Search{
	static searchtext:string = "";
	static pntmarks:PointMark[] = [];
	static idx:number = 0;
	static search(searchtext:string):void{
		Search.searchtext = searchtext;
		const oldcnt = Search.pntmarks.length;
		search_in_gfmt(searchtext); // adds View.pnts
		const added = Search.pntmarks.length - oldcnt;
		if (added>0){
			Search.idx = Math.max(0,oldcnt-1);
				// so that 'next' button focuses to the first newly found object.
		}
		redraw(); // refresh the screen to clarify the search was performed.
	}
	static clear_search_result():void{
		Search.searchtext = "";
		Search.pntmarks = [];
		Search.idx = 0;
	}
	static select_by_rect(probe:GemBbox):void{
		select_by_rect_in_gfmt(probe); // adds View.pnts
		redraw();
	}
	static goto_next_or_prev_selection(next:boolean):void{
		if (Search.pntmarks.length>0){
			Search.idx = (Search.idx+(next?1:Search.pntmarks.length-1))%Search.pntmarks.length;
			View.canvascen.copy(Search.pntmarks[Search.idx].pnt);
			redraw();
			return;
		}
	}
}

function draw_shapes(shapes:GemShape[], layer:number, color_for_stroke:string, color_for_fill:string){
	var closedcnt = 0, open_0widcnt = 0, open_widcnt = 0, selcnt = 0;
	for(var i = 0 ; i < shapes.length ; i++){
		const shape = shapes[i];
		if (shape.layer!=layer) continue;
		if (shape.isclosed) closedcnt++;
		else if (shape.width()==0) open_0widcnt++;
		else open_widcnt++;
		if (shape.selected) selcnt++;
	}
	if (closedcnt>0){
		G.ctx.fillStyle = color_for_fill;
		G.ctx.beginPath();
		for(var i = 0 ; i < shapes.length ; i++){
			const shape = shapes[i];
			if (shape.layer==layer&&shape.isclosed) outpath_shape(shape);
		}
		G.ctx.fill();
	}
	if (closedcnt+open_0widcnt>0){
		G.ctx.strokeStyle = color_for_stroke;
		G.ctx.beginPath();
		for(var i = 0 ; i < shapes.length ; i++){
			const shape = shapes[i];
			if (shape.layer==layer&&(shape.isclosed||shape.width()==0)) outpath_shape(shape);
		}
		G.ctx.stroke();
	}
	if (open_widcnt>0){
		G.ctx.strokeStyle = color_for_stroke;
		for(var i = 0 ; i < shapes.length ; i++){
			const shape = shapes[i];
			if (shape.layer==layer&&!shape.isclosed&&shape.width()>0){
				G.ctx.beginPath();
				outpath_shape(shape);
				G.ctx.stroke();
			}
		}
	}
	if (selcnt>0){
		G.ctx.strokeStyle = G.SELECTED_COLOR;
		for(var i = 0 ; i < shapes.length ; i++){
			const shape = shapes[i];
			if (shape.layer==layer&&shape.selected){
				G.ctx.beginPath();
				outpath_shape(shape);
				G.ctx.stroke();
			}
		}
	}
}
function outpath_circle(circ:GemCircle):void{
	const px = View.px(circ.cen.x);
	const py = View.py(circ.cen.y);
	G.ctx.lineWidth = 1;
	G.ctx.moveTo(px+View.pdist(circ.dia/2),py);
	G.ctx.arc(px,py,View.pdist(circ.dia/2),0,2*Math.PI,circ.isvoid?true:false);
	G.ctx.closePath();
	if (circ.innerdia>0){
		G.ctx.moveTo(px+View.pdist(circ.innerdia/2),py);
		G.ctx.arc(px,py,View.pdist(circ.innerdia/2),0,2*Math.PI,circ.isvoid?false:true);
		G.ctx.closePath();
	}
}
function outpath_poly(poly:GemPoly):void{
	poly.ensurecw(); // we need to follow CW policy to use flatten-js

	if (poly.pps.length===2 && poly.pps[0].p.equals(poly.pps[1].p) && poly.pps[1] instanceof GemArcTo){
		const ac = (<GemArcTo>poly.pps[1]).ac;
		const radius = GemEuclid.dist(poly.pps[0].p,ac);
		G.ctx.lineWidth = 1;
		const px = View.px(ac.x);
		const py = View.py(ac.y);
		G.ctx.moveTo(px+View.pdist(radius),py);
		G.ctx.arc(px,py,View.pdist(radius),0,2*Math.PI,false);
		G.ctx.closePath();
		prev = ac;
		return;
	}

	if (poly.isclosed) G.ctx.lineWidth = 1;
	else{
		G.ctx.lineWidth = Math.max(1,View.pdist(poly.ape));
		G.ctx.lineCap = "round";
		G.ctx.lineJoin = "round";
	}
	var prev = poly.isclosed?poly.pps[poly.pps.length-1].p:poly.pps[0].p;
	G.ctx.moveTo(View.px(prev.x),View.py(prev.y));
	for(var i = 0 ; i < poly.pps.length ; i++){
		const p2:GemMoveTo = poly.pps[i];
		if (!poly.isclosed&&i===0) continue;
		if (p2 instanceof GemArcTo){
			const arc:GemArcTo = p2;
			G.ctx.arc(View.px(arc.ac.x),View.py(arc.ac.y),
				View.pdist(GemEuclid.dist(arc.ac,arc.p)),
				View.pang(GemEuclid.angdeg(arc.ac,prev)),
				View.pang(GemEuclid.angdeg(arc.ac,arc.p)),
				arc.isccw);
		}else if (p2 instanceof GemArc3To){
			const arc3:GemArc3To = p2;
			const ac:GemPoint = arc3.ac(prev);
			G.ctx.arc(View.px(ac.x),View.py(ac.y),
				View.pdist(GemEuclid.dist(ac,arc3.p)),
				View.pang(GemEuclid.angdeg(ac,prev)),
				View.pang(GemEuclid.angdeg(ac,arc3.p)),
				GemEuclid.ccw(prev,arc3.mp,arc3.p)>0);
					// (js memo) in HTML5 canvas, y-axis is negative, and the rotation is clockwise, then
					// the ccw sense is the same.
		}else if (p2 instanceof GemMoveTo){ // (js note) derived type must be checked later.
			G.ctx.lineTo(View.px(p2.p.x),View.py(p2.p.y));
		}
		prev = p2.p;
	}
	if (poly.isclosed){
		G.ctx.closePath();
	}
}
function outpath_shape(shape:GemShape):void{
	if (shape instanceof GemPoly) outpath_poly(shape);
	else if (shape instanceof GemCircle) outpath_circle(shape);
	else throw Error("shape.classname="+shape.classname);
}
function search_text_in_pad(pad:Pad, text:string):void{
	if (!View.is_pad_visible(pad)) return;
	if (text===pad.lineno.toString()||text===pad.shape.lineno.toString()||
	(pad.antipad_shape && text===pad.antipad_shape.lineno.toString())){
		if (View.is_layer_visible(pad.layer)){
			pad.select(true);
			Search.pntmarks.push(new PointMark(pad.bbox().center(),text+" "+text));
		}
	}
}
function intersects_with_pad(pad:Pad, probe:GemBbox):boolean{
	if (!View.is_pad_visible(pad)) return false;
	if (probe.intersect(pad.shape,true,false)) return true;
	if (pad.antipad_shape && probe.intersect(pad.antipad_shape,true,false)) return true;
	return false;
}
function intersects_with_padstack(padstack:Padstack, probe:GemBbox):boolean{
	for(var i = 0 ; i < padstack.pads.length ; i++){
		if (intersects_with_pad(padstack.pads[i],probe)) return true;
	}
	return false;
}
function draw_pad(pad:Pad, color:string):void{
	G.ctx.fillStyle = color;
	G.ctx.strokeStyle = pad.selected? G.SELECTED_COLOR: color;
	G.ctx.beginPath();
	outpath_shape(pad.shape);
	if (pad.layer===0) G.ctx.stroke();
	else if (pad.selected){
		G.ctx.fill();
		G.ctx.stroke();
	}else G.ctx.fill();
}

function search_text_in_shape(shape:GemShape, text:string):void{
	if (shape.layer && !View.is_layer_visible(shape.layer)) return;
	if (text===shape.lineno.toString()){
		shape.select(true);
		Search.pntmarks.push(new PointMark(shape.bbox().center(),text+" "+text));
	}
}
function search_text_in_padstack(psk:Padstack, text:string):void{
	if (text===psk.lineno.toString()){
		psk.select(true);
		Search.pntmarks.push(new PointMark(psk.bbox().center(),text+" "+psk.lineno));
	}else{
		for(var i = 0 ; i < psk.pads.length ; i++){
			search_text_in_pad(psk.pads[i],text);
		}
	}
}
function search_text_in_via(via:Via, text:string):void{
	if (text===via.lineno.toString()){
		via.select(true);
		Search.pntmarks.push(new PointMark(via.bbox().center(),text+" "+via.lineno));
	}else{
		search_text_in_padstack(via.psk,text);
		if (via.barrel) search_text_in_shape(via.barrel,text);
	}
}
function search_text_in_wire(wire:Wire, text:string):void{
	if (text===wire.lineno.toString()){
		wire.select(true);
		Search.pntmarks.push(new PointMark(wire.bbox().center(),text+" "+wire.lineno));
	}
}
// function is_pad_in_padstack_on_layer(psk:Padstack, layer:number):boolean{
// 	for(var i = 0 ; i < psk.pads.length ; i++){
// 		if (psk.pads[i].layer===layer) return true;
// 	}
// 	return false;
// }
/**
 * draw the objects in the padstack, in the scenario of drawing the layer,
 * which is proven visible.
 */
function draw_pads_in_padstack_on_layer(psk:Padstack,color:string,layer:number):void{
	for(var i = 0 ; i < psk.pads.length ; i++){
		const pad = psk.pads[i];
		if (pad.layer===layer) draw_pad(psk.pads[i],color);
	}
}

function select_by_rect_in_via(via:Via, probe:GemBbox):void{
	if (!View.is_via_visible(via)) return;
	if (probe.contains(via.origin)||intersects_with_padstack(via.psk,probe)||
	(via.barrel && probe.intersect(via.barrel,true,false))){
		via.select(true);
		Search.pntmarks.push(new PointMark(via.origin,"via "+via.net.name+" "+via.lineno));
	}
}
function draw_pads_in_via_on_layer(via:Via, color:string,layer:number):void{
	if (!View.is_via_visible(via)) return;
	if (layer<via.lay1||via.lay2<layer) return;
	draw_pads_in_padstack_on_layer(via.psk,color,layer);
}
/**
 * draw the hole, in the scenario of drawing the current layer.
 */
function draw_hole_in_via_on_layer(via:Via, color:string,layer:number):void{
	if (!View.is_via_visible(via)) return;
	if (layer<via.lay1||via.lay2<layer) return;
	color = (via.selected)? G.SELECTED_COLOR: color;
	if (via.barrel){
		G.ctx.strokeStyle = color;
		G.ctx.beginPath();
		outpath_shape(via.barrel);
		G.ctx.stroke();
	}


	// draw the hole range mark.
	var sz = View.pdist(via.bbox().width()/6);
	var px = View.px(via.bbox().center().x);
	var py = View.py(via.bbox().center().y);
	G.ctx.strokeStyle= color;
	G.ctx.lineWidth = 1;
	G.ctx.moveTo(px-sz,py);
	G.ctx.lineTo(px+sz,py);
	if (View.curr_layer>via.lay1){
		// top peak triangle
		G.ctx.moveTo(px-sz,py);
		G.ctx.lineTo(px,py-sz);
		G.ctx.lineTo(px+sz,py);
	}
	if (View.curr_layer<via.lay2){
		// bottom peak triangle
		G.ctx.moveTo(px-sz,py);
		G.ctx.lineTo(px,py+sz);
		G.ctx.lineTo(px+sz,py);
	}
	G.ctx.stroke();
}

function search_text_in_pin(pin:Pin, text:string):void{
	if (text===pin.lineno.toString()||text===pin.pinname||
	RegExp(pin.par.uname+"."+pin.pinname+'$').test(text)){
		pin.select(true);
		mkpointmark_for_pin(pin,text);
	}
}
function select_by_rect_in_pin(pin:Pin, probe:GemBbox):void{
	if ((View.comp_vis
		&& View.is_layer_visible(pin.par.placed_layer())
		&& probe.contains(pin.p)
		)||(
		pin.padstk
		&& intersects_with_padstack(pin.padstk,probe)
		)){
		var s = pin.par.uname+"."+pin.pinname;
		if (pin.net) s += " "+pin.net.name;
		s += " "+pin.lineno.toString();
		Search.pntmarks.push(new PointMark(pin.p,s));
		pin.select(true);
	}
}
function mkpointmark_for_pin(pin:Pin, text:string):void{
	Search.pntmarks.push(new PointMark(pin.p,text+" "+pin.lineno));
	// if (View.pinpos_vis){
	// 	Search.pnts.push(new PointMark(pin.p,text+" "+pin.lineno));
	// }
	// if (pin.padstk && !pin.padstk.bbox().center().equals(pin.p)){
	// 	Search.pnts.push(new PointMark(pin.padstk.bbox().center(),text+" "+
	// 	pin.padstk.lineno));
	// }
}
function search_text_in_comp(comp:Comp, text:string):void{
	// if (View.is_layer_visible(comp.placed_layer())){
		if (text===comp.lineno.toString()||text===comp.partname||text===comp.uname){
			comp.select(true);
			Search.pntmarks.push(new PointMark(comp.shape.textpnt(),text+" "+comp.lineno));
		}else{
			for(var i = 0 ; i < comp.pins.length ; i++){
				const pin = comp.pins[i];
				// if (View.is_pin_visible(pin)){
					search_text_in_pin(comp.pins[i],text);
				// }
			}
		}
	// }
	for(var i = 0 ; i < comp.pins.length ; i++){
		const pin = comp.pins[i];
		if (pin.padstk) search_text_in_padstack(pin.padstk,text);
	}
}
function select_by_rect_in_comp(comp:Comp, probe:GemBbox):void{
	if (View.comp_vis && View.is_layer_visible(comp.placed_layer()) &&
		probe.intersect(comp.shape,true,false)){
		Search.pntmarks.push(new PointMark(comp.shape.textpnt(),comp.uname+" "+comp.partname+" "+comp.lineno));
		comp.select(true);
	}
	for(var i = 0 ; i < comp.pins.length ; i++){
		select_by_rect_in_pin(comp.pins[i],probe);
	}
}
/**
 * draw pattern objects on the layer.
 */
function draw_pads_in_comp_on_layer(comp:Comp, layer:number):void{
	for(var i = 0 ; i < comp.pins.length ; i++){
		const pin:Pin = comp.pins[i];
		if (pin.net && !pin.net.visible) continue;
		if (pin.padstk){
			draw_pads_in_padstack_on_layer(pin.padstk,net_color(pin.net),layer);
		}
	}
}
function draw_compshape(comp:Comp):void{
	var color = comp.shape.selected? G.SELECTED_COLOR: comp.flipped? G.FLIPPED_COMPSHAPE_COLOR: G.COMPSHAPE_COLOR;
	G.ctx.strokeStyle = G.ctx.fillStyle = color;
	G.ctx.beginPath();
	outpath_shape(comp.shape);
	G.ctx.stroke();
	for(var i = 0 ; i < comp.pins.length ; i++){
		const pin:Pin = comp.pins[i];
		if (pin.padstk){
			draw_pads_in_padstack_on_layer(pin.padstk,net_color(pin.net),0);
				// we allow pad having layer 0 for a comp pin considering a wire bonded dpad,
				// and draw the pad figure with die shape. (note 101).
		}
	}
}
function draw_pinpos_in_comp(comp:Comp):void{
	for(let pin of comp.pins){
		draw_x_mark(pin.p,pin.selected?G.SELECTED_COLOR:G.PINPOS_COLOR);
	}
}
function draw_pinpos_in_via(via:Via):void{
	draw_x_mark(via.origin,via.selected?G.SELECTED_COLOR:G.PINPOS_COLOR);
}
/**
 * draw text info for a pin, if visibility is set.
 */
function draw_textinfo_in_comp(comp:Comp):void{
	if (View.compname_vis||View.lineno_vis){
		var s = "";
		if (View.compname_vis) s = comp.uname+" "+comp.partname;
		if (View.lineno_vis){
			if (s!=="") s += " ";
			s += " "+comp.lineno;
		}
		var px = View.px(comp.shape.textpnt().x);
		var py = View.py(comp.shape.textpnt().y);
		G.ctx.textAlign = "start";
		G.ctx.fillText(s,px,py-4);
	}
	if (View.pinname_vis||View.netname_vis||View.lineno_vis){
		for(var i = 0 ; i < comp.pins.length ; i++){
			const pin:Pin = comp.pins[i];
			var ss:string[] = [];
			if (View.pinname_vis) ss.push(pin.pinname);
			if (View.netname_vis && pin.net && pin.net.visible) ss.push(pin.net.name);
			if (View.lineno_vis) ss.push(pin.lineno.toString());
			draw_text_at_center(ss,pin.p);
		}
	}
}

function select_by_rect_in_wire(wire:Wire, probe:GemBbox):void{
	if (View.wire_vis && View.is_wire_visible(wire)
		&& probe.intersect(wire.shape,true,false)){
		wire.select(true);
		Search.pntmarks.push(new PointMark(wire.bbox().center(),
		"wire "+wire.par.name+" "+wire.lineno));
	}
}
function draw_wire(wire:Wire, color:string):void{
	G.ctx.strokeStyle = wire.selected?G.SELECTED_COLOR:color;
	G.ctx.beginPath();
	G.ctx.moveTo(View.px(wire.p1.x),View.py(wire.p1.y));
	G.ctx.lineTo(View.px(wire.p2.x),View.py(wire.p2.y));
	G.ctx.lineWidth=1;
	G.ctx.stroke();
}

function search_text_in_net(net:Net, text:string):void{
	if (text===net.name||text===net.lineno.toString()){
		net.select(true);
		for(var i = 0 ; i < net.pins.length ; i++){
			const pin = net.pins[i];
			if (View.is_pin_visible(pin)){
				mkpointmark_for_pin(pin,text);
			}
		}
		if (text===net.name){
			if (View.via_vis){
				for(var i = 0 ; i < net.vias.length ; i++){
					const via = net.vias[i];
					if (View.is_via_visible(via)){
						Search.pntmarks.push(new PointMark(via.bbox().center(),text+" "+via.lineno));
					}
				}
			}
			if (View.wire_vis){
				for(var i = 0 ; i < net.wires.length ; i++){
					const wire = net.wires[i];
					if (View.is_wire_visible(wire)){
						Search.pntmarks.push(new PointMark(wire.bbox().center(),text+" "+wire.lineno));
					}
				}
			}
			if (View.route_vis){
				for(var i = 0 ; i < net.shapes.length ; i++){
					const shape = net.shapes[i];
					if (View.is_layer_visible(shape.layer)){
						Search.pntmarks.push(new PointMark(shape.textpnt(),text+" "+shape.lineno));
					}
				}
			}
		}
	}else{
		if (View.via_vis){
			for(var i = 0 ; i < net.vias.length ; i++){
				search_text_in_via(net.vias[i],text);
			}
		}
		if (View.wire_vis){
			for(var i = 0 ; i < net.wires.length ; i++){
				search_text_in_wire(net.wires[i],text);
			}
		}
		if (View.route_vis){
			for(var i = 0 ; i < net.shapes.length ; i++){
				search_text_in_shape(net.shapes[i],text);
			}
		}
	}
}
function net_color(net:Net):string{
	if (net==null) return G.NONET_COLOR;
	else return G.mid_colors[net.idx%G.mid_colors.length];
}
function select_by_rect_in_net(net:Net, probe:GemBbox):void{
	if (View.via_vis){
		for(var i = 0 ; i < net.vias.length ; i++){
			select_by_rect_in_via(net.vias[i],probe);
		}
	}
	if (View.wire_vis){
		for(var i = 0 ; i < net.wires.length ; i++){
			select_by_rect_in_wire(net.wires[i],probe);
		}
	}
	if (View.route_vis){
		for(var i = 0 ; i < net.shapes.length ; i++){
			const shape = net.shapes[i];
			if (shape.layer>0&&View.is_layer_visible(shape.layer)){
				const bb = shape.bbox();
				let bbinclude = (probe.xmin<=bb.xmin && bb.xmax<=probe.xmax &&
					probe.ymin<=bb.ymin&&bb.ymax<=probe.ymax);
				if (bbinclude || probe.intersect(shape,true,false)){
					shape.select(true);
					Search.pntmarks.push(new PointMark(shape.textpnt(),
					shape.fig+" "+net.name+" "+shape.lineno));
				}
			}
		}
	}
}
function draw_text_at_center(ss:string[], p:GemPoint):void{
	// draw text lines at pin center
	const pcx = View.px(p.x);
	const pcy = View.py(p.y);
	G.ctx.textAlign = "center";
	for(var j = 0 ; j < ss.length ; j++){
		const py = pcy - G.FONT_SIZE*(ss.length-1)/2 + j * G.FONT_SIZE + G.FONT_SIZE/2 - 2;
		G.ctx.fillText(ss[j],pcx,py);
	}
}
function draw_x_mark(p:GemPoint, color:string):void{
	const x = View.px(p.x);
	const y = View.py(p.y);
	G.ctx.strokeStyle = G.ctx.fillStyle = color;
	G.ctx.lineWidth = 1;
	G.ctx.beginPath();
	G.ctx.moveTo(x-G.PINPOSMARKSIZE/2,y-G.PINPOSMARKSIZE/2);
	G.ctx.lineTo(x+G.PINPOSMARKSIZE/2,y+G.PINPOSMARKSIZE/2);
	G.ctx.moveTo(x+G.PINPOSMARKSIZE/2,y-G.PINPOSMARKSIZE/2);
	G.ctx.lineTo(x-G.PINPOSMARKSIZE/2,y+G.PINPOSMARKSIZE/2);
	G.ctx.lineWidth = 1;
	G.ctx.stroke();
}
function clear_canvas():void{
	G.ctx.globalAlpha = 1;
	G.ctx.fillStyle = G.BACKGROUND_COLOR;
	G.ctx.fillRect(0,0,G.canvas.width,G.canvas.height);
}
export function redraw():void{
	clear_canvas();
	if (!isdata()) return;
	draw();
	// View.drawLayerNumber();
}
export function isdata():boolean{
	return (G.gfmt&&G.gfmt.boardshapes.length>0&&G.gfmt.layerCnt()>0);
}
export function fit_redraw():void{
	G.canvas.width = G.canvas.parentElement.clientWidth;
	G.canvas.height = G.canvas.parentElement.clientHeight;
		/* refreshing canvas's logical width to the actual pixel width.
		without this, logical width won't change, leading auto-scaling in
		the browser, resulted a blurred image */
	G.ctx.font = G.FONT_SIZE+"px sans-serif";
	View.scale = 1.0;
	if (!isdata()){
		clear_canvas();
		return;
	}
	View.canvascen.copy(G.gfmt.bbox().center());
	redraw();
}
function try_text_as_coord(text:string):GemPoint{
	const ss:string[] = text.split(/[ ,]/);
	if (ss.length==2&& /[0-9]+/.test(ss[0]) && /[0-9]+/.test(ss[1])){
		return new GemPoint(Number.parseFloat(ss[0]),Number.parseFloat(ss[1]));
	}else return null;
}
function search_in_gfmt(text:string):void{
	const p = try_text_as_coord(text);
	if (p){
		Search.pntmarks.push(new PointMark(p,text));
	}else{
		for(var i = 0 ; i < G.gfmt.boardshapes.length ; i++){
			search_text_in_shape(G.gfmt.boardshapes[i],text);
		}
		if (View.comp_vis){
			for(var i = 0 ; i < G.gfmt.comps.length ; i++){
				search_text_in_comp(G.gfmt.comps[i],text);
			}
		}
		if (View.route_vis||View.via_vis||View.wire_vis){
			for(var i = 0 ; i < G.gfmt.nets.length ; i++){
				search_text_in_net(G.gfmt.nets[i],text);
			}
		}
	}
}
/**
 * search data by the coordinates. this function searches
 * only the displayed objects, because what user want is to
 * investigate a displayed objects.
 */
function select_by_rect_in_gfmt(probe:GemBbox):void{
	if (View.subs_vis){
		for(var i = 0 ; i < G.gfmt.boardshapes.length ; i++){
			const shape = G.gfmt.boardshapes[i];
			if (probe.intersect(shape,true,false)){
				shape.select(true);
				Search.pntmarks.push(new PointMark(shape.textpnt(),"boardshape "+shape.fig+" "+shape.lineno));
			}
		}
	}
	if (View.comp_vis){
		for(var i = 0 ; i < G.gfmt.comps.length ; i++){
			select_by_rect_in_comp(G.gfmt.comps[i],probe);
		}
	}
	if (View.route_vis||View.via_vis||View.wire_vis){
		for(var i = 0 ; i < G.gfmt.nets.length ; i++){
			select_by_rect_in_net(G.gfmt.nets[i],probe);
		}
	}
}
export function draw():void{
	// (1) draw board shapes
	G.ctx.globalAlpha = 1;
	if (View.subs_vis){
		const board_fill_color = G.black_colors[(View.curr_layer-1)%G.black_colors.length];
		draw_shapes(G.gfmt.boardshapes,0,G.BOARDSHAPE_COLOR,board_fill_color);
	}
	// (2) draw patterns, thin-line shapes, and texts, on each layer 1,2,3..
	for(var layer = G.gfmt.layerCnt() ; layer >= 1  ; layer--){
		if (!View.is_layer_visible(layer)) continue;
		G.ctx.globalAlpha = View.calcAlpha(layer,0);
		// (2.1) pattern in route data
		if (View.route_vis){
			for(var i = 0 ; i < G.gfmt.nets.length ; i++){
				const net:Net = G.gfmt.nets[i];
				if (!net.visible) continue;
				const color = net_color(net);
				draw_shapes(net.shapes,layer,color,color);
			}
		}
		// (2.2) pads in vias
		if (View.via_vis){
			for(var i = 0 ; i < G.gfmt.nets.length ; i++){
				const net:Net = G.gfmt.nets[i];
				if (net && !net.visible) continue;
				const color = net_color(net);
				for(var j = 0 ; j < net.vias.length ; j++){
					const via = net.vias[j];
					draw_pads_in_via_on_layer(via,color,layer);
				}
			}
		}
		// (2.3) pinpos in vias
		if (View.via_vis && View.viapos_vis){
			for(var i = 0 ; i < G.gfmt.nets.length ; i++){
				const net:Net = G.gfmt.nets[i];
				for(var j = 0 ; j < net.vias.length ; j++){
					const via = net.vias[j];
					if (View.is_via_visible(via)){
						draw_pinpos_in_via(via);
					}
				}
			}
		}

		// (2.4) pads in comps.
		G.ctx.globalAlpha = View.calcAlpha(layer,1);
		if (View.comp_vis){
			// pads in the comps are drawn, not only the die shapes.
			for(var i = 0 ; i < G.gfmt.comps.length ; i++){
				draw_pads_in_comp_on_layer(G.gfmt.comps[i],layer);
			}
		}
		// (2.5) via hole
		G.ctx.globalAlpha = View.calcAlpha(layer,-1);
		if (layer===View.curr_layer&&View.via_vis){
			for(var i = 0 ; i < G.gfmt.nets.length ; i++){
				const net:Net = G.gfmt.nets[i];
				if (!net.visible) continue;
				for(var j = 0 ; j < net.vias.length ; j++){
					const via = net.vias[j];
					draw_hole_in_via_on_layer(via,G.VIAMARK_COLOR,layer);
				}
			}
		}
		// (2.6) comp shapes
		G.ctx.globalAlpha = View.calcAlpha(layer,2);
		if (View.comp_vis){
			for(var i = 0 ; i < G.gfmt.comps.length ; i++){
				const comp = G.gfmt.comps[i];
				if (comp.placed_layer()==layer) draw_compshape(comp);
			}
		}
		// (2.7) pinpos in comp pins
		if (View.comp_vis && View.pinpos_vis){
			for(var i = 0 ; i < G.gfmt.comps.length ; i++){
				const comp = G.gfmt.comps[i];
				if (comp.placed_layer()==layer) draw_pinpos_in_comp(comp);
			}
		}
		// (2.8) wires
		if (View.wire_vis){
			for(var i = 0 ; i < G.gfmt.nets.length ; i++){
				const net:Net = G.gfmt.nets[i];
				if (!net.visible) continue;
				for(var j = 0 ; j < net.wires.length ; j++){
					const wire = net.wires[j];
					if (View.is_wire_visible(wire)){
						draw_wire(wire,net_color(net));
					}
				}
			}
		}
		// (2.9) text info
		G.ctx.globalAlpha = 1;
		G.ctx.fillStyle=G.TEXT_COLOR;
		if (View.via_vis&&(View.netname_vis||View.lineno_vis)){
			for(var i = 0 ; i < G.gfmt.nets.length ; i++){
				const net:Net = G.gfmt.nets[i];
				if (!net.visible) continue;
				for(var j = 0 ; j < net.vias.length ; j++){
					const via = net.vias[j];
					if (View.is_via_visible(via)){
						var ss:string[] = [];
						if (View.netname_vis&&via.net) ss.push(via.net.name);
						if (View.lineno_vis) ss.push(via.lineno.toString());
						draw_text_at_center(ss,via.bbox().center());
					}
				}
			}
		}
		if (View.comp_vis&&(View.compname_vis||View.pinname_vis||View.netname_vis||View.lineno_vis)){
			for(var i = 0 ; i < G.gfmt.comps.length ; i++){
				const comp = G.gfmt.comps[i];
				if (comp.placed_layer()==layer) draw_textinfo_in_comp(comp);
			}
		}
	}
	// (3) point marks
	if (View.objinfo_vis){
		for(var i = 0 ; i < Search.pntmarks.length ; i++){
			const pntmark = Search.pntmarks[i];
			const px = View.px(pntmark.pnt.x);
			const py = View.py(pntmark.pnt.y);
			G.ctx.globalAlpha = 1;
			G.ctx.strokeStyle = G.ctx.fillStyle = G.POINTMARK_COLOR;
			G.ctx.lineWidth=1;
			G.ctx.beginPath();
			G.ctx.moveTo(px,py);
			G.ctx.lineTo(px+G.POINTMARK_SIZE,py-G.POINTMARK_SIZE/2);
			G.ctx.lineTo(px+G.POINTMARK_SIZE/2,py-G.POINTMARK_SIZE);
			G.ctx.closePath();
			G.ctx.stroke();
			G.ctx.textAlign="start";
			G.ctx.fillText(pntmark.text,px+G.POINTMARK_SIZE*0.75+2,py-G.POINTMARK_SIZE*0.75-2);
		}
	}
	/*
	(js note) how polygon with holes can be drawn in HTML5 canvas.
	(1) ctx.beginPath()
	(2) define polygons as closed CW paths,	define void shapes as closed
	CCW ordered paths, in arbitrary order.
	(3) ctx.fill();
	the voids and outlines need not to be co-related.
	if voids are defined outside of outline polygon, the area is drawn by odd-even rule.
	the outline is always zero width in ctx.fill().
	below is a demo code.
	*/
}
function add_mouse_handler():void{
	enum MouseIs{
		IDLE, PANNING, SELECTING, ZOOMING,
	}
	var px1:number;
	var py1:number;
	var px2:number;
	var py2:number;
	var mouseis:MouseIs = MouseIs.IDLE;
	var image:ImageData = null;
	var probe_rect:GemBbox = new GemBbox;
	var get_pxpy = function(ev:MouseEvent):[number, number]{
		var bb = G.canvas.getBoundingClientRect();
		const px = Math.round((ev.x - bb.left)*G.canvas.width/bb.width);
		const py = Math.round((ev.y - bb.top)*G.canvas.height/bb.height);
			// mouse coord is based on bb, then need conversion to canvas coord.
		return [px,py];
	}
	// pan by mouse middle button dragging
	G.canvas.addEventListener('mousedown',function(ev:MouseEvent){
		if (ev.ctrlKey||ev.metaKey||ev.altKey) return;
		if (ev.button!==0) return; // 0:left button, 1:wheel button, 2:right button, 3: browser back, 4: browser forward
		if (mouseis!==MouseIs.IDLE) return;
		[px1,py1] = get_pxpy(ev);
		if (View.is_mouse_zoommode){
			mouseis = MouseIs.ZOOMING;
		}else if (View.is_mouse_selmode || ev.shiftKey){
			mouseis = MouseIs.SELECTING;
		}else{
			mouseis = MouseIs.PANNING;
			image = G.ctx.getImageData(0,0,G.canvas.width,G.canvas.height);
		}
	});
	G.canvas.addEventListener('mousemove',function(ev:MouseEvent){
		if (ev.ctrlKey||ev.metaKey||ev.altKey) return;
		if (ev.button!=0) return;
		if (mouseis===MouseIs.IDLE) return;
		const [px3,py3] = get_pxpy(ev);
		if (px1===px3&&py1===py3) return;
		if (mouseis === MouseIs.SELECTING || mouseis === MouseIs.ZOOMING){
			if (image){
				G.ctx.putImageData(image,Math.min(px1,px2)-1,Math.min(py1,py2)-1);
				image = null;
			}
			image = G.ctx.getImageData(Math.min(px1,px3)-1,Math.min(py1,py3)-1,
			Math.abs(px1-px3)+2,Math.abs(py1-py3)+2);
			G.ctx.globalAlpha = 1;
			G.ctx.strokeStyle = "white";
			G.ctx.lineWidth = 1;
			G.ctx.strokeRect(Math.min(px1,px3)+0.5,Math.min(py1,py3)+0.5,Math.abs(px1-px3),Math.abs(py1-py3));
			px2 = px3;
			py2 = py3;
		}else if (mouseis == MouseIs.PANNING){
			clear_canvas();
			G.ctx.putImageData(image,px3-px1,py3-py1);
		}
	});

	G.canvas.addEventListener('mouseup',function(ev:MouseEvent){
		if (ev.ctrlKey||ev.metaKey||ev.altKey) return;
		if (ev.button!=0) return;
		if (mouseis===MouseIs.IDLE) return;
		const [px3,py3] = get_pxpy(ev);
		const ux1 = View.ux(px1);
		const uy1 = View.uy(py1);
		const ux3 = View.ux(px3);
		const uy3 = View.uy(py3);
		if (mouseis === MouseIs.SELECTING || mouseis === MouseIs.ZOOMING){
			if (image){
				G.ctx.putImageData(image,Math.min(px1,px2),Math.min(py1,py2));
				image = null;
			}
			probe_rect.xmin = Math.min(ux1,ux3);
			probe_rect.ymin = Math.min(uy1,uy3);
			probe_rect.xmax = Math.max(ux1,ux3);
			probe_rect.ymax = Math.max(uy1,uy3);
			probe_rect.clearCache();
			if (mouseis === MouseIs.SELECTING) Search.select_by_rect(probe_rect);
			else View.zoom_by_rect(probe_rect);
		}else if (mouseis === MouseIs.PANNING){
			image = null;
			View.canvascen.x -= ux3 - ux1;
			View.canvascen.y -= uy3 - uy1;
			redraw();
		}
		if (View.is_mouse_zoommode) View.is_mouse_zoommode = false;
		mouseis = MouseIs.IDLE;
	});

	// mouse wheel zooming
	G.canvas.addEventListener('wheel',function(ev:WheelEvent){
		if (ev.metaKey||ev.altKey) return;
		ev.preventDefault(); // wheel makes scroll bar move if any. disable it.
		if (ev.ctrlKey){
			const [px,py] = get_pxpy(ev);
			const ux = View.ux(px);
			const uy = View.uy(py);
			if (ev.deltaY<0) View.scale *= G.ZOOM_BY_WHEEL;
			else View.scale /= G.ZOOM_BY_WHEEL;
			View.canvascen.setXY(ux-View.udist(px-G.canvas.width/2),uy+View.udist(py-G.canvas.height/2));
			redraw();
		}else if (ev.shiftKey){
			if (ev.deltaY<0){
				View.canvascen.x -= Math.min(G.canvas.width,G.canvas.height) * G.PAN_BY_BUTTON / View.upratio();
				redraw();
			}else{
				View.canvascen.x += Math.min(G.canvas.width,G.canvas.height) * G.PAN_BY_BUTTON / View.upratio();
				redraw();
			}
		}else{
			if (ev.deltaY<0){
				View.canvascen.y += Math.min(G.canvas.width,G.canvas.height) * G.PAN_BY_BUTTON / View.upratio();
				redraw();
			}else{
				View.canvascen.y -= Math.min(G.canvas.width,G.canvas.height) * G.PAN_BY_BUTTON / View.upratio();
				redraw();
			}
		}
	});

	// zoom by double click
	G.canvas.addEventListener('dblclick',function(ev:MouseEvent){
		ev.preventDefault(); // wheel makes scroll bar move if any. disable it.
		const [px,py] = get_pxpy(ev);
		const ux = View.ux(px);
		const uy = View.uy(py);
		View.scale *= G.ZOOM_BY_DOUBLECLICK;
		View.canvascen.setXY(ux-View.udist(px-G.canvas.width/2),uy+View.udist(py-G.canvas.height/2));
		redraw();
	});

	// coordinates monitoring field
	// var coord_label:HTMLElement = document.getElementById("coord");
	G.canvas.addEventListener('mousemove',function(ev:MouseEvent){
		if (ev.shiftKey||ev.ctrlKey||ev.metaKey||ev.altKey) return;
		if (mouseis!==MouseIs.IDLE) return;
		const [px,py] = get_pxpy(ev);
		const ux = Math.round(View.ux(px)*10)/10;
		const uy = Math.round(View.uy(py)*10)/10;
		// coord_label.innerHTML = "("+ux+","+uy+")";
		View.curr_mouse_ux = ux;
		View.curr_mouse_uy = uy;
	});
   
}
function add_keyboard_handler():void{
	// keyboard panning, etc.
	enum KeyCode{
		ArrowLeft=37,
		ArrowDown=40,
		ArrowUp=38,
		ArrowRight=39,
		PageUp=33,
		PageDown=34,
		Home=36,
		Delete=46,
		F=70,
		Z=90,
		X=88,
	}
	window.addEventListener('keydown',function(ev:KeyboardEvent){
		// ev.preventDefault(); // wheel makes scroll bar move if any. disable it.
		switch(ev.keyCode||ev.which){ // FireFox returns keyCode = 0.
			case KeyCode.ArrowLeft:
				View.canvascen.x -= Math.min(G.canvas.width,G.canvas.height) * G.PAN_BY_BUTTON / View.upratio();
				redraw();
				break;
			case KeyCode.ArrowRight:
				View.canvascen.x += Math.min(G.canvas.width,G.canvas.height) * G.PAN_BY_BUTTON / View.upratio();
				redraw();
				break;
			case KeyCode.ArrowUp:
				View.canvascen.y += Math.min(G.canvas.width,G.canvas.height) * G.PAN_BY_BUTTON / View.upratio();
				redraw();
				break;
			case KeyCode.ArrowDown:
				View.canvascen.y -= Math.min(G.canvas.width,G.canvas.height) * G.PAN_BY_BUTTON / View.upratio();
				redraw();
				break;
			case KeyCode.Home: case KeyCode.F:
				fit_redraw();
				break;
			case KeyCode.PageUp:
				View.curr_layer = Math.max(1,View.curr_layer-1);
				redraw();
				break;
			case KeyCode.PageDown:
				View.curr_layer = Math.min(G.gfmt.layerCnt(),View.curr_layer+1);
				redraw();
				break;
			case KeyCode.Z:
				View.scale *= G.ZOOM_BY_BUTTON;
				redraw();
				break;
			case KeyCode.X:
				View.scale /= G.ZOOM_BY_BUTTON;
				redraw();
				break;
			case KeyCode.Delete:
				Search.clear_search_result();
				G.gfmt.clear_selection();
				redraw();
		}
	});

}

// function add_button_handler():void{
// 	// other buttons are handled by angular.
// 	const alpha_curr_slider:HTMLInputElement = <HTMLInputElement>document.getElementById("alpha_curr");
// 	alpha_curr_slider.addEventListener("change",function(ev:Event){
// 		View.alpha_curr = Number(alpha_curr_slider.value)*0.01;
// 		redraw();
// 	});
// 	const alpha_noncurr_slider:HTMLInputElement = <HTMLInputElement>document.getElementById("alpha_noncurr");
// 	alpha_noncurr_slider.addEventListener("change",function(ev:Event){
// 		View.alpha_noncurr = Number(alpha_noncurr_slider.value)*0.01;
// 		redraw();
// 	});
// }
export function setup_canvas(){
	G.gfmt = new Gfmt();
		// import from src folder makes error: 'gemlib_src_gfmt_WEBPACK_IMPORTED_MODULE_1__.Gfmt is not a constructor'
		// import from dist_fe folder goes good.
		// Gfmt.mkinst() does not help.
		// new gfmt.Gfmt() does not help.
		// could be related to the fact that 'interface' disappear in compiling ts to js.
		// they say 'd.ts' is needed to export/import interface, which is fulfilled in dist_fe but not in src folder.
		// then if we provide d.ts in src folder, then it may solve the problem.
		
	// obtaining canvas and it's graphic context
	G.canvas = <HTMLCanvasElement>document.querySelector('#ggv_canvas');
	G.ctx = G.canvas.getContext('2d');
	/* (js note) fabric.js
		here we use the row canvas. there is an additional tool called fabric.js, which is built on top of the
		canvas, and provide the figures we draw are treated as objects. once draw, the drawn figure can be
		independently selected, moved, rotated, etc without additional code. maybe we need it when we make
		a cad-like tool. but here, we are doing just viewer, then the row canvas is sufficient.
		*/
	add_keyboard_handler();
	add_mouse_handler();
	// add_button_handler();
	fit_redraw();
}


