import { FormGroup, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
export const matchvalidator = function(
    f1name: string,
    f2name: string,
    errname: string
): ValidatorFn{
    return (control:AbstractControl):ValidationErrors|null=>{
        const fld1 = control.get(f1name);
        const fld2 = control.get(f2name);
        if (fld1 && fld2 && fld1.value === fld2.value) return null;
        const obj = new Object();
        Object.defineProperty(obj,errname,{value:true});
        return obj;
    }
}
