import { LoginDtoI, UserDroI, RegisterEmailDtoI, RegisterPasswordDtoI, IssueSeccodeDtoI,
    ChangeEmailDtoI, ChangePasswordDtoI, ChangeUnameDtoI, ChangePrefDtoI, GroupDroI,
    AddGroupDtoI, ChangeQuotaDtoI, AddGroupMemberDtoI, RemoveGroupMemberDtoI,
    AssignUnassignGroupManagerDtoI, RenameGroupDtoI,
    QuotaPurchaseInquiryDtoI, 
    LicPurchaseInquiryDtoI} from 'gemlib/dist_fe/dtodro';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { CookieKey } from '@app/helper/cookie-key';
import { Util } from '@app/helper/util';

export class DiskPlan{
    quotakb: number;
    get quotakb_str(){
        return Util.kbstr(this.quotakb);
    }
    quotauntil: number; // UNIX epoch time
    get quotauntil_yymmdd(){
        if (this.quotakb<=CookieKey.quotakb_free) return "--";
        else return Util.yymmdd(this.quotauntil);
    }
    usedkb: number;
    get usedkb_str(){
        const str = Util.kbstr(this.usedkb);
        const r = this.usedkb/this.quotakb*100;
        if (r<80) return str;
        else if (r<100) return "(near full) "+str;
        else return "DISK FULL "+str;
    }
    get usedkb_str_class(){
        const r = this.usedkb/this.quotakb*100;
        if (r<80) return "text-body";
        else if (r<100) return "text-warning";
        else return "text-danger";
    }
}
export class GroupMember{
    checked: boolean; // secondary info
    username: string;
    email: string;
    ismanager: boolean; // secondary info
}
export class Group{
    // checked: boolean;
    groupname: string;
    members: GroupMember[];
    diskplan: DiskPlan;
    constructor(){
        this.members = [];
        this.diskplan = new DiskPlan();
    }
    managercnt():number{
        let cnt = 0;
        for(let i = 0 ; i < this.members.length ; i++){
            if (this.members[i].ismanager) cnt++;
        }
        return cnt;
    }
    ismanager(uname:string):boolean{
        for(let i = 0 ;i < this.members.length ; i++){
            let m = this.members[i];
            if (m.username === uname && m.ismanager) return true;
        }
        return false;
    }
    // we need to make new group in front-end, then this cannot be a constructor.
    static make(dro:GroupDroI):Group{
        const grp = new Group();
        // grp.checked = false;
        grp.groupname = dro.groupname;
        for(let i = 0 ; i < dro.members.length ; i++){
            grp.members.push({
                username: dro.members[i],
                email: dro.emails[i],
                ismanager: dro.managers.includes(dro.members[i]),
                checked: false,
            });
        }
        grp.diskplan = new DiskPlan();
        grp.diskplan.quotakb = dro.diskplan.quotakb;
        grp.diskplan.quotauntil = dro.diskplan.quotauntil;
        grp.diskplan.usedkb = dro.diskplan.usedkb;
        return grp;
    }
}



@Injectable({ providedIn: 'root' })
export class AcctService {
    private curruser_subj: BehaviorSubject<UserDroI>;
    curruser_obse: Observable<UserDroI>;

    constructor(private http: HttpClient) {
        this.curruser_subj = new BehaviorSubject<UserDroI>(JSON.parse(localStorage.getItem('currentUser')));
            // localStorageとはブラウザ上に設けられた大域変数領域。
            // 初めて動いたときにブラウザ上の他のタブの操作によりすでにログイン済みであればlocalStorageにユーザー情報がセットされている。
            // それを取得する。なければnullとなる。
        this.curruser_obse = this.curruser_subj.asObservable();
    }
    public get curruser(): UserDroI {
        return this.curruser_subj.value;
        // LoginComponentやRegisterComponentは、ここを問い合わせて現在ログイン中かどうかを判断する。
    }

    login(email:string, password:string):Observable<void> {
        const dto:LoginDtoI = {email:email, password:password};
        return this.http.post<UserDroI>(`${environment.apiUrl}/account/login`, dto)
            .pipe(map((user:UserDroI) => {
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.curruser_subj.next(user);
                return;
            }));
    }
    /**
     * email is the primary identification mean of a user. then we create user
     * by email address. this method makes the user account, sends 'seccode' to the
     * email address, and lock the account. the lock is released when
     * register_password() is called with valid seccode.
     */
    register_email(email: string):Observable<void>{
        const dto: RegisterEmailDtoI = { email: email};
        return this.http.post<void>(`${environment.apiUrl}/account/email`, dto);
    }
    /**
     * this request registers the password to the user account represented by the email.
     * this request is used in two scenario, finishing the registration process, finishing
     * the password-forgotten-senario.
     * @param email to which the seccode is sent
     * @param seccode should match to the sent password
     * @param password the password to be registered
     */
    register_password(email:string, seccode:string, password:string):Observable<void> {
        const dto: RegisterPasswordDtoI = {email: email, seccode: seccode, password: password};
        return this.http.post<void>(`${environment.apiUrl}/account/password`, dto);
    }
    /**
     * seccode is valid only in 10 minutes after issued. then it can be re-issued when requested.
     */
    issue_seccode(email:string, scenario:string, reissue:boolean):Observable<void>{
        const dto: IssueSeccodeDtoI = {email: email, scenario:scenario, reissue:reissue};
        return this.http.post<void>(`${environment.apiUrl}/account/issueseccode`, dto);
    }
    /**
     * caller logic may call this method only if user is logged in with valid email address.
     */
    change_email(oldemail: string, newemail: string):Observable<void>{
        if (!this.curruser) return throwError(new Error("bug: logged-in user only"));
        const dto: ChangeEmailDtoI = {oldemail: oldemail, newemail: newemail};
        return this.http.put<void>(`${environment.apiUrl}/account/email`, dto);
    }
    change_password(email: string, oldpassword: string, newpassword: string):Observable<void>{
        if (!this.curruser) return throwError(new Error("bug: logged-in user only"));
        const dto: ChangePasswordDtoI = {email: email, oldpassword: oldpassword, newpassword: newpassword};
        return this.http.put<void>(`${environment.apiUrl}/account/password`, dto);
    }
    logout():void {
        // remove user from local storage and set current user to null
        localStorage.removeItem('currentUser');
        this.curruser_subj.next(null);
    }
    /**
     * this method turns on 'deleted' flag in the mongodb, which works fine within
     * the above account service methods.
     * actual deletion will be performed manually by system manager
     * with proper house keeping on data deletion and linux login account deletion.
     */
    delete_user():Observable<void>{
        if (!this.curruser) return throwError(new Error("bug: logged-in user only"));
        return this.http.delete<void>(`${environment.apiUrl}/account/user`);
    }
    change_username(email:string, olduname:string, newuname:string):Observable<void>{
        if (!this.curruser) return throwError(new Error("bug: logged-in user only"));
        const dto: ChangeUnameDtoI = {email: email, olduname: olduname, newuname: newuname};
        return this.http.put<UserDroI>(`${environment.apiUrl}/account/username`,dto)
            .pipe(map((user:UserDroI) => {
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.curruser_subj.next(user);
                return;
            }));
    }
    change_pref(dto:ChangePrefDtoI):Observable<void>{
        if (!this.curruser) return throwError(new Error("bug: logged-in user only"));
        return this.http.put<UserDroI>(`${environment.apiUrl}/account/preference`,dto)
            .pipe(map((user:UserDroI) =>{
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.curruser_subj.next(user);
                return;
            }));
    }
    get_groups():Observable<Group[]>{
        if (!this.curruser) return throwError(new Error("bug: logged-in user only"));
        // group info is modified and added in front-end, so we convert the dro to local data.
        return this.http.get<GroupDroI[]>(`${environment.apiUrl}/account/groups`)
            .pipe(map((dros:GroupDroI[])=>{
                const groups:Group[] = dros.map<Group>((dro:GroupDroI)=>{return Group.make(dro);});
                return groups;
            }));
    }
    add_group(groupname:string, manager:string):Observable<Group>{
        if (!this.curruser) return throwError(new Error("bug: logged-in user only"));
        const dto:AddGroupDtoI = {groupname:groupname, manager:manager};
        return this.http.post<GroupDroI>(`${environment.apiUrl}/account/group`,dto)
            .pipe(map((dro:GroupDroI)=>{
                return  Group.make(dro);
            }));
    }
    change_groupdiskplan(info:string, signature:string):Observable<Group>{
        if (!this.curruser) return throwError(new Error("bug: logged-in user only"));
        const dto:ChangeQuotaDtoI = {info:info, signature:signature};
        return this.http.put<GroupDroI>(`${environment.apiUrl}/account/groupdiskplan`,dto)
            .pipe(map((dro:GroupDroI)=>{
                return  Group.make(dro);
            }));
    }
    add_groupmember(groupname:string, email:string):Observable<void>{
        if (!this.curruser) return throwError(new Error("bug: logged-in user only"));
        const dto:AddGroupMemberDtoI = {groupname:groupname, email:email};
        return this.http.put<void>(`${environment.apiUrl}/account/groupmember`,dto);
    }
    remove_groupmember(groupname:string, unames:string[]):Observable<void>{
        if (!this.curruser) return throwError(new Error("bug: logged-in user only"));
        const dto:RemoveGroupMemberDtoI = {groupname:groupname, unames:unames};
        return this.http.delete<void>(`${environment.apiUrl}/account/groupmember`,
        {
            params: {
                dto: JSON.stringify(dto)
            }
        });
    }
    assign_groupmanager(groupname:string, unames:string[]):Observable<void>{
        if (!this.curruser) return throwError(new Error("bug: logged-in user only"));
        const dto:AssignUnassignGroupManagerDtoI = {groupname:groupname, unames:unames};
        return this.http.put<void>(`${environment.apiUrl}/account/groupmanager`,dto);
    }
    unassign_groupmanager(groupname:string, unames:string[]):Observable<void>{
        if (!this.curruser) return throwError(new Error("bug: logged-in user only"));
        const dto:AssignUnassignGroupManagerDtoI = {groupname:groupname, unames:unames};
        return this.http.delete<void>(`${environment.apiUrl}/account/groupmanager`,
        {
            params: {
                dto: JSON.stringify(dto)
            }
        });
    }
    rename_group(oldgroupname:string, newgroupname:string):Observable<void>{
        if (!this.curruser) return throwError(new Error("bug: logged-in user only"));
        const dto:RenameGroupDtoI = {oldgroupname:oldgroupname, newgroupname:newgroupname};
        return this.http.put<void>(`${environment.apiUrl}/account/grouprename`,dto);
    }
    quota_purchase_inquiry(isnewgroup:boolean, groupname:string, diskplan:string, diskperiod:string):Observable<void>{
        if (!this.curruser) return throwError(new Error("bug: logged-in user only"));
        const dto:QuotaPurchaseInquiryDtoI = {isnewgroup:isnewgroup, groupname:groupname, diskplan:diskplan, diskperiod:diskperiod};
        return this.http.put<void>(`${environment.apiUrl}/account/quotapurchaseinquiry`,dto);
    }
    lic_purchase_inquiry(licensee:string, product:string, feature:string, periodstr:string, liccnt:number):Observable<void>{
        if (!this.curruser) return throwError(new Error("bug: logged-in user only"));
        const dto:LicPurchaseInquiryDtoI = {licensee:licensee, product:product, feature:feature, periodstr:periodstr, liccnt:liccnt};
        return this.http.put<void>(`${environment.apiUrl}/account/licpurchaseinquiry`,dto);
    }

}