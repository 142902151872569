import { UserDroI } from 'gemlib/dist_fe/dtodro';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@app/helper/alert.service';
import { matchvalidator } from '@app/helper/matchvalidator';
import { AcctService } from '@app/acctservice/acct.service';

@Component({
    templateUrl: 'chgemail.component.html',
    styleUrls:['chgemail.component.css']
})
export class ChangeEmailComponent implements OnInit {
    form: FormGroup;
    userDro: UserDroI;
    loading = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private acctService: AcctService,
        private alertService: AlertService
    ){
        this.userDro = acctService.curruser;
    }
    ngOnInit() {
        this.form = this.formBuilder.group({
            oldemail: [this.userDro.email],
            newemail: ['', [Validators.required, Validators.email]],
            newemail2: ['', Validators.required],
        },{
            validators: matchvalidator('newemail','newemail2','nomatch')
        });
    }
    fieldError(field:string):string{
        if (!this.form.controls[field].errors) return '';
        else if (this.form.controls[field].errors.required) return 'required';
        else return 'doesn\'t seem like an email';
    }
    crossFieldError(errname:string):string{
        if (!this.form.errors) return '';
        if (!this.form.errors[errname]) return '';
        if (errname==='nomatch') return 'matching error';
        else return '(bug) unknown errname : '+errname;
    }
    onSubmit() {
        this.form.markAllAsTouched();
        this.alertService.clear();
        if (this.form.invalid) {
            return;
        }
        this.loading = true;
        this.acctService.change_email(this.form.controls.oldemail.value,
            this.form.controls.newemail.value)
            .subscribe(
                () => {
                    this.alertService.success("Your e-mail address is changed. please log-in.",true);
                    this.loading = false;
                    this.acctService.logout();
                    this.router.navigate(['/login'],{ queryParams: { email: this.form.controls.newemail.value }});
                },
                (error:HttpErrorResponse|Error) => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
}
