<header2 [file-path-str]="filepath_str"></header2>
<div class="page-body">
    <div class="page-container-medium">
		<!-- command bar -->
		<mat-toolbar class="command-toolbar mat-elevation-z8">
            <button mat-stroked-button disableRipple>
				<mat-icon>star</mat-icon>
				LPB-Format Checker
			</button>
			<span class="spacer"></span>
			<mat-button-toggle-group multiple>
				<mat-button-toggle [checked]="see_error" (change)="see_error=!see_error;" matTooltip="show error message">
					<mat-icon color="warn">error</mat-icon>
					Error
				</mat-button-toggle>
				<mat-button-toggle [checked]="see_warning" (change)="see_warning=!see_warning;"  matTooltip="show warning message">
					<mat-icon color="accent">warning</mat-icon>
					Warning
				</mat-button-toggle>
				<mat-button-toggle [checked]="see_info" (change)="see_info=!see_info;"  matTooltip="show log message">
					<mat-icon color="primary">info</mat-icon>
					Info
				</mat-button-toggle>
				<mat-button-toggle [checked]="see_source" (change)="see_source=!see_source;" matTooltip="show source lines">
					<mat-icon>code</mat-icon>
				</mat-button-toggle>
				<mat-button-toggle [checked]="see_verbose" (change)="see_verbose=!see_verbose;" matTooltip="show verbose message">
					<mat-icon>message</mat-icon>
				</mat-button-toggle>
				<mat-button-toggle [checked]="see_debug" (change)="see_debug=!see_debug;" matTooltip="show debug info">
					<mat-icon>bug_report</mat-icon>
				</mat-button-toggle>
			</mat-button-toggle-group>
		</mat-toolbar>
		<div style="justify-content:left;">
			<mat-card>
				<mat-card-title>
					Summary Report
				</mat-card-title>
				<mat-card-content>
					<table class="summary-table">
						<tr>
							<td>Folder</td>
							<td>{{dirpath}}</td>
						</tr>
						<tr>
							<td>Checked Files</td>
							<td>{{filenames_str}}</td>
						</tr>
						<tr>
							<td>Date</td>
							<td>{{currdate}}</td>
						</tr>
						<tr>
							<td>Tool</td>
							<td>gem-lpb LPB-Format Checker</td>
						</tr>
						<tr>
							<td>Checked Result</td>
							<td>
								<span class="{{total_error_cnt>0?'error-color':undefined}}">
									{{total_error_cnt}} errors,
								</span>
								<span class="{{total_warning_cnt>0?'warning-color':undefined}}">
									{{total_warning_cnt}} warnings
								</span>
							</td>
						</tr>
					</table>
				</mat-card-content>
			</mat-card>
			<div *ngFor="let filelog of filelogs; let i = index;">
				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{filelog.fname}}
						</mat-panel-title>
						<mat-panel-description>
							<span class="{{filelog.error_cnt>0?'error-color':undefined}}">
								{{filelog.error_cnt}} errors,&nbsp;
							</span>
							<span class="{{filelog.warning_cnt>0?'warning-color':undefined}}">
								{{filelog.warning_cnt}} warnings.
							</span>
						</mat-panel-description>
					</mat-expansion-panel-header>
					<div *ngFor="let log of filelog.logs">
						<div *ngIf="see_error && log.type=='error'" class="error-color">
							<mat-icon>error</mat-icon>
							{{log.message}}
						</div>
						<div *ngIf="see_warning && log.type=='warning'" class="warning-color">
							<mat-icon>warning</mat-icon>
							{{log.message}}
						</div>
						<div *ngIf="see_info && log.type=='info'" class="info-color">
							<mat-icon>info</mat-icon>
							{{log.message}}
						</div>
						<div *ngIf="see_source && log.type=='source'">
							{{log.message}}
						</div>
						<div *ngIf="see_verbose && log.type=='verbose'" class="disabled-color">
							{{log.message}}
						</div>
						<div *ngIf="see_debug && log.type=='debug'">
							{{log.message}}
						</div>
					</div>
				</mat-expansion-panel>
			</div>
		</div>
	</div>
</div>
<div class="footer">
	<a class="footer" href="https://gemdt.com" target="_top">Gem Design Technologies, Inc.</a>
</div>
