<header1></header1>
<div class="page-body">
    <div class="page-container-small">
        <mat-grid-list cols="1">
            <mat-card>
                <mat-card-title class="mat-title" color="warn">
                    Deleting Account {{userDro.email}}
                </mat-card-title>
                <mat-card-content>
                    <h3>
                        Warning: Delete all of your data before deleting the account.
                        If you leave your data, you shall be charged for the diskspace.
                    </h3>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button color="warn" (click)="delete_acct()">
                        DELETE ACCOUNT
                    </button>
                </mat-card-actions>
            </mat-card>
        </mat-grid-list>
    </div>
</div>
<div class="footer">
	<a class="footer" href="https://gemdt.com" target="_top">Gem Design Technologies, Inc.</a>
</div>
