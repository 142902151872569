import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@app/helper/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AcctService } from '@app/acctservice/acct.service';

@Component({
    styleUrls: ['forgotpw1.component.css'],
    templateUrl: 'forgotpw1.component.html'
})
export class Forgotpw1Component implements OnInit {
    form: FormGroup;
    loading = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private acctService: AcctService,
        private alertService: AlertService,
        private route: ActivatedRoute
    ) {
        if (this.acctService.curruser) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            email: [this.route.snapshot.queryParams['email']||'',
                [Validators.required, Validators.email]]
        });
    }
    fieldError(field:string):string{
        if (!this.form.controls.email.errors) return '';
        if (this.form.controls.email.errors.required) return 'required';
        else return 'does\'t seem like an email address';
    }

    ngOnSubmit() {
        this.form.markAllAsTouched();
        this.alertService.clear();
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.acctService.issue_seccode(this.form.controls.email.value, "forgotpassword", false)
            .subscribe(
                () => {
                    this.alertService.success('Security code is just sent to '+this.form.controls.email.value,true);
                    this.router.navigate(['/forgotpw2'],{ queryParams: { email: this.form.controls.email.value }});
                },
                (error:HttpErrorResponse|Error) => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
}
