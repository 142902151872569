import {FileLogDroI} from './dtodro';
import {GemLog} from './gemutil';
import {GemPoint, GemPointI, GemEuclid, GemBbox, GemShapeI, GemShape, GemPoly, GemMoveTo, GemText, GemTextI} from './gemshape';
/**
 * interfaces for serialize/deserialize database.
 */
export interface MaterialI{
	lineno:number;
  classname:string;
  name:string;
}
export interface DieleI extends MaterialI{
  permittivity:number;
  permeability:number;
  losstangent:number;
}
export interface CondI extends MaterialI{
	cond_ohm_mm_inv:number;
}

export interface WireI{
	lineno:number;
	lay1:number; // -1 if connected to a die
	lay2:number; // -1 if connected to a die
	bwprof:string; // '0' means omitted.
	p1:GemPointI;
	p2:GemPointI;
	die1name:string; // suggest die of diepad at p1, when lay1<0.
  die2name:string; // suggest die of diepad at p2, when lay2<0.
}
export interface PadI{
	lineno:number;
	layer:number;
	shape:GemShapeI; // optional
	antipad_shape:GemShapeI;
}
export interface PadstackI{
	lineno:number;
  id:string;
  pads:PadI[];
}
export interface PinI{
	lineno:number;
  pinname:string;
  p:GemPointI;
  iotype:string; // 'D' driver pin, 'R' receiver pin, 'B' bi-directional pin, 'DT' driver terminator, 'RT' receiver terminator
  padstk:PadstackI;
}
export interface CompI{
	lineno:number;
  partname:string;
	rcd:string; // top view shape is 'R' rectangle, 'C' circle, or 'D' round-corner diamond
	shape:GemShapeI; // part outline shape.
	height:number;
	parttype:string; // 'R' resistor, 'L' inductor, 'C' capacitor, 'S' solder ball, 'D' die, 'M' molding compound, 'O' other
	noflip:boolean; // don't flip the part when placed below a layer.
	partvalue:string; // mohms for 'R', nH for 'L', pF for 'C', or '( mohms nH pF)'.
	material:string; // (optional)
	pins:PinI[];
	// comp only info
	uname:string; // instance name
	origin:GemPointI; // placed coord.
	zoffset:number;
	layer:number;  // +1:above layer 1, -1:below layer 1.
	rotdegree:number; // rotation angle.
	flipped:boolean;  // flipped
	stack_compname: string; // (optional) on which this comp is stacked.
	compvalue:string;  // (optional) '(50 10 250 0) --> 50 mohms, 10 nH, 250 pF, rfu.'
}
export interface LayerI{
	lineno:number;
	name:string;
	type:string; // 'S'/'D'/'P' for signal/dielectric/power or ground
	thickness:number;
	condmat:string;
	dielemat1:string;
	dielemat2:string;
	dielemat3:string;
}
export interface PropI{
	lineno:number;
	key:string;
	value:string;
}
export interface PropGroupI{
	lineno:number;
	id:string;
	props:PropI[];
}
export interface ViaI{
	lineno:number;
	vianame:string;
	psk:PadstackI;
	barrel:GemShapeI;
	barrel_thickness:number;
	// instance info
	lay1:number;
	lay2:number;
	origin:GemPointI;
	rotdegree:number;
	flipped:boolean;
}
export interface PinrefI{
	lineno:number;
	uname:string;
	pinname:string;
}
export interface NetI{
	lineno:number;
	name:string;
	nettype:string; // G, S, P
	pinrefs:PinrefI[];
	propgroup:PropGroupI;
	vias:ViaI[];
	wires:WireI[];
	shapes:GemShapeI[];
}
export interface ComTextI{
	lineno:number;
	layer:number;
	text:GemTextI;
}
export interface GfmtI{
	dirpath:string;
	fname:string;
	iserror: boolean;
	materials:MaterialI[];
	layers:LayerI[];
	shapes:GemShapeI[];
	boardshapes:GemShapeI[];
	padstacks:PadstackI[];
	parts:CompI[];
	comps:CompI[];
	propgroups:PropGroupI[];
	vialist:ViaI[];
	nets:NetI[];
	comtexts:ComTextI[];
	partial:boolean;
}
/**
 * actual database
 */
export abstract class Material implements MaterialI{
	lineno:number;
	par:Gfmt;
	name:string;
	setparR(par:Gfmt){this.par = par;}
	get classname(){return 'Material';}
	constructor(par:Gfmt,lineno:number){
		this.par = par;
		this.lineno = lineno;
	}
	fromI(par:Gfmt, ref:MaterialI):Material{
		this.par = par;
		this.lineno = ref.lineno;
		this.name = ref.name;
		return this;
	}
	toI():MaterialI{
		return {
			lineno:this.lineno,
			classname: this.classname,
			name: this.name
		}
	};
	static mkFromI(par:Gfmt, ref:MaterialI){
		let material:Material = null;
		switch(ref.classname){
		case 'Diele':
			material = new Diele(par,0).fromI(par,<DieleI>ref);
			break;
		case 'Cond':
			material = new Cond(par,0).fromI(par,<CondI>ref);
			break;
		default:
			throw new Error('Material.mkFromI(): ref.classname='+ref.classname+', expected={Diele,Cond}');
		}
		return material;
	}
}
export class Diele extends Material implements DieleI{
	permittivity:number;
	permeability:number;
	losstangent:number;
	get classname(){return 'Diele';}
	static fromJSON(par:Gfmt, json:string):Diele{
		return new Diele(null,0).fromI(par,<DieleI>JSON.parse(json));
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(par:Gfmt, ref:DieleI):Diele{
		super.fromI(par, ref);
		this.permittivity = ref.permittivity;
		this.permeability = ref.permeability;
		this.losstangent = ref.losstangent;
		return this;
	}
	toI():DieleI{
		return {
			...super.toI(),
			classname: this.classname,
			permittivity: this.permittivity,
			permeability: this.permeability,
			losstangent: this.losstangent
		}
	}
}
export class Cond extends Material implements CondI{
	cond_ohm_mm_inv:number;
	get classname(){return 'Cond';}
	static fromJSON(par:Gfmt, json:string):Cond{
		return new Cond(null,0).fromI(par,<CondI>JSON.parse(json));
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(par:Gfmt, ref:CondI):Cond{
		super.fromI(par,ref);
		this.cond_ohm_mm_inv = ref.cond_ohm_mm_inv;
		return this;
	}
	toI():CondI{
		return {
			...super.toI(),
			classname: this.classname,
			cond_ohm_mm_inv: this.cond_ohm_mm_inv
		}
	}
}
export class Layer implements LayerI{
	lineno:number;
	par:Gfmt;
	name:string='L1'; // need default to conveniently make 1 that setting when .that is omitted.
	thickness:number=0;
	type:string='S'; // 'S'/'D'/'P' for signal/dielectric/power or ground
	condmat:string='';
	dielemat1:string='';
	dielemat2:string = '';
	dielemat3:string = '';
	// non-if
	visible:boolean = false;
	setparR(par:Gfmt){this.par = par;}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	static fromJSON(par:Gfmt, json:string):Layer{
		return new Layer(par,0).fromI(par,<LayerI>JSON.parse(json));
	}
	fromI(par:Gfmt, ref:LayerI):Layer{
		this.lineno = ref.lineno;
		this.par = par;
		this.name = ref.name;
		this.thickness = ref.thickness;
		this.type = ref.type;
		this.condmat = ref.condmat;
		this.dielemat1 = ref.dielemat1;
		this.dielemat2 = ref.dielemat2;
		this.dielemat3 = ref.dielemat3;
		return this;
	}
	toI():LayerI{
		return {
			lineno:this.lineno,
			name:this.name,
			thickness:this.thickness,
			type:this.type,
			condmat:this.condmat,
			dielemat1:this.dielemat1,
			dielemat2:this.dielemat2,
			dielemat3:this.dielemat3
		}
	}
	constructor(par:Gfmt, lineno:number){
		this.par = par;
		this.lineno = lineno;
	}
}
export class Pad implements PadI{
	lineno:number = 0;
	par:Padstack = null;
	layer:number = 0;
	shape:GemShape = null;
	antipad_shape:GemShape = null; // optional
	//
	private bb:GemBbox = null;
	selected:boolean = false;
	center():GemPoint{
		return this.bbox().center();
	}
	setparR(par:Padstack){this.par = par;}
	select(state:boolean):void{
		this.selected = state;
		this.shape.select(state);
		if (this.antipad_shape) this.antipad_shape.select(state);
	}

	static fromJSON(par:Padstack,ref:string):Pad{
		return new Pad(0,par).fromI(par,<PadI>JSON.parse(ref));
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(par:Padstack,ref:PadI):Pad{
		this.lineno = ref.lineno;
		this.layer = ref.layer;
		this.shape = GemShape.mkFromI(this,ref.shape);
		if (ref.antipad_shape){
			this.antipad_shape = GemShape.mkFromI(this,ref.antipad_shape);
			this.antipad_shape.par = this;
		}else{
			this.antipad_shape = null;
		}
		return this;
	}
	toI(){
		return {
			lineno: this.lineno,
			layer: this.layer,
			shape: this.shape.toI(),
			antipad_shape: this.antipad_shape?this.antipad_shape.toI():null
		}
	}
	constructor(lineno:number,par:Padstack){
		this.lineno = lineno;
		this.par = par;
	}
	bbox():GemBbox{
		if (!this.bb){
			this.bb = new GemBbox;
			this.bb.add(this.shape.bbox());
			// if (this.antipad_shape) this.bb.add(this.antipad_shape.bbox());
			// (note) including anti-pad shape in bbox would be inappropriate.
		}
		return this.bb;
	}
	copy(that:Pad):Pad{
		this.lineno = that.lineno;
		this.layer = that.layer;
		this.shape = that.shape.mkcopy();
		this.antipad_shape = null;
		if (that.antipad_shape) this.antipad_shape = that.antipad_shape.mkcopy();
			// (js note) the condition is false if the object is null or undefined.
		return this;
	}
	mkcopy(par:Padstack):Pad{
		return new Pad(this.lineno,this.par).copy(this);
	}
	rotate(degree:number, rotcen:GemPoint):Pad{
		this.shape.rotate(degree,rotcen);
		if (this.antipad_shape) this.antipad_shape.rotate(degree,rotcen);
		return this;
	}
	move(dx:number, dy:number):Pad{
		this.shape.move(dx,dy);
		if (this.antipad_shape) this.antipad_shape.move(dx,dy);
		return this;
	}
	flip(x0:number):Pad{
		this.shape.flip(x0);
		if (this.antipad_shape) this.antipad_shape.flip(x0);
		return this;
	}
}

export class Padstack implements PadstackI{
	lineno:number;
	par:Pin|Via|Gfmt = null; // parent is pin or via.
	id:string;
	pads:Pad[] = [];
	// non-if
	bb:GemBbox = null;
	selected:boolean = false;
	private static _pwk = new GemPoint(0,0);
	highestlayer():number{
		if (this.pads.length<=0) return 0;
		let layer = Number.MAX_SAFE_INTEGER;
		for(let pad of this.pads){
			layer = Math.min(layer,pad.layer);
		}
		return layer;
	}
	samepads(pads:Pad[]):boolean{
		if (pads.length!=this.pads.length) return false;
		const p = Padstack._pwk;
		p.copy(pads[0].center().minus(this.pads[0].center()));
		for(let i = 0 ; i < pads.length ; i++){
			const pad1 = pads[i];
			const pad2 = this.pads[i];
			if (pad1.layer != pad2.layer) return false;
			if (!GemShape.sameshape(pad1.shape,pad2.shape)) return false;
			if (!p.equals(pad1.center().minus(pad2.center()))) return false;
		}
		return true;
	}
	setparR(par:Pin|Via|Gfmt){
		this.par = par;
		this.pads.forEach((m)=>{m.setparR(this);})
	}
	select(state:boolean):void{
		this.selected = state;
		for(let pad of this.pads) pad.select(state);
	}
	static fromJSON(par:Pin|Via|Gfmt,ref:string):Padstack{
		return new Padstack(0,par).fromI(par,<PadstackI>JSON.parse(ref));
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(par:Pin|Via|Gfmt,ref:PadstackI):Padstack{
		this.par = par;
		this.lineno = ref.lineno;
		this.id = ref.id;
		this.pads = ref.pads.map((m)=>{
			return new Pad(m.lineno,this).fromI(this,m);
		});
		// par will be set in Pin/Via/GFile.fromI()
		return this;
	}
	toI():PadstackI{
		return {
			lineno: this.lineno,
			id: this.id,
			pads: this.pads.map((m)=>{
				return m.toI();
			})
		}
	}
	gfile():Gfmt{
		if (this.par instanceof Pin){
			return this.par.par.par;
		}else if (this.par instanceof Via){
			return this.par.par;
		}else{
			return this.par;
		}
	}
	constructor(lineno:number,par:Pin|Via|Gfmt){
		this.lineno = lineno;
		this.par = par;
	}
	bbox():GemBbox{
		if (!this.bb){
			this.bb = new GemBbox;
			for(var i = 0 ; i < this.pads.length ; i++){
				this.bb.add(this.pads[i].bbox());
			}
		}
		return this.bb;
	}
	copy(that:Padstack):Padstack{
		this.lineno = that.lineno;
		this.id = that.id;
		this.pads = [];
		for(var i = 0 ; i < that.pads.length ; i++){
			this.pads.push(that.pads[i].mkcopy(this));
		}
		return this;
	}
	mkcopy(lineno:number, par:Via|Pin|Gfmt):Padstack{
		const that = new Padstack(lineno,par);
		that.copy(this);
		that.lineno = lineno;
		that.par = par;
		return that;
	}
	rotate(degree:number, rotcen:GemPoint):Padstack{
		for(var i = 0 ; i < this.pads.length ; i++){
			this.pads[i].rotate(degree,rotcen);
		}
		return this;
	}
	move(dx:number, dy:number):Padstack{
		for(var i = 0 ; i < this.pads.length ; i++){
			this.pads[i].move(dx,dy);
		}
		return this;
	}
	flip(x0:number):Padstack{
		for(var i = 0 ; i < this.pads.length ; i++){
			this.pads[i].flip(x0);
		}
		return this;
	}
}

export class Via implements ViaI{
	lineno:number;
	par:Gfmt;
	vianame:string;
	psk:Padstack;
	barrel:GemShape = null;
	barrel_thickness:number = 0;
	// instance info
	lay1:number = 0;
	lay2:number = 0;
	origin:GemPoint = new GemPoint(0,0);
	rotdegree:number = 0;
	flipped:boolean = false;
	net:Net = null; // set in importing route section
	//
	selected:boolean = false; // origin will be highlighted
	bb:GemBbox = null;
	setparR(par:Gfmt){this.par = par;};
	select(state:boolean):void{
		this.selected = state;
		this.psk.select(state);
		if (this.barrel) this.barrel.select(state);
	}
	constructor(par:Gfmt, lineno:number){
		this.par = par;
		this.lineno = lineno;
	}
	static fromJSON(par:Gfmt,ref:string):Via{
		return new Via(par,0).fromI(par,<ViaI>JSON.parse(ref));
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(par:Gfmt,ref:ViaI):Via{
		this.lineno = ref.lineno;
		this.vianame = ref.vianame;
		this.psk = new Padstack(ref.lineno,this).fromI(this,ref.psk);
		if (ref.barrel){
			this.barrel = GemShape.mkFromI(this,ref.barrel);
		}else{
			this.barrel = null;
		}
		this.barrel_thickness = ref.barrel_thickness;
		this.lay1 = ref.lay1;
		this.lay2 = ref.lay2;
		this.origin.copy(ref.origin);
		this.rotdegree = ref.rotdegree;
		this.flipped = ref.flipped;
		return this;
	}
	toI():ViaI{
		return {
			lineno: this.lineno,
			vianame: this.vianame,
			psk: this.psk.toI(),
			barrel: this.barrel?this.barrel.toI():null,
			barrel_thickness: this.barrel_thickness,
			lay1: this.lay1,
			lay2: this.lay2,
			origin: this.origin.toI(),
			rotdegree: this.rotdegree,
			flipped: this.flipped
		}
	}
	bbox():GemBbox{
		if (!this.bb){
			this.bb = new GemBbox;
			this.bb.addPoint(this.origin);
			this.bb.add(this.psk.bbox());
			if (this.barrel) this.bb.add(this.barrel.bbox());
		}
		return this.bb;
	}
	copy(that:Via):Via{
		this.lineno = that.lineno;
		this.vianame = that.vianame;
		this.psk = that.psk.mkcopy(that.psk.lineno,this);
		this.barrel = null;
		if (that.barrel) this.barrel = that.barrel.mkcopy();
		this.barrel_thickness = that.barrel_thickness;
		this.lay1 = that.lay1;
		this.lay2 = that.lay2;
		this.origin.copy(that.origin);
		this.rotdegree = that.rotdegree;
		this.flipped = that.flipped;
		return this;
	}
	mkcopy():Via{
		return new Via(this.par,this.lineno).copy(this);
	}
	rotate(degree:number, rotcen:GemPoint):Via{
		this.psk.rotate(degree,rotcen);
		if (this.barrel) this.barrel.rotate(degree,rotcen);
		this.origin.rotate(degree,rotcen);
		return this;
	}
	move(dx:number, dy:number):Via{
		this.psk.move(dx,dy);
		if (this.barrel) this.barrel.move(dx,dy);
		this.origin.move(dx,dy);
		return this;
	}
	flip(x0:number):Via{
		this.psk.flip(x0);
		if (this.barrel) this.barrel.flip(x0);
		this.origin.flip(x0);
		this.flipped = !this.flipped;
		this.rotdegree = GemEuclid.degreeModulo(-this.rotdegree); // see comments in Comp
		return this;
	}
}
export class Pin implements PinI{
	lineno:number;
	par:Comp = null; // the comp it belongs. set in parsing part, then changed in parsing component.
	pinname:string;
	readonly p:GemPoint = new GemPoint(0,0);
	iotype:string = 'B'; // 'D' driver pin, 'R' receiver pin, 'B' bi-directional pin, 'DT' driver terminator, 'RT' receiver terminator
	padstk:Padstack = null; // optional
	// non-if items
	net:Net = null; // set when importing netlist.
	bb:GemBbox = null;
	selected:boolean = false;
	/**
	 * returns the highest layer, or the component placement layer if no padstack available.
	 */
	highestlayer():number{
		if (this.padstk) return this.padstk.highestlayer();
		else return this.par.placed_layer();
	}
	setparR(par:Comp){
		this.par = par;
		if (this.padstk) this.padstk.setparR(this);
	}
	select(state:boolean){
		this.selected = state;
		if (this.padstk) this.padstk.select(state);
	}
	static fromJSON(ref:string):Pin{
		return new Pin(0,null).fromI(<PinI>JSON.parse(ref));
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(ref:PinI):Pin{
		this.lineno = ref.lineno;
		this.pinname = ref.pinname;
		this.p.copy(ref.p);
		this.iotype = ref.iotype;
		this.padstk = ref.padstk? new Padstack(ref.padstk.lineno,this).fromI(this,ref.padstk):null;
		return this;
	}
	toI():PinI{
		return {
			lineno: this.lineno,
			pinname: this.pinname,
			p: this.p.toI(),
			iotype: this.iotype,
			padstk: this.padstk?this.padstk.toI():null
		}
	}
	constructor(lineno:number,par:Comp){
		this.lineno = lineno;
		this.par = par;
	}
	bbox():GemBbox{
		if (!this.bb){
			this.bb = new GemBbox;
			this.bb.addPoint(this.p);
			if (this.padstk) this.bb.add(this.padstk.bbox());
		}
		return this.bb;
	}
	copy(that:Pin):Pin{
		this.lineno = that.lineno;
		this.pinname = that.pinname;
		this.p.copy(that.p);
		this.iotype = that.iotype;
		this.padstk = null;
		if (that.padstk){
			this.padstk = that.padstk.mkcopy(that.lineno,this);
		}
		return this;
	}
	mkcopy(par:Comp):Pin{
	    return new Pin(this.lineno,par).copy(this);
	}
	rotate(degree:number, rotcen:GemPoint):Pin{
		this.p.rotate(degree,rotcen);
		if (this.padstk) this.padstk.rotate(degree,rotcen);
		return this;
	}
	move(dx:number, dy:number):Pin{
		this.p.move(dx,dy);
		if (this.padstk) this.padstk.move(dx,dy);
		return this;
	}
	flip(x0:number):Pin{
		this.p.flip(x0);
		if (this.padstk) this.padstk.flip(x0);
		return this;
	}
	dotname():string{
		if (this.par) return this.par.uname+'.'+this.pinname;
		else return '?.'+this.pinname;
	}
}
export class Comp implements CompI{
	lineno:number;
	par:Gfmt;
	// part info
	partname:string;
	rcd:string = 'R'; // top view shape is 'R' rectangle, 'C' circle, or 'D' round-corner diamond
	shape:GemShape; // part shape
	height:number = 0;
	parttype:string = 'O'; // 'R' resistor, 'L' inductor, 'C' capacitor, 'S' solder ball, 'D' die, 'M' molding compound, 'O' other
	noflip:boolean = false; // donut flip the part when placed below a layer.
	partvalue:string = ''; // mohms for 'R', nH for 'L', pF for 'C', or '( mohms nH pF)'.
	material:string = ''; // (optional)
	pins:Pin[] = [];
	// comp info
	uname:string = '';
	origin:GemPoint = new GemPoint(0,0); // placed coord.
	zoffset:number = 0;
	layer:number = 1; // +1:above layer 1, -1:below layer 1.
	rotdegree:number = 0; // rotation angle.
	flipped:boolean = false; // flipped
	stack_compname:string = ''; // (optional) on which this comp is stacked.
	compvalue:string = ''; // (optional) '(50 10 250 0) --> 50 mohms, 10 nH, 250 pF, rfu.'
	// not-in-if
	bb:GemBbox = null;
	setparR(par:Gfmt){
		this.par = par;
		this.shape.par = this;
		this.pins.forEach((m)=>{m.setparR(this);})
	}
	select(state:boolean):void{
		this.shape.select(state);
		for(let pin of this.pins) pin.select(state);
	}
	static fromJSON(par:Gfmt, ref:string):Comp{
		return new Comp(0,par).fromI(par,<CompI>JSON.parse(ref));
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(par:Gfmt, ref:CompI):Comp{
		this.par = par;
		this.lineno = ref.lineno;
		this.partname = ref.partname;
		this.rcd = ref.rcd;
		this.shape = GemShape.mkFromI(this,ref.shape);
		this.height = ref.height;
		this.parttype = ref.parttype;
		this.noflip = ref.noflip;
		this.partvalue = ref.partvalue;
		this.material = ref.material;
		this.pins = ref.pins.map((m)=>{
			return new Pin(m.lineno,this).fromI(m);
		});
		this.uname = ref.uname;
		this.origin.copy(ref.origin);
		this.zoffset = ref.zoffset;
		this.layer = ref.layer;
		this.rotdegree = ref.rotdegree;
		this.flipped = ref.flipped;
		this.stack_compname = ref.stack_compname;
		this.compvalue = ref.compvalue;
		return this;
	}
	toI():CompI{
		return {
			lineno: this.lineno,
			partname: this.partname,
			rcd: this.rcd,
			shape: this.shape.toI(),
			height: this.height,
			parttype: this.parttype,
			noflip: this.noflip,
			partvalue: this.partvalue,
			material: this.material,
			pins: this.pins.map((m)=>{
				return m.toI();
			}),
			uname: this.uname,
			origin: this.origin.toI(),
			zoffset: this.zoffset,
			layer: this.layer,
			rotdegree: this.rotdegree,
			flipped: this.flipped,
			stack_compname: this.stack_compname,
			compvalue: this.compvalue
		}
	}
	constructor(lineno:number, par:Gfmt){
		this.lineno = lineno;
		this.par = par;
	}
	placed_layer():number{
		return Math.abs(this.layer);
	}
	bbox():GemBbox{
		if (!this.bb){
			this.bb = new GemBbox;
			this.bb.add(this.shape.bbox());
			for(var i = 0 ; i < this.pins.length ; i++){
				this.bb.add(this.pins[i].bbox());
			}
		}
		return this.bb;
	}
	findPinByPinname(pinname:string):Pin{
		for(let pin of this.pins) if (pin.pinname===pinname) return pin;
		return null;
	}
	mustFindPinByPinname(pinname:string, lineno:number):Pin{
		for(var i = 0 ; i < this.pins.length ; i++){
			const pin = this.pins[i];
			if (pin.pinname === pinname) return pin;
		}
		throw new Error('line '+lineno+' : no such pin "'+pinname+'", in comp "'+this.uname+'"');
	}
	copy(that:Comp):Comp{
		this.lineno = that.lineno;
		this.partname = that.partname;
		this.rcd = that.rcd;
		this.shape = that.shape.mkcopy();
		this.height = that.height;
		this.parttype = that.parttype;
		this.noflip = that.noflip;
		this.partvalue = that.partvalue;
		this.material = that.material;
		this.pins = [];
		for(var i = 0 ; i < that.pins.length ; i++){
			const pin = that.pins[i].mkcopy(this);
			this.pins.push(pin);
		}
		this.uname = that.uname;
		this.origin.copy(that.origin);
		this.layer = that.layer;
		this.rotdegree = that.rotdegree;
		this.flipped = that.flipped;
		this.stack_compname = that.stack_compname;
		this.compvalue = that.compvalue;
		return this;
	}
	mkcopy():Comp{
		return new Comp(this.lineno,this.par).copy(this);
	}
	rotate(degree:number, rotcen:GemPoint):Comp{
		this.shape.rotate(degree,rotcen);
		for(var i = 0 ; i < this.pins.length ; i++){
			this.pins[i].rotate(degree,rotcen);
		}
		this.origin.rotate(degree,rotcen);
		this.rotdegree = GemEuclid.degreeModulo(this.rotdegree + degree);
		return this;
	}
	move(dx:number, dy:number):Comp{
		this.shape.move(dx,dy);
		for(var i = 0 ; i < this.pins.length ; i++){
			this.pins[i].move(dx,dy);
		}
		this.origin.move(dx,dy);
		return this;
	}
	flip(x0:number):Comp{
		this.shape.flip(x0);
		for(var i = 0 ; i < this.pins.length ; i++){
			this.pins[i].flip(x0);
		}
		this.origin.flip(x0);

		this.flipped = !this.flipped;
		this.rotdegree = GemEuclid.degreeModulo(-this.rotdegree);
		return this;
	}
}
export class Wire implements WireI{
	lineno:number;
	par:Net;
	lay1:number = -1; // -1 if connected to a die
	lay2:number = -1; // -1 if connected to a die
	bwprof:string = '0'; // zero means omitted.
	p1:GemPoint = new GemPoint(0,0);
	p2:GemPoint = new GemPoint(0,0);
	die1:Comp = null; // suggest die of diepad at p1, when lay1<0.
	die2:Comp = null; // suggest die of diepad at p2, when lay2<0.
	// secondary info
	// cache
	private _shape:GemPoly = null;
	private bb:GemBbox = null;
	selected:boolean = false;
	setparR(par:Net){this.par = par;}
	select(state:boolean):void{
		this.selected = state;
	}
	constructor(lineno:number, par:Net){
		this.lineno = lineno;
		this.par = par;
	}
	static fromJSON(par:Net, ref:string):Wire{
		return new Wire(0,par).fromI(par,<WireI>JSON.parse(ref));
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(par:Net,ref:WireI):Wire{
		this.par = par;
		this.lineno = ref.lineno;
		this.lay1 = ref.lay1;
		this.lay2 = ref.lay2;
		this.bwprof = ref.bwprof;
		this.p1.copy(ref.p1);
		this.p2.copy(ref.p2);
		this.die1 = (ref.die1name)? par.par.mustFindCompByUname(ref.die1name,this.lineno):null;
		this.die2 = (ref.die2name)? par.par.mustFindCompByUname(ref.die2name,this.lineno):null;
		return this;
	}
	toI():WireI{
		return {
			lineno: this.lineno,
			lay1: this.lay1,
			lay2: this.lay2,
			bwprof: this.bwprof,
			p1: this.p1.toI(),
			p2: this.p2.toI(),
			die1name: this.die1name,
			die2name: this.die2name
		}
	}
	get die1name(){
		return (this.die1)? this.die1.uname:'';
	}
	get die2name(){
		return (this.die2)? this.die2.uname:'';
	}
	clearCache():Wire{
		this._shape = null;
		this.bb = null;
		return this;
	}
	get shape():GemShape{
		if (this._shape==null){
			this._shape = new GemPoly(this.lineno,this);
			this._shape.pps.push(new GemMoveTo(this.lineno,this._shape,this.p1));
			this._shape.pps.push(new GemMoveTo(this.lineno,this._shape,this.p2));
		}
		return this._shape;
	}
	bbox():GemBbox{
		if (!this.bb){
			this.bb = new GemBbox;
			this.bb.addPoint(this.p1);
			this.bb.addPoint(this.p2);
		}
		return this.bb;
	}
}
export class Prop implements PropI{
	par:PropGroup;
	lineno:number;
	key:string;
	value:string;
	setparR(par:PropGroup){this.par = par;}
	static fromJSON(par:PropGroup, ref:string):Prop{
		return new Prop(par,0,null,null).fromI(par,<PropI>JSON.parse(ref));
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(par:PropGroup, ref:PropI):Prop{
		this.par = par;
		this.lineno = ref.lineno;
		this.key = ref.key;
		this.value = ref.value;
		return this;
	}
	toI():PropI{
		return {
			lineno:this.lineno,
			key: this.key,
			value: this.value
		}
	}
	bbox():GemBbox{
		throw new Error('Prop.bbox() should not be called.');
	}
	constructor(par:PropGroup, lineno:number,key:string,value:string){
		this.par = par;
		this.lineno = lineno;
		this.key = key;
		this.value = value;
	}
}
export class PropGroup implements PropGroupI{
	static readonly NET_CLASS = 'NET_CLASS'; // reserved key in net prop for net class
	lineno:number;
	par:Gfmt;
	id:string;
	props:Prop[] = [];
	findProp(key:string):string{
		for(let prop of this.props){
			if (prop.key===key) return prop.value;
		}
		return '';
	}
	setparR(par:Gfmt){
		this.par = par;
		this.props.forEach((m)=>{m.setparR(this);})
	}
	static fromJSON(par:Gfmt, ref:string):PropGroup{
		return new PropGroup(0,par,'').fromI(par,<PropGroupI>JSON.parse(ref));
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(par:Gfmt, ref:PropGroupI):PropGroup{
		this.par = par;
		this.lineno = ref.lineno;
		this.id = ref.id;
		this.props = ref.props.map((m)=>{
			return new Prop(this,m.lineno,m.key,m.value).fromI(this,m);
		});
		return this;
	}
	toI():PropGroupI{
		return {
			lineno: this.lineno,
			id: this.id,
			props: this.props.map((m)=>{return m.toI();})
		}
	}
	bbox():GemBbox{
		throw new Error('PropGroup.bbox() should not be called.');
	}
	constructor(lineno:number,par:Gfmt,id:string){
		this.lineno = lineno;
		this.par = par;
		this.id = id;
	}
}
export class Net implements NetI{
	static readonly nettype_S = 'S';
	static readonly nettype_G = 'G';
	static readonly nettype_P = 'P';
	lineno: number = 0;
	par:Gfmt = null;
	name:string = '';
	nettype:string = 'S';
	pins:Pin[] = []; // reference to comp's pin
	propgroup:PropGroup = null; // reference
	vias:Via[] = []; // instances
	wires:Wire[] = []; // instances
	shapes:GemShape[] = []; // instances
	//
	diffpartner:Net = null; // set after loaded, according to NET_DIFFERENTIAL_PAIR property
	netclass:NetClass = null; // belonging netclass. (we support NET_CLASS=xxx in attr)
	visible:boolean = true; // extended for fend
	//
	private _idx:number = -1;
	private bb:GemBbox = null;
	get is_diff():boolean{
		return !!this.diffpartner;
	}
	findProp(key:string):string{
		if (!this.propgroup) return '';
		return this.propgroup.findProp(key);
	}
	setProp(key:string, value:string):void{
		if (!this.propgroup){
			const propgroupid = (this.par.propgroups.length+1).toString();
			const propgroup:PropGroup = new PropGroup(0,this.par,propgroupid);
			this.par.propgroups.push(propgroup);
			this.propgroup = propgroup;
		}
		let idx = this.propgroup.props.findIndex(prop=>prop.key===key);
		if (idx>=0){
			this.propgroup.props[idx].value = value;
		}else{
			this.propgroup.props.push(new Prop(this.propgroup,-1,key,value));
		}
	}
	setparR(par:Gfmt){
		this.par = par;
		// Most of the objects are set the parent-child chain following component structure.
		// gfmt>part or comp>pin>... Net is a referencer of those objects.
		// only the exception is Wire, which does not belong to component, then belongs Net.
		this.wires.forEach((m)=>{m.setparR(this);})
	}
	get idx():number{ // index in nets array
		if (this._idx<0) this._idx = this.par.nets.indexOf(this);
		return this._idx;
	}
	select(state:boolean):void{
		for(let pin of this.pins) pin.select(state);
		for(let via of this.vias) via.select(state);
		for(let wire of this.wires) wire.select(state);
		for(let shape of this.shapes) shape.select(state);
	}
	static fromJSON(par:Gfmt, ref:string):Net{
		return new Net(0,par,'').fromI(par,<NetI>JSON.parse(ref));
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(par:Gfmt, ref:NetI):Net{
		this.par = par;
		this.lineno = ref.lineno;
		this.name = ref.name;
		this.nettype = ref.nettype;
		this.pins = [];
		for(let m of ref.pinrefs){
			const comp = par.mustFindCompByUname(m.uname,ref.lineno);
			if (!comp) continue;
			const pin = comp.mustFindPinByPinname(m.pinname,ref.lineno);
			if (!pin) continue;
			pin.par = comp;
			pin.net = this;
			this.pins.push(pin);
		}
		if (ref.propgroup){
			this.propgroup = new PropGroup(ref.lineno,par,ref.propgroup.id).fromI(par,ref.propgroup);
		}
		this.vias = ref.vias.map((m)=>{
			const via = new Via(par,ref.lineno).fromI(par,m);
			via.net = this;
			return via;
		});
		this.wires = ref.wires.map((m)=>{
			return new Wire(ref.lineno,this).fromI(this,m);
		});
		this.shapes = ref.shapes.map((m)=>{
			const shape = GemShape.mkFromI(par,m);
			shape.net = this;
			return shape;
		});
		return this;
	}
	toI():NetI{
		return {
			lineno: this.lineno,
			name: this.name,
			nettype: this.nettype,
			pinrefs: this.pins.map((m)=>{
				return {
					lineno: m.lineno,
					uname: m.par.uname,
					pinname: m.pinname
				};
			}),
			propgroup: this.propgroup?this.propgroup.toI():null,
			vias: this.vias.map((m)=>{return m.toI();}),
			wires: this.wires.map((m)=>{return m.toI();}),
			shapes: this.shapes.map((m)=>{return m.toI();})
		}
	}
	get pinrefs():PinrefI[]{
		return this.pins.map((m)=>{
			return {
				lineno:m.lineno,
				uname:m.par.uname,
				pinname:m.pinname
			};
		});
	}
	bbox():GemBbox{
		if (!this.bb){
			this.bb = new GemBbox;
			for(var i = 0 ; i < this.pins.length ; i++) this.bb.add(this.pins[i].bbox());
			for(var i = 0 ; i < this.vias.length ; i++) this.bb.add(this.vias[i].bbox());
			for(var i = 0 ; i < this.wires.length ; i++) this.bb.add(this.wires[i].bbox());
			for(var i = 0 ; i < this.shapes.length ; i++) this.bb.add(this.shapes[i].bbox());
		}
		return this.bb;
	}
	constructor(lineno:number,par:Gfmt, netname:string){
		this.lineno = lineno;
		this.par = par;
		if (netname.startsWith('"') && netname.endsWith('"')) netname = netname.substring(1,netname.length-1);
		this.name = netname;
	}
}
export class ComText implements ComTextI{
	par:Gfmt;
	lineno: number;
	layer: number;
	text: GemText;
	constructor(lineno:number,par:Gfmt){
		this.lineno = lineno;
		this.par = par;
	}
	static fromJSON(par:Gfmt, ref:string):Net{
		return new Net(0,par,'').fromI(par,<NetI>JSON.parse(ref));
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(par:Gfmt, ref:ComTextI):ComText{
		this.par = par;
		this.lineno = ref.lineno;
		this.layer = ref.layer;
		this.text = new GemText(ref.lineno,null,0,0,'').fromI(this,ref.text);
		return this;
	}
	toI():ComTextI{
		return {
			lineno: this.lineno,
			layer: this.layer,
			text: this.text.toI()
		}
	}
}
export class NetClass{
	name:string='';
	nets:Net[]=[];
	constructor(name:string){
		this.name = name;
	}
	nettype():string{
		if (this.nets.length>0) return this.nets[0].nettype;
		else return Net.nettype_S;
	}
}

export class Gfmt implements GfmtI{
	dirpath:string='';
	fname:string='';
	iserror:boolean = false;
	materials:Material[] = [];
	layers:Layer[] = [];
	shapes:GemShape[] = [];
	boardshapes:GemShape[] = [];
	padstacks:Padstack[] = [];
	parts:Comp[] = [];
	comps:Comp[] = [];
	propgroups:PropGroup[] = [];
	vialist:Via[] = [];
	nets:Net[] = [];
	partial:boolean = false;
	// nets are organized in netclasses, in our extended support for attr 'NET_CLASS=xxx'.
	// 'NET_CLASS=_netname_' is assumed if not such attr defined.
	netclasses:NetClass[] = [];
	// G-fmt is extended to support short text data
	comtexts:ComText[] = [];
	//
	logs:GemLog[] = [];
	// cache
	bb:GemBbox = null;
	private _condlays:Layer[] = null;
	constructor(){
	}
	static mkinst():Gfmt{
		return new Gfmt();
	}
	isLayer(name:string):boolean{
		return this.layers.findIndex(lay=>lay.name===name)>=0;
	}
	findNetClassByName(name:string):NetClass{
		for(let netclass of this.netclasses){
			if (netclass.name===name) return netclass;
		}
		return null;
	}
	constructNetClass():void{
		this.netclasses = [];
		for(let net of this.nets){
			var netclassname:string = '';
			if (net.propgroup) netclassname = net.findProp(PropGroup.NET_CLASS);
			if (!netclassname) netclassname = net.name;
			net.netclass = this.findNetClassByName(netclassname);
			if (!net.netclass){
				net.netclass = new NetClass(netclassname);
				this.netclasses.push(net.netclass);
			}
			net.netclass.nets.push(net);
		}
		if (this.netclasses.length>0) this.log(this.netclasses.length+' netclasses acknowledged');
		// debug
		// for(let i = 0 ; i < this.netclasses.length ; i++){
		// 	const cl = this.netclasses[i];
		// 	this.log_debug('netclass '+(i+1)+' : '+cl.name);
		// }
	}
	acknowledgeDiffpairs():void{
		let cnt = 0;
		for(let i = 0 ; i < this.nets.length ; i++){
			const net1 = this.nets[i];
			if (net1.diffpartner) continue; // set earlier
			const tag1 = net1.findProp('NET_DIFFERENTIAL_PAIR');
			if (!tag1) continue;
			for(let j = i+1 ; j < this.nets.length ; j++){
				const net2 = this.nets[j];
				const tag2 = net2.findProp('NET_DIFFERENTIAL_PAIR');
				if (tag2 && tag1===tag2){
					net1.diffpartner = net2;
					net2.diffpartner = net1;
					cnt++;
					break;
				}
			}
		}
		if (cnt>0) this.log(cnt+' diff pairs acknowledged.');
	}
	setparR():void{
		this.materials.forEach((m)=>{m.setparR(this);});
		this.layers.forEach((m)=>{m.setparR(this);});
		this.shapes.forEach((m)=>{m.setparR(this);});
		this.boardshapes.forEach((m)=>{m.setparR(this);});
		this.padstacks.forEach((m)=>{m.setparR(this);});
		this.parts.forEach((m)=>{m.setparR(this);});
		this.comps.forEach((m)=>{m.setparR(this);});
		this.propgroups.forEach((m)=>{m.setparR(this);});
		this.vialist.forEach((m)=>{m.setparR(this);});
		this.nets.forEach((m)=>{m.setparR(this);});
	}

	get condlayers():Layer[]{
		if (!this._condlays){
			this._condlays = this.layers.filter((m)=>{
				return m.type=='P'||m.type=='S';
			});
		}
		return this._condlays;
	}
	clear_selection():void{
		for(let shape of this.boardshapes) shape.select(false);
		for(let comp of this.comps) comp.select(false);
		for(let net of this.nets) net.select(false);
	}
	// -----------------------------
	// serialize/deserialize
	// -----------------------------
	static fromJSON(ref:string):Gfmt{
		return new Gfmt().fromI(<GfmtI>JSON.parse(ref));
	}
	toJSON():string{
		return JSON.stringify(this.toI());
	}
	fromI(ref:GfmtI):Gfmt{
		this.dirpath = ref.dirpath;
		this.fname= ref.fname;
		this.iserror = ref.iserror;
		this.materials = ref.materials.map((m)=>{
			return Material.mkFromI(this,m);
		});
		this.layers = ref.layers.map((m)=>{
			return new Layer(this,0).fromI(this,m);
		});
		this.shapes = ref.shapes.map((m)=>{
			return GemShape.mkFromI(this,m);
		});
		this.boardshapes = ref.boardshapes.map((m)=>{
			return GemShape.mkFromI(this,m);
		});
		this.padstacks = ref.padstacks.map((m)=>{
			return new Padstack(0,this).fromI(this,m);
		});
		this.parts = ref.parts.map((m)=>{
			return new Comp(0,this).fromI(this,m);
		});
		this.comps = ref.comps.map((m)=>{
			return new Comp(0,this).fromI(this,m);
		});
		this.propgroups = ref.propgroups.map((m)=>{
			return new PropGroup(0,this,'').fromI(this,m);
		});
		this.vialist = ref.vialist.map((m)=>{
			return new Via(this,0).fromI(this,m);
		});
		this.nets = ref.nets.map((m)=>{
			return new Net(0,this,'').fromI(this,m);
		});
		this.comtexts = ref.comtexts.map(m=>new ComText(0,null).fromI(this,m));
		return this;
	}
	toI():GfmtI{
		return {
			dirpath: this.dirpath,
			fname: this.fname,
			iserror: this.iserror,
			materials: this.materials.map((m)=>{return m.toI();}),
			layers: this.layers.map((m)=>{return m.toI();}),
			shapes: this.shapes.map((m)=>{return m.toI();}),
			boardshapes: this.boardshapes.map((m)=>{return m.toI();}),
			padstacks: this.padstacks.map((m)=>{return m.toI();}),
			parts: this.parts.map((m)=>{return m.toI();}),
			comps: this.comps.map((m)=>{return m.toI();}),
			propgroups: this.propgroups.map((m)=>{return m.toI();}),
			vialist: this.vialist.map((m)=>{return m.toI();}),
			nets: this.nets.map((m)=>{return m.toI();}),
				// netclass is not output because you can re-create by constructNetClass()
			comtexts: this.comtexts.map(m=>m.toI()),
			partial: this.partial
		}
	}

	log_source(msg:string){
		this.logs.push(new GemLog(GemLog.source,msg));
	}
	log(msg:string){
		this.logs.push(new GemLog(GemLog.info,msg));
	}
	log_error(msg:string){
		this.logs.push(new GemLog(GemLog.error,msg));
	}
	log_warning(msg:string){
		this.logs.push(new GemLog(GemLog.warning,msg));
	}
	log_debug(msg:string){
		this.logs.push(new GemLog(GemLog.debug,msg));
	}
	log_verbose(msg:string){
		this.logs.push(new GemLog(GemLog.verbose,msg));
	}
	bbox():GemBbox{
		if (!this.bb){
			this.bb = new GemBbox;
			for(var i = 0 ; i < this.boardshapes.length ; i++) this.bb.add(this.boardshapes[i].bbox());
			for(var i = 0 ; i < this.comps.length ; i++) this.bb.add(this.comps[i].bbox());
			for(var i = 0 ; i < this.nets.length ; i++) this.bb.add(this.nets[i].bbox());
		}
		return this.bb;
	}
	findOrRegisterShape(shape:GemShape):number{
		for(let i = 0 ; i < this.shapes.length ; i++){
			if (GemShape.sameshape(this.shapes[i],shape)) return (i+1);
		}
		shape.id = (this.shapes.length+1).toString();
		this.shapes.push(shape);
		return this.shapes.length;
	}
	findOrRegisterPadstack(pads:Pad[]):Padstack{
		for(let padstack of this.padstacks){
			if (padstack.samepads(pads)) return padstack;
		}
		const padstack = new Padstack(0,null);
		padstack.id = (this.padstacks.length+1).toString();
		padstack.pads = pads;
		this.padstacks.push(padstack);
		return padstack;
	}
	pskid2pskno(pskid:string):number{
		for(let i = 0 ; i < this.padstacks.length ; i++){
			if (this.padstacks[i].id===pskid) return (i+1);
		}
		throw new Error('bug: no such padstack '+pskid);
	}
	
	findOrCreateNetByName(netname:string):Net{
		for(var i = 0 ; i < this.nets.length ; i++){
			const net = this.nets[i];
			if (net.name === netname) return net;
		}
		const net = new Net(0,this,netname);
		this.nets.push(net);
		return net;
	}
	mustFindNetByNetname(netname:string):Net{
		const net = this.findNetByNetname(netname);
		if (!net) throw new Error('no such net '+netname);
		return net;
	}
	findNetByNetname(netname:string):Net{
		for(var i = 0 ; i < this.nets.length ; i++){
			const net = this.nets[i];
			if (net.name === netname) return net;
		}
		return null;
	}
	mustFindPropGroupById(id:string, lineno:number):PropGroup{
		for(var i = 0 ; i < this.propgroups.length ; i++){
			const grp = this.propgroups[i];
			if (grp.id===id) return grp;
		}
		throw new Error('line '+lineno+' : no such attr id "'+id+'"');
	}
	findPadstackById(id:string):Padstack{
		for(let padstack of this.padstacks){
			if (padstack.id===id) return padstack;
		}
		return null;
	}
	mustFindPadstackById(id:string, lineno:number):Padstack{
		for(let padstack of this.padstacks){
			if (padstack.id===id) return padstack;
		}
		throw new Error('line '+lineno+' : no such padstack "'+id+'"');
	}
	findPartByPartname(partname:string):Comp{
		for(let comp of this.parts) if (comp.partname===partname) return comp;
		return null;
	}
	findViaInVialistByVianame(vianame:string):Via{
		for(let via of this.vialist) if (via.vianame===vianame) return via;
		return null;
	}
	mustFindCompByUname(uname:string, lineno:number):Comp{
		for(var i = 0 ; i < this.comps.length ; i++){
			var comp = this.comps[i];
			if (comp.uname===uname) return comp;
		}
		throw new Error('line '+lineno+' : no such component "'+uname+'"');
	}
	mustFindPinByUnameDotPinname(uname_dot_pinname:string):Pin{
		const i = uname_dot_pinname.indexOf('.');
		if (i<=0) throw new Error('not in dot form : '+uname_dot_pinname);
		const uname = uname_dot_pinname.substring(0,i);
		const comp = this.mustFindCompByUname(uname,0);
		const pinname = uname_dot_pinname.substring(i+1);
		return comp.mustFindPinByPinname(pinname,0);
	}
	mustFindPartByPartname(partname:string, lineno:number):Comp{
		for(var i = 0 ; i < this.parts.length ; i++){
			var part = this.parts[i];
			if (part.partname===partname) return part;
		}
		throw new Error('line '+lineno+' : no such part "'+partname+'"');
	}
	findOrCreateLayerByName(layname:string):number{
		let layno = 0;
		for(let i =0 ; i < this.layers.length ; i++){
			const layer = this.layers[i];
			if (layer.type==='S'||layer.type==='P'){
				layno++;
				if (layer.name === layname) return layno;
			}
		}
		const layer = new Layer(this,0);
		layer.name = layname;
		layer.type = 'S';
		layer.condmat = 'CU';
		layer.dielemat1 = 'AIR';
		this.layers.push(layer);
		return layno+1;
	}
	layno(layname:string):number{
		let layno = 0;
		for(let i =0 ; i < this.layers.length ; i++){
			const layer = this.layers[i];
			if (layer.type==='S'||layer.type==='P'){
				layno++;
				if (layer.name === layname) return layno;
			}
		}
		return 0;
	}
	layerCnt():number{
		var cnt = 0;
		for(var i = 0 ; i < this.layers.length ; i++){
			if (this.layers[i].type === 'S'||this.layers[i].type === 'P') cnt++;
		}
		return cnt;
	}
	exportLog():FileLogDroI{
		const log:FileLogDroI = {
			dirpath: this.dirpath,
			fname: this.fname,
			logs: this.logs,
			error_cnt: 0,
			warning_cnt: 0
		}
		return log;
	}
}
