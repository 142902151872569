<header1></header1>
<div class="page-body">
    <div class="page-container-small">
        <mat-grid-list cols="1">
            <mat-card>
                <mat-card-title class="mat-title">
                    Change Password
                </mat-card-title>
                <mat-card-content>
                    <form [formGroup]="form" (onSubmit)="onSubmit()">
                        <div>
                            <mat-form-field>
                                <mat-label>Old password</mat-label>
                                <input matInput type="password"  formControlName="oldpassword">
                                <mat-error *ngIf="fieldError('oldpassword')">
                                    {{fieldError('oldpassword')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <mat-label>New password</mat-label>
                                <input matInput type="password" formControlName="newpassword">
                                <mat-error *ngIf="fieldError('newpassword')">
                                    {{fieldError('newpassword')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <mat-label>New password (type again)</mat-label>
                                <input matInput type="password" formControlName="newpassword2">
                                <mat-error *ngIf="fieldError('newpassword2')">
                                    {{fieldError('newpassword2')}}
                                </mat-error>
                            </mat-form-field>
                            <div *ngIf="!fieldError('newpassword') &&
                                !fieldError('newpassword2') && crossFieldError('nomatch')"
                                class="mat-caption place-cross-field-error-at-above-field">
                                {{crossFieldError('nomatch')}}
                            </div>
                        </div>
                    </form>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button color="primary"
                        [disabled]="loading" (click)="onSubmit()">
                        <mat-spinner *ngIf="loading"></mat-spinner>
                        Change
                    </button>
                </mat-card-actions>
            </mat-card>
        </mat-grid-list>
    </div>
</div>
<div class="footer">
	<a class="footer" href="https://gemdt.com" target="_top">Gem Design Technologies, Inc.</a>
</div>
