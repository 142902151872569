import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { AcctService } from '@app/acctservice/acct.service';
import { AlertService } from '@app/helper/alert.service';

@Component({
    templateUrl: 'register1.component.html',
    styleUrls: ['register1.component.css']
})
export class Register1Component implements OnInit, OnDestroy {
    form: FormGroup;
    loading = false;
    agreed = new FormControl();

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private acctService: AcctService,
        private alertService: AlertService,
        private route: ActivatedRoute
    ) {
        if (this.acctService.curruser) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        // checkbox cannot be a mat-form-field
        this.form = this.formBuilder.group({
            email: [this.route.snapshot.queryParams['email']||'',
                [Validators.required, Validators.email]]
        });
    }
    fieldError(string:string):string{
        if (string==='email'){
            if (this.form.controls.email.errors){
                if (this.form.controls.email.errors.required) return 'required';
                else return 'doesn\'t seem like an email address';
            }
        }
        return '';
    }

    ngOnSubmit() {
        this.form.markAllAsTouched();
        this.alertService.clear();
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.acctService.register_email(this.form.controls.email.value)
            .subscribe(
                () => {
                    this.router.navigate(['/register2'],{ queryParams: {
                            email: this.form.controls.email.value
                    }})
                },
                (error:HttpErrorResponse|Error) => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            );
    }
    ngOnDestroy(){
        this.alertService.success('Security code is just sent to '+
            this.form.controls.email.value,true);
    }
}
