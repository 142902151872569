import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { matchvalidator } from '@app/helper/matchvalidator';
import { AcctService } from '@app/acctservice/acct.service';
import { AlertService } from '@app/helper/alert.service';

@Component({
  templateUrl: 'register2.component.html',
  styleUrls:['register2.component.css']
})
export class Register2Component implements OnInit {
    form: FormGroup;
    loading = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private acctService: AcctService,
        private alertService: AlertService,
        private route: ActivatedRoute){
        if (this.acctService.curruser) {
            this.router.navigate(['/']);
        }
    }
    ngOnInit() {
        this.form = this.formBuilder.group({
            email: [this.route.snapshot.queryParams['email']||''],
            seccode: ['',Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            password2: ['', Validators.required]
        },{
            validators: matchvalidator('password', 'password2', 'nomatch')
        });
    }
    fieldError(field:string):string{
        // returns non-empty string iff the field has validation error.
        // note: cross-field validation error is NOT a validation error of any specific field,
        // in the angular's validation mechanism.
        if (!this.form.controls[field].errors) return '';
        if (field==='seccode') return 'required';
        else if (field==='password') return '6 chars at least, please';
        else if (field==='password2') return 'required';
        else return '(bug) unknown field : '+field;
    }
    crossFieldError(errname:string):string{
        // returns non-empty string iff form has cross-field validation error.
        if (!this.form.errors) return '';
        if (!this.form.errors[errname]) return '';
        if (errname==='nomatch') return 'matching error';
        else return '(bug) unknown errname : '+errname;
    }
    onSubmit() {
        this.form.markAllAsTouched();
            // angular shows mat-error for a field iff the field is touched and has
            // a validation error.
        this.alertService.clear();
        if (this.form.invalid) {
            return;
        }
        this.loading = true;
        this.acctService.register_password(this.form.controls.email.value,
                this.form.controls.seccode.value,
                this.form.controls.password.value)
            .subscribe(
                () => {
                    this.alertService.success("Registration complete. Please login.",true);
                    this.router.navigate(['/login'],
                        {queryParams: {
                            email: this.form.controls.email.value
                        }});
                },
                (error:HttpErrorResponse|Error) => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
    reissue_seccode(){
        this.acctService.issue_seccode(this.form.controls.email.value,
            "registration",true).subscribe(
            ()=>{
                this.alertService.success("New security code is sent to "+
                    this.form.controls.email.value+". Use it within 10 minutes.");
            }
        )
    }
}
