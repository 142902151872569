<header1></header1>
<div class="page-body">
    <div class="page-container-small">
        <mat-grid-list cols="1">
            <mat-card>
                <mat-card-title class="mat-title">
                    <mat-icon>person</mat-icon>
                    Profile
                </mat-card-title>
                <mat-card-content>
                    <form [formGroup]="form" (onSubmit)="onSubmit()">
                        <div>
                            <mat-form-field>
                                <mat-label>Username</mat-label>
                                <input matInput readonly type="text" formControlName="username">
                                <button mat-icon-button matSuffix routerLink="/setting/chguname">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <mat-label>E-mail</mat-label>
                                <input matInput readonlly type="text" formControlName="email">
                                <button mat-icon-button matSuffix routerLink="/setting/chgemail">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <mat-label>Password</mat-label>
                                <input matInput readonly type="text" formControlName="password" >
                                <button mat-icon-button matSuffix routerLink="/setting/chgpw">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                        <div>
                            Check latest <a href="assets/user_agreement_latest.txt"> gem-lpb.com User Agreement</a>
                        </div>
                    </form>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button color="primary" (click)="onSubmit()">
                        Looks Okay
                    </button>
                    <button mat-raised-button class="go-right" color="warn" routerLink="/setting/deleteacct">
                        Delete this account
                    </button>
                </mat-card-actions>
            </mat-card>
        </mat-grid-list>
    </div>
</div>
<div class="footer">
	<a class="footer" href="https://gemdt.com" target="_top">Gem Design Technologies, Inc.</a>
</div>
