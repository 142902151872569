import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup,
    ValidationErrors, Validator, ValidatorFn, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
    // DialogRef means dialog class, Dialog means dialog factory class.

// data model
export interface RegUserDialogI {
    product: string;
    product_opts: string[];
    email: string;
    seccode: string;
    keytype: string;
    keyvalue: string;
    // hostname_re: string; // depre
    // ipaddr_re: string; // depre
}

class EmailOrStar implements Validator{
    validate(control:AbstractControl):ValidationErrors|null{
        if (!control.value) return null;
        if (control.value==='*') return null;
        // the standard email validator cannot be invoked
        // like 'else return Validators.email'.
        // so we need to do it our own.
        const idx = control.value.indexOf('@');
        if (idx<=0||idx>=control.value.length) return {notemail:true};
        return null;
    }
}
const keyvalueValidator = function(form:FormGroup): ValidatorFn{
    return ():ValidationErrors|null=>{
        const keytype = form.controls['keytype'].value;
        const keyvalue = form.controls['keyvalue'].value;
        if (keytype=='mac' && keyvalue){
            const value = keyvalue.replace(/[-:]/g,'');
            if (value.length!=12 || /\H/.test(value)){ // \H means [^0-9a-fA-F]
                return {notmac:true};
            }
        }
        return null;
    }
}
@Component({
    selector: "reguserdialog",
    templateUrl: "./reguserdialog.component.html",
    styleUrls:['reguserdialog.component.css']
})
export class RegUserDialogComponent implements OnInit{
    form:FormGroup;
    constructor(
        private formBuilder: FormBuilder,
        public dialog: MatDialogRef<RegUserDialogI>,
        @Inject(MAT_DIALOG_DATA) public model: RegUserDialogI
    ){}
    ngOnInit(){
        this.form = this.formBuilder.group({
            product: [this.model.product],
            email: [this.model.email, [new EmailOrStar()]],
            keytype: [this.model.keytype],
            keyvalue: [this.model.keyvalue],
            seccode: [this.model.seccode],
        });
        this.form.controls['keyvalue'].setValidators(keyvalueValidator(this.form));
            // single-field validator can observe other fields in this way.
    }
    fieldError(field:string):string{
        if (!this.form.controls[field].errors) return '';
        else if (this.form.controls[field].errors.required) return 'required';
        else if (this.form.controls[field].errors.notmac) return 'doesn\'t seem like a MAC address';
        else if (this.form.controls[field].errors.notemail) return 'doesn\'t seem like an email';
        else return 'unexpected error: '+JSON.stringify(this.form.controls[field].errors);
    }
    fieldValue(field:string):string{
        return this.form.controls[field].value;
    }
    ok(){
        this.form.markAllAsTouched();
        if (this.form.invalid) return;
        this.model.product = this.form.controls.product.value;
        this.model.email = this.form.controls.email.value;
        this.model.seccode = this.form.controls.seccode.value;
        this.model.keytype = this.form.controls.keytype.value;
        this.model.keyvalue = this.form.controls.keyvalue.value;
        this.dialog.close(this.model);
    }
    cancel(){
        this.dialog.close(null);
    }
}
