import { UserDroI, GemGfmtDtoI } from 'gemlib/dist_fe/dtodro';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AcctService } from '@app/acctservice/acct.service';
import { AlertService} from '@app/helper/alert.service';
import { Fent } from '@app/fileservice/file.service';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from '@app/dataservice/data.service';
import { Gfmt, Layer, Net } from 'gemlib/dist_fe/gfmt';
import { View, Search, G } from './ggv.canvas';
import * as ggv from './ggv.canvas';

class LayerVisibility{
    _layer:Layer;
    layno:number;
    get name(){return this._layer.name;}
    // get dot_class(){
    //     return this.layno===View.curr_layer?"text-light":"text-primary";
    // }
    set visible(state:boolean){
        this.visibleI = state;
        ggv.redraw();
    }
    set visibleI(state:boolean){
        this._layer.visible = state;
    }
    get visible(){
        return this._layer.visible;
    }
    constructor(layno:number,layer:Layer){
        this.layno = layno;
        this._layer = layer;
    }
}
class NetVisibility{
    net:Net;
    _visible:boolean;
    get name(){
        if (!this.net) return "signal";
        else return this.net.name;
    }
    get sort_big_key():number{
        if (!this.net) return 0;
        else if (this.net.nettype===Net.nettype_S) return 0;
        else if (this.net.nettype===Net.nettype_P) return 1;
        else return 2;
    }
    get visible(){
        return this._visible;
    }
    set visible(state:boolean){
        this.visibleI = state;
        ggv.redraw();
    }
    set visibleI(state:boolean){
        this._visible = state;
        if (this.net) this.net.visible = state;
        else{
            for(let net of G.gfmt.nets){
                if (net.nettype===Net.nettype_S) net.visible = state;
            }
        }
    }
    constructor(net:Net){
        this.net = net;
        if (this.net) this._visible = this.net.visible;
        else this.visible = true;
    }
}
@Component({
    templateUrl: 'ggv.component.html',
    styleUrls: ['ggv.component.css'],
})
export class GgvComponent implements OnInit {
    curruser: UserDroI;
    currdir: Fent;
    loading = false;
    submitted = false;
    searchtext: string = "";
    filename: string = "N/A";
    filepath_str:string = "";
    memory = window.performance
    get currlayer(){return View.curr_layer;}
    get currlayername(){return ggv.isdata()?G.gfmt.condlayers[View.curr_layer-1].name:"";}
    get layercnt(){
        return ggv.isdata()?G.gfmt.layerCnt():0;
    }
    _layers:LayerVisibility[] = null;
    get layers():LayerVisibility[]{
        if (!ggv.isdata()) return [];
        if (!this._layers) this._layers = G.gfmt.condlayers.map((m:any,idx:number)=>{return new LayerVisibility(idx+1,m);});
        return this._layers;
    }
    _nets:NetVisibility[] = null;
    get nets():NetVisibility[]{
        if (!ggv.isdata()) return [];
        if (!this._nets){
            if (G.gfmt.nets.length>1000){
                this._nets = [new NetVisibility(null)];
                for(let net of G.gfmt.nets){
                    if (net.nettype===Net.nettype_G||net.nettype===Net.nettype_P){
                        this._nets.push(new NetVisibility(net));
                    }
                }
            }else{
                this._nets = G.gfmt.nets.map((m:Net)=>{return new NetVisibility(m);});
            }
            this._nets.sort(function(a:NetVisibility, b:NetVisibility):number{
                const k = a.sort_big_key - b.sort_big_key;
                return (k!=0)? k: a.name.localeCompare(b.name);
            });
        }
        return this._nets;
    }
    get manylayer(){return this.layercnt>=1;}
    get see_subst(){return View.subs_vis;}
    set see_subst(b){View.subs_vis = b; ggv.redraw()}
    get see_comp(){return View.comp_vis;}
    set see_comp(b){View.comp_vis = b; ggv.redraw();}
    get see_pinpos(){return View.pinpos_vis;}
    set see_pinpos(b){View.pinpos_vis = b; ggv.redraw();}
    get see_viapos(){return View.viapos_vis;}
    set see_viapos(b){View.viapos_vis = b; ggv.redraw();}
    get see_bwire(){return View.wire_vis;}
    set see_bwire(b){View.wire_vis = b; ggv.redraw();}
    get see_via(){return View.via_vis;}
    set see_via(b){View.via_vis = b; ggv.redraw();}
    get see_routing(){return View.route_vis;}
    set see_routing(b){View.route_vis = b; ggv.redraw();}
    get see_objinfo(){return View.objinfo_vis;}
    set see_objinfo(b){View.objinfo_vis = b; ggv.redraw();}
    get see_compname(){return View.compname_vis;}
    set see_compname(b){View.compname_vis = b; ggv.redraw();}
    get see_pinname(){return View.pinname_vis;}
    set see_pinname(b){View.pinname_vis = b; ggv.redraw();}
    get see_netname(){return View.netname_vis;}
    set see_netname(b){View.netname_vis = b; ggv.redraw();}
    get see_lineno(){return View.lineno_vis}
    set see_lineno(b){View.lineno_vis = b; ggv.redraw();}
    get coordstr(){return ggv.isdata()?View.curr_mouse_ux+" "+View.curr_mouse_uy:"0 0";}
    isnet(netname:string){}

    view_fit(){ggv.fit_redraw();}
    view_zoomin(){View.scale *= G.ZOOM_BY_BUTTON; ggv.redraw();}
    view_zoomout(){ View.scale /= G.ZOOM_BY_BUTTON; ggv.redraw();}
    search_text(){Search.search(this.searchtext);};
    search_next(){Search.goto_next_or_prev_selection(true);}
    search_prev(){Search.goto_next_or_prev_selection(false);}
    pan_left(){
        View.canvascen.x -= Math.min(G.canvas.width,G.canvas.height) * G.PAN_BY_BUTTON / View.upratio();
        ggv.redraw();
    }
    pan_bottom(){
        View.canvascen.y -= Math.min(G.canvas.width,G.canvas.height) * G.PAN_BY_BUTTON / View.upratio();
        ggv.redraw();
    }
    pan_top(){
        View.canvascen.y += Math.min(G.canvas.width,G.canvas.height) * G.PAN_BY_BUTTON / View.upratio();
        ggv.redraw();
    }
    pan_right(){
        View.canvascen.x += Math.min(G.canvas.width,G.canvas.height) *G.PAN_BY_BUTTON / View.upratio();
        ggv.redraw();
    }
    get see_farupperlay(){return View.far_upper_layer_vis;}
    set see_farupperlay(b){
        View.far_upper_layer_vis = b;
        if (b) View.near_upper_layer_vis = b;
        ggv.redraw();
    }
    get see_upperlay(){return View.near_upper_layer_vis;}
    set see_upperlay(b){
        View.near_upper_layer_vis = b;
        if (!b) View.far_upper_layer_vis = b;
        ggv.redraw();
    }
    get see_lowerlay(){return View.near_lower_layer_vis;}
    set see_lowerlay(b){
        console.log('sss='+b);
        View.near_lower_layer_vis = b;
        if (!b) View.far_lower_layer_vis = b;
        ggv.redraw();
    }
    get see_farlowerlay(){return View.far_lower_layer_vis;}
    set see_farlowerlay(b){
        View.far_lower_layer_vis = b;
        if (b) View.near_lower_layer_vis = b;
        ggv.redraw();
    }
    set_allnetvis(){
        const nets = this.nets;
        function allnet_vis():boolean{
            return nets.every((m)=>{return m.visible});
        }
        function allnet_onoff(b:boolean):void{
            nets.forEach((m)=>{m.visibleI = b;});
        }
        allnet_onoff(!allnet_vis());
        ggv.redraw();
    }
    set_alllayvis(){
        const lays = this.layers;
        function alllay_vis():boolean{
            return lays.every((m)=>{return m.visible});
        }
        function alllay_onoff(b:boolean):void{
            lays.forEach((m)=>{m.visibleI = b;});
        }
        alllay_onoff(!alllay_vis());
        ggv.redraw();
    }
    see_otherlays(){
        if (View.far_upper_layer_vis && View.near_upper_layer_vis &&
            View.far_lower_layer_vis && View.near_lower_layer_vis){
            View.far_upper_layer_vis = false;
            View.near_upper_layer_vis = false;
            View.far_lower_layer_vis = false;
            View.near_lower_layer_vis = false;
        }else{
            View.far_upper_layer_vis = true;
            View.near_upper_layer_vis = true;
            View.far_lower_layer_vis = true;
            View.near_lower_layer_vis = true;
        }
        ggv.redraw();
    }
    goto_toplay(){
        if (!ggv.isdata()) return;
        View.curr_layer = 1;
        ggv.redraw();
    }
    goto_upperlay(k:number){
        if (!ggv.isdata()) return;
        let layer = View.curr_layer;
        if (k==1){
            layer -= 1;
        }else{
            const mod = layer % k;
            layer -= mod? mod:k;
        }
        layer = Math.max(1,layer);
        View.goto_layer(layer);
        ggv.redraw();
    }
    goto_lowerlay(k:number){
        if (!ggv.isdata()) return;
        let layer = View.curr_layer;
        if (k==1){
            layer += 1;
        }else{
            const mod = layer % k;
            layer += mod? (k-mod):k;
        }
        layer = Math.min(G.gfmt.layerCnt(),layer);
        View.goto_layer(layer);
        ggv.redraw();
    }
    goto_bottomlay(){
        if (!ggv.isdata()) return;
        View.curr_layer = G.gfmt.layerCnt();
        ggv.redraw();
    }
    goto_layer(layer:number){
        if (!ggv.isdata()) return;
        View.goto_layer(Math.max(1,Math.min(G.gfmt.layerCnt(),layer)));
        ggv.redraw();
    }
    set alpha_curr(percent:number){
        if (!ggv.isdata()) return;
        View.alpha_curr = percent*0.01;
        ggv.redraw();
    }
    set alpha_noncurr(percent:number){
        if (!ggv.isdata()) return;
        View.alpha_noncurr = percent*0.01;
        ggv.redraw();
    }
    clear_selection(){
        if (!ggv.isdata()) return;
        G.gfmt.clear_selection();
        Search.clear_search_result();
        ggv.redraw();
    }
    set mouse_mode(val){
        if (val==='zoom') View.is_mouse_zoommode = !View.is_mouse_zoommode;
        else View.is_mouse_selmode = (val==='select');
    }
    get mouse_mode(){
        if (View.is_mouse_zoommode) return 'zoom';
        else if (View.is_mouse_selmode) return 'select';
        else return 'pan';
    }

    constructor(
        private acctService: AcctService,
        private dataService: DataService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private cookieService: CookieService,
        // private titleService: Title,
    ) {
        this.curruser = this.acctService.curruser;
    }

    ngOnInit() {
        ggv.setup_canvas();
        var dirpath = this.route.snapshot.queryParams['dir'];
        var infname = this.route.snapshot.queryParams['infile'];
        var outfname = this.route.snapshot.queryParams['outfile'];
        // console.log(dirpath+", "+infname+", "+outfname);
        if (infname){
            this.filename = infname;
            this.load_file(dirpath,infname);
            // this.titleService.setTitle(infname);
            this.filepath_str = dirpath+'/'+infname;
        }else if (outfname){
            // this.titleService.setTitle(outfname);
            this.filepath_str = dirpath+'/'+outfname;
        }else{
            this.filepath_str = dirpath+'/unnamed.xfl';
        }
    }
    load_file(dir:string, fname:string){
        this.loading = true;
        const dto:GemGfmtDtoI = {dir: dir, fname: fname};
        this.dataService.get_gfmt(dto)
        .subscribe((gfmt:Gfmt)=>{
            G.gfmt = gfmt;
            this._layers = null;
            View.curr_layer = 1;
            ggv.fit_redraw();
        },(error)=>{
            this.alertService.error(error);
            this.loading = false;
        },()=>{
            this.loading = false;
        });
    }
}
