import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AcctService } from '../acctservice/acct.service';
import { AlertService } from './alert.service';

@Injectable({ providedIn: 'root' })
export class LoggedInUserOnly implements CanActivate {
    constructor(
        private router: Router,
        private alertService: AlertService,
        private acctService: AcctService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean {
        const userDro = this.acctService.curruser;
        if (userDro) { // logged-in
            return true;
        }else{
            this.alertService.error("Authentication Error");
            this.router.navigate(['/login']);
            return false;
        }
    }
}