<header1></header1>
<div class="page-body">
    <div class="page-container-medium">
        <mat-grid-list cols="1">
            <mat-card>
                <mat-card-title class="mat-title">
                    Groups
                </mat-card-title>
                <mat-card-content>
                    <group-table (change)="onChangeGroup($event)">
                    </group-table>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button class="go-right" (click)="renamegroup_pressed()">
                        <small>Rename Group</small>
                    </button>
                </mat-card-actions>
            </mat-card>
            <ng-container *ngIf="currgrp">
                <mat-card>
                    <mat-card-title>
                        <mat-spinner *ngIf="loading"></mat-spinner>
                        {{currgrp.groupname}} members
                        <span class="mat-caption">(* mark means manager)</span>
                    </mat-card-title>
                    <mat-card-content>
                        <div style="margin-left:1em;">
                            <div *ngFor="let mbr of currgrp.members">
                                <mat-checkbox [checked]="mbr.checked"
                                    [(ngModel)]="mbr.checked"
                                    name="mbr.username">
                                    {{mbr.username}} ({{mbr.email}})
                                    {{mbr.ismanager?" *":""}}
                                </mat-checkbox>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-raised-button color="primary" (click)="addmember_pressed()">
                            <small>Add Member</small>
                        </button>
                        <button mat-raised-button class="go-right" (click)="assnmgr_pressed()">
                            <small>Assign Manager</small>
                        </button>
                        <button mat-raised-button (click)="unassnmgr_pressed()">
                            <small>Unassign Manager</small>
                        </button>
                        <button mat-raised-button color="warn" (click)="removemember_pressed()">
                            <small>Remove Member</small>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </ng-container>
        </mat-grid-list>
    </div>
</div>
<div class="footer">
	<a class="footer" href="https://gemdt.com" target="_top">Gem Design Technologies, Inc.</a>
</div>
