import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
    // DialogRef means dialog class, Dialog means dialog factory class.

// data model
export interface RegLicMgrDialogI {
    email1: string;
    email2: string;
    email3: string;
}
@Component({
    selector: "reglicmgrdialog",
    templateUrl: "./reglicmgrdialog.component.html",
    styleUrls:['reglicmgrdialog.component.css']
})
export class RegLicMgrDialogComponent implements OnInit{
    form:FormGroup;
    constructor(
        private formBuilder: FormBuilder,
        public dialog: MatDialogRef<RegLicMgrDialogI>,
        @Inject(MAT_DIALOG_DATA) public model: RegLicMgrDialogI
    ){}
    ngOnInit(){
        this.form = this.formBuilder.group({
            email1: [this.model.email1, [Validators.email]],
            email2: [this.model.email2, [Validators.email]],
            email3: [this.model.email3, [Validators.email]],
        });
    }
    fieldError(field:string):string{
        if (!this.form.controls[field].errors) return '';
        else return 'doesn\'t seem like an email';
    }
    ok(){
        this.form.markAllAsTouched();
        if (this.form.invalid) return;
        this.model.email1 = this.form.controls.email1.value;
        this.model.email2 = this.form.controls.email2.value;
        this.model.email3 = this.form.controls.email3.value;
        this.dialog.close(this.model);
    }
    cancel(){
        this.dialog.close(null);
    }
}
