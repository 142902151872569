<table mat-table [dataSource]="groups" class="mat-elevation-z8">
    <ng-container matColumnDef="groupname">
        <th mat-header-cell *matHeaderCellDef>
            group name
        </th>
        <td mat-cell *matCellDef="let grp"> 
            <mat-radio-group [(ngModel)]="seldGroup" (change)=onChange()>
                <mat-radio-button [value]="grp">
                    {{grp.groupname}}
                    {{grp.groupname===curruser.username?" (private)":""}}
                </mat-radio-button>
            </mat-radio-group>
        </td>
    </ng-container>
    <ng-container matColumnDef="mbrcnt">
        <th mat-header-cell *matHeaderCellDef>
            #mbr
        </th>
        <td mat-cell *matCellDef="let grp">
            {{grp.members.length}}
        </td>
    </ng-container>
    <ng-container matColumnDef="usedkb">
        <th mat-header-cell *matHeaderCellDef>
            used (KB)
        </th>
        <td mat-cell *matCellDef="let grp">
            {{grp.diskplan.usedkb_str}}
        </td>
    </ng-container>
    <ng-container matColumnDef="totalkb">
        <th mat-header-cell *matHeaderCellDef>
            total (KB)
        </th>
        <td mat-cell *matCellDef="let grp">
            {{grp.diskplan.quotakb_str}}
        </td>
    </ng-container>
    <ng-container matColumnDef="expire">
        <th mat-header-cell *matHeaderCellDef>
            expire
        </th>
        <td mat-cell *matCellDef="let grp">
            {{grp.diskplan.quotauntil_yymmdd}}
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="groupTableCols"></tr>
    <tr mat-row *matRowDef="let row; columns: groupTableCols;"></tr>
</table>
