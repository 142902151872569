import { Component } from '@angular/core';

import { UserDroI } from 'gemlib/dist_fe/dtodro';
import { Router } from '@angular/router';
import { AcctService } from './acctservice/acct.service';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  // title = 'fend05';
  curruser: UserDroI;

  constructor(
      private router: Router,
      private acctService: AcctService,
  ) {
      this.acctService.curruser_obse.subscribe(x => this.curruser = x);
  }
  logout() {
      this.acctService.logout();
      this.router.navigate(['/loggedout']);
  }
}
