import { FentDroI, UploadDtoI, DownloadDtoI, DeleteDtoI,
    MkdirDtoI, MoveCopyDtoI, MkBackupDtoI } from 'gemlib/dist_fe/dtodro';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Util } from '@app/helper/util';

// convert dro to local structure to add parent pointer in the tree etc.
export class Fent implements FentDroI{
    name: string;
    type: string; // 'D': directory, 'O': other
    modified: number;
    get modified_yymmdd_hhmm(){
        return Util.yymmdd_hhmm(this.modified);
    }
    sizekb: number;
    get sizekbstr():string{
        return Util.kbstr(this.sizekb);
    }
    diskplan: {
        quotakb: number;
        quotauntil: number;
        usedkb: number;
    }
    quotakb_str():string{
        return Util.kbstr(this.diskplan.quotakb);
    }
    freekb_str():string{
        return Util.kbstr(Math.max(0,this.diskplan.quotakb-this.diskplan.usedkb));
    }
    children: Fent[];
    checked: boolean = false;
    par: Fent;
    sort(colname:string, inc:boolean):void{
        this.children.sort((f1:Fent, f2:Fent)=>{
            if (colname==='name'){
                return Util.compareFname(f1.name,f2.name) * (inc?1:-1);
            }else if (colname==='date'){
                return (f1.modified - f2.modified) * (inc?1:-1);
            }else if(colname==='size'){
                return (f1.sizekb - f2.sizekb) * (inc?1:-1);
            }else throw Error("bug: colname="+colname);
        });
    }
    constructor(dro:FentDroI){
        this.name = dro.name;
        this.type = dro.type;
        this.modified = dro.modified;
        this.sizekb = dro.sizekb;
        this.diskplan = dro.diskplan;
        this.children = [];
        for(let i = 0 ; i < dro.children.length ; i++){
            const chd = new Fent(dro.children[i]);
            chd.par = this;
            this.children.push(chd);
        }
    }
    /**
     * returns an array of children names
     * @param filter 'checked', 'unchecked', or 'all'
     */
    childnames(filter:string):string[]{
        var names: string[] = [];
        for(let i =0 ; i < this.children.length ; i++){
            if (filter=='checked'){
                if (this.children[i].checked) names.push(this.children[i].name);
            // }else if (filter=='checked_nondir'){
            //     if (this.children[i].checked && this.children[i].type!==FentType.DIR)
            //         names.push(this.children[i].name);
            }else if (filter=='unchecked'){
                if (!this.children[i].checked) names.push(this.children[i].name);
            }else if (filter=='all'){
                names.push(this.children[i].name);
            }else throw new Error("bug unknown filter : "+filter);
        }
        return names;
    }
    check_children(names:string[]):void{
        for(let i =0 ; i < this.children.length ; i++){
            const chd = this.children[i];
            chd.checked = names.includes(chd.name);
        }
    }

    path():string{
        if (this.par){
            var s = this.name;
            for(let par = this.par ; par ; par = par.par){
                if (par.par) s = par.name+"/"+s;
                else s = "/"+s;
            } 
            return s;
        }else{
            return "/";
        }
    }
    findbypath(path:string):Fent{
        const names = path.split('/'); // '/abc/def' ==> [,abc,def]
        var fent:Fent = this;
        for(let i = 1 ; i < names.length ; i++){
            var found = false;
            for(let j = 0 ; j < fent.children.length && !found ; j++){
                if (names[i] === fent.children[j].name){
                    fent = fent.children[j]; found = true;
                }
            }
            if (!found) return fent;
                // '/abc/def' is given, but no 'def' in 'abc', then 'abc' is returned.
        }
        return fent;
    }
}
    
@Injectable({ providedIn: 'root' })
export class FileService{
    constructor(private http: HttpClient){
    }
    // get entire file entry information for the user
    get_fent():Observable<Fent>{
        // convert dro to local structure to add parent pointer in the tree etc.
        return this.http.get<FentDroI>(environment.apiUrl+"/file/fent")
            .pipe(map((dro:FentDroI)=>{return new Fent(dro);}));
    }
    upload(formdata:FormData, dto:UploadDtoI):Observable<HttpEvent<any>>{
        formdata.append('dto',JSON.stringify(dto));
        return this.http.post<any>(environment.apiUrl+"/file/upload/",
            formdata, {reportProgress: true, observe: 'events'});
    }
    download(dto:DownloadDtoI):Observable<HttpEvent<any>>{
        return this.http.get<any>(environment.apiUrl+"/file/download/",
            {
                reportProgress: true,
                observe: 'events',
                params: {
                    dto: JSON.stringify(dto)
                }
            });
    }
    move_or_copy(ismove:boolean, srcdir:string, dstdir:string, srcfnames:string[], dstfnames:string[]):Observable<void>{
        if (ismove){
            return this.move(srcdir,dstdir,srcfnames,dstfnames);
        }else{
            return this.copy(srcdir,dstdir,srcfnames,dstfnames);
        }
    }
    move(srcdir:string, dstdir: string, srcfnames:string[], dstfnames:string[]):Observable<void>{
        const dto:MoveCopyDtoI = {
            srcdir: srcdir,
            dstdir: dstdir,
            srcfnames: srcfnames,
            dstfnames: dstfnames
        };
        return this.http.put<void>(environment.apiUrl+"/file/move/",dto);
    }
    copy(srcdir:string, dstdir: string, srcfnames:string[], dstfnames:string[]):Observable<void>{
        const dto:MoveCopyDtoI = {
            srcdir: srcdir,
            dstdir: dstdir,
            srcfnames: srcfnames,
            dstfnames: dstfnames
        };
        return this.http.put<void>(environment.apiUrl+"/file/copy/",dto);
    }
    delete(dir:string, fnames:string[]):Observable<void>{
        const dto:DeleteDtoI = {
            dir: dir,
            fnames: fnames
        };
        return this.http.delete<void>(environment.apiUrl+"/file/delete/",
        {
            params: {
                dto: JSON.stringify(dto)
            }
        });
    }
    mkdir(pardir:string, dirname:string):Observable<void>{
        const dto:MkdirDtoI = {
            pardir: pardir,
            dirname: dirname
        };
        return this.http.put<void>(environment.apiUrl+"/file/mkdir/",dto);
    }
    backup(srcdir:string, tgtdir:string):Observable<void>{
        const dto:MkBackupDtoI = {
            srcdir: srcdir,
            tgtdir: tgtdir
        };
        return this.http.post<void>(environment.apiUrl+"/file/backup/",dto);
    }
}