import { UserDroI } from 'gemlib/dist_fe/dtodro';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertService} from '@app/helper/alert.service';
import { AcctService } from '@app/acctservice/acct.service';

@Component({
    templateUrl: 'deleteacct.component.html',
    styleUrls:['deleteacct.component.css']
})
export class DeleteAccountComponent implements OnInit {
    userDro: UserDroI;

    constructor(
        private router: Router,
        private acctService: AcctService,
        private alertService: AlertService
    ) {
        this.userDro = acctService.curruser;
    }

    ngOnInit() {
    }

    delete_acct(){
        this.acctService.delete_user()
            .subscribe(
                () => {
                    this.alertService.success("User "+this.userDro.email+" deleted.",true);
                    this.acctService.logout(); // to clear 'login' state
                    this.router.navigate(['/loggedout']);
                },
                (error:HttpErrorResponse|Error) => {
                    this.alertService.error(error);
                });
        }
}
