<header1></header1>
<div class="page-body">
    <div class="page-container-small">
        <mat-grid-list cols="1">
            <mat-card>
                <mat-card-title>
                    <mat-icon>settings</mat-icon>
                    Preference
                </mat-card-title>
                <mat-card-content>
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <h3>Auto directories</h3>
                        <p>
                            By specifying directory names, system automatically creates those directories under your home directory, and provide relevant services.
                        </p>
                        <div style="margin-left: 1rem">
                            <!--1-->
                            <div>
                                <mat-form-field>
                                    <mat-label>
                                        <mat-icon>person</mat-icon>
                                        Project directory
                                    </mat-label>
                                    <input type="text" matInput formControlName="projs"
                                        placeholder="ex. projs"/>
                                    <button mat-icon-button matSuffix type="button"
                                        (click)="iscollapsed_projs=!iscollapsed_projs;">
                                        <mat-icon>help</mat-icon>
                                    </button>
                                    <mat-error *ngIf="fieldError('projs')">
                                        {{fieldError('projs')}}
                                    </mat-error>
                                </mat-form-field>
                                <div *ngIf="!fieldError('projs') && crossFieldError('projs')"
                                    class="mat-caption place-cross-field-error-at-above-field">
                                    {{crossFieldError('projs')}}
                                </div>
                                <div *ngIf="!iscollapsed_projs">
                                    <mat-card class="help-block">
                                        <mat-card-subtitle>
                                            Project directory
                                        </mat-card-subtitle>
                                        <mat-card-content>
                                            <p>
                                                If specified, 'backup' function and 'trash-based-deletion' feature can be available for the folder.
                                            </p>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                            <!--2-->
                            <div>
                                <mat-form-field>
                                    <mat-label>
                                        <mat-icon>cloud</mat-icon>
                                        Upload directory
                                    </mat-label>
                                    <input type="text" matInput formControlName="upload"
                                        placeholder="ex. upload">
                                    <button mat-icon-button matSuffix type="button"
                                        (click)="iscollapsed_upload=!iscollapsed_upload">
                                        <mat-icon>help</mat-icon>
                                    </button>
                                    <mat-error *ngIf="fieldError('upload')">
                                    {{fieldError('upload')}}
                                    </mat-error>
                                </mat-form-field>
                                <div *ngIf="!fieldError('upload') && crossFieldError('upload')"
                                    class="mat-caption place-cross-field-error-at-above-field">
                                    {{crossFieldError('upload')}}
                                </div>
                                <div *ngIf="!iscollapsed_upload">
                                    <mat-card class="help-block">
                                        <mat-card-subtitle>
                                            Upload directory
                                        </mat-card-subtitle>
                                        <mat-card-content>
                                            <p>
                                                If specified, 'upload' command will upload files always to the specified directory. Otherwise, upload files are placed in the current directory.
                                            </p>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                            <!--3-->
                            <div>
                                <mat-form-field>
                                    <mat-label>
                                        <mat-icon>delete</mat-icon>
                                        Trash directory
                                    </mat-label>
                                    <input type="text" matInput formControlName="trash"
                                        placeholder="ex. trash">
                                    <button mat-icon-button matSuffix type="button"
                                        (click)="iscollapsed_trash=!iscollapsed_trash;">
                                        <mat-icon>help</mat-icon>
                                    </button>
                                    <mat-error *ngIf="fieldError('trash')">
                                        {{fieldError('trash')}}
                                    </mat-error>
                                </mat-form-field>
                                <div *ngIf="!fieldError('trash') && crossFieldError('trash')"
                                    class="mat-caption place-cross-field-error-at-above-field">
                                    {{crossFieldError('trash')}}
                                </div>
                                <div *ngIf="!iscollapsed_trash">
                                    <mat-card class="help-block">
                                        <mat-card-subtitle>
                                            Trash directory
                                        </mat-card-subtitle>
                                        <mat-card-content>
                                            <p>
                                                If specified, delete operation in the project folder works as just moving the deleting files to the trash directory.
                                            </p>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                            <!--4-->
                            <div>
                                <mat-form-field>
                                    <mat-label>
                                        <mat-icon>file_copy</mat-icon>
                                        Backup directory
                                    </mat-label>
                                    <input type="text" matInput formControlName="backup"
                                        placeholder="ex. backup">
                                    <button mat-icon-button matSuffix type="button"
                                        (click)="iscollapsed_backup=!iscollapsed_backup">
                                        <mat-icon>help</mat-icon>
                                    </button>
                                    <mat-error *ngIf="fieldError('backup')">
                                        {{fieldError('backup')}}
                                    </mat-error>
                                </mat-form-field>
                                <div *ngIf="!fieldError('backup') && crossFieldError('backup')"
                                    class="mat-caption place-cross-field-error-at-above-field">
                                    {{crossFieldError('backup')}}
                                </div>
                                <div *ngIf="!iscollapsed_backup">
                                    <mat-card class="help-block">
                                        <mat-card-subtitle>
                                            Backup directory
                                        </mat-card-subtitle>
                                        <mat-card-content>
                                            <p>
                                                If specified, 'backup' button will copy the selected file or folder to the backup directory, using an incremental backup tool.
                                            </p>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                        <h3>
                            Other settings
                        </h3>
                        <div>
                            <mat-form-field>
                                <mat-label>
                                    <mat-icon>access_time</mat-icon>
                                    Maximum login time (hours)<br>
                                </mat-label>
                                <input type="text" matInput formControlName="jwt_expires_in_hours"
                                    placeholder="ex. 24">
                                <button mat-icon-button matSuffix type="button"
                                    (click)="iscollapsed_maxtime=!iscollapsed_maxtime;">
                                    <mat-icon>help</mat-icon>
                                </button>
                                <mat-error *ngIf="fieldError('jwt_expires_in_hours')">
                                    {{fieldError('jwt_expires_in_hours')}}
                                </mat-error>
                            </mat-form-field>
                            <div *ngIf="!iscollapsed_maxtime">
                                <mat-card class="help-block">
                                    <mat-card-subtitle>
                                        Maximum login time
                                    </mat-card-subtitle>
                                    <mat-card-content>
                                        <p>
                                            If your login time exceeds this limit, you will be
                                            forced to logout by the system.
                                        </p>
                                        <p>
                                            For example, you can set this to your maximum worktime,
                                            so that the system automatically log you
                                            out if you eventually go home without turning
                                            off your pc.
                                            You never want this setting to be too short,
                                            not to be forced to logout when you are still using.
                                        </p>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                        <mat-checkbox [checked]="gemchk_gfmt_see_source">
                            Let LPB-format checker display the raw content of G-format files.
                        </mat-checkbox>
                    </form>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button color="primary" (click)="onSubmit()">
                        Looks Okay
                    </button>
                </mat-card-actions>
            </mat-card>
        </mat-grid-list>
    </div>
</div>
<div class="footer">
	<a class="footer" href="https://gemdt.com" target="_top">Gem Design Technologies, Inc.</a>
</div>
