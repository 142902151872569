<header0></header0>
<div class="page-body">
    <div class="page-container-small">
        <mat-grid-list cols="1">
            <mat-card>
                <mat-card-title class="mat-title">
                    Log In
                </mat-card-title>
                <mat-card-content>
                    <form [formGroup]="form" (onSubmit)="ngOnSubmit()">
                        <div>
                            <mat-form-field>
                                <mat-label>E-mail</mat-label>
                                <input type="email" matInput
                                formControlName="email">
                                <mat-error *ngIf="fieldError('email')">
                                    {{fieldError('email')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <mat-label>Password</mat-label>
                                <input type="password" matInput 
                                formControlName="password" (keyup.enter)="ngOnSubmit()">
                                    <!-- (keyup.enter)= fires when user hit Enter key in the field. -->
                                <mat-error *ngIf="fieldError('password')">
                                    {{fieldError('password')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </form>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button color="primary" (click)="ngOnSubmit()" cdkFocusInitial> Login</button>
                    <button mat-fab class="go-right" color="accent" (click)="onRegister()"> Register</button>
                </mat-card-actions>
                <div>
                    Forgot password? Try <button mat-stroked-button color="warn" (click)="onForgotPassword()">reset your password</button>
                </div>
            </mat-card>
            <mat-card>
                <mat-card-title>
                    News
                </mat-card-title>
                <mat-list dense>
                    <div *ngFor="let article of news.news">
                        <mat-divider></mat-divider>
                        <mat-list-item>
                            ({{article.date}})&nbsp;{{article.text}}
                        </mat-list-item>
                    </div>
                </mat-list>
            </mat-card>
        </mat-grid-list>
    </div>
</div>
<div class="footer">
	<a class="footer" href="https://gemdt.com" target="_top">Gem Design Technologies, Inc.</a>
</div>
