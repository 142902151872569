import { UserDroI, UploadDtoI, DownloadDtoI} from 'gemlib/dist_fe/dtodro';
import { Component, OnInit, ElementRef, ViewChild, Type } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Fent, FileService } from '@app/fileservice/file.service';
import { AlertService } from '@app/helper/alert.service';
import { Sort } from '@angular/material/sort';
import { TxinDialogComponent, TxinDialogI } from '@app/helper/txindialog.component';
import { saveAs } from 'file-saver-es';
import { Util } from '@app/helper/util';
import { CookieService } from 'ngx-cookie-service';
import { CookieKey } from '@app/helper/cookie-key';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AcctService } from '@app/acctservice/acct.service';

/**
 * file wrapper, for uploading/downloading progress bar
 */
class FileWrap{
    fname: string;
    upfile: File;
    inprogress: boolean;
    progress: number;
    error: string;
    constructor(fname:string){
        this.fname = fname;
    }
}

/**
 * File explorer, which is the center page of the site
 */
@Component({
    templateUrl: 'explorer.component.html',
    styleUrls:['explorer.component.css']
})
export class ExplorerComponent implements OnInit{
    // ------------------ members ---------------------------

    // major info
    curruser: UserDroI; // the user we are serving for
    rootdir: Fent; // directory tree info for the user
    loading: number = 0; // spinner rounds while this is non-zero


    // current folder fent table
    currdir: Fent; // the directory currently shown
    fentTableCols: string[] = ['size','date','check','name'];

    // column sorting
    sortby: string; // 'size','date','name'.
    sortinc: boolean = true; // incremental or decremental


    // top directories are artificially created as {/home, /group}.
    // the backend maps those artificial dirs to the real paths.
    get currdirpath():string{
        if (this.currdir) return this.currdir.path();
        else return "";
    }
    get groupfents():Fent[]{
        if (this.rootdir) return this.rootdir.findbypath('/groups').children;
        else return [];
    }

    // quota usage bar support
    get quota_info():string{
        const disk = this.currdisk;
        if (!disk) return '';
        let per = this.quota_used_percent;
        if (per>=100) return disk.name+' DISK FULL';
        else return per+' %';
    }
    get quota_used_percent():number{
        const disk = this.currdisk;
        if (!disk) return 0;
        let per = disk.diskplan.usedkb/disk.diskplan.quotakb*100;
        return Math.min(100,Math.max(0,Math.round(per)));
    }
    get quota_tooltip():string{
        const disk = this.currdisk;
        if (!disk) return '';
        if (disk.diskplan.usedkb>=disk.diskplan.quotakb) return disk.name+' DISK FULL';
        else return disk.name+' '+disk.quotakb_str()+' KB total, '+disk.freekb_str()+" KB free";
    }
    private get currdisk():Fent{
        for(let fent = this.currdir ; !!fent ; fent = fent.par){
            if (fent.diskplan) return fent;
        } 
        if (this.rootdir) return this.rootdir.findbypath('/home');
        return null;
    }

    // trash support
    get is_trash():boolean{
        const b:boolean =  
            !!this.curruser.pref?.autodir?.projs &&
            !!this.curruser.pref?.autodir?.trash;
        return b;
    }
    get now_in_trash():boolean{
        const b:boolean =
            this.is_trash &&
            this.currdirpath.startsWith('/home/'+this.curruser.pref.autodir.trash);
        return b;
    }

    // cut buffer
    cutbuf = {
        dirpath: "",
        fnames: [] as string[],
        iscut: false // cut or copy
    };
    // a file entry is shown in bold font, if it is in a cut buffer
    incutbuf(fname:string):boolean{
        return this.cutbuf.dirpath===this.currdirpath &&
            this.cutbuf.fnames.includes(fname);
    }

    // local file selector is raised by this button for upload,
    // but the button is hidden because ugly. we hide the button
    // from the screen, and click it from program.
    @ViewChild("hidden", {static: false}) hidden: ElementRef; files:string[] = [];

    // upload/download progress table.
    // the lines are automatically created as upload/download begins, and
    // disappear when completed the last one, but the error lines stays
    // until explicit refresh opepration.
    fwraps: FileWrap[] = [];
    get updnloading():boolean{
        return this.fwraps.filter(fwrap=>fwrap.inprogress).length>0;
    }

    constructor(
        private acctService: AcctService,
        private fileService: FileService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        // private modalService: NgbModal, // TODO need modal service
        private cookieService: CookieService,
        private dialogFactory: MatDialog
    ) {
        this.curruser = this.acctService.curruser;
    }
    ngOnInit() {
        var path = this.route.snapshot.queryParams['path'];
        if (path){
            if (!path.startsWith('/')) path = '/'+path;
            if (!path.startsWith('/group') && !path.startsWith('/home')){
                path = '/home' + path;
            }
        }else{
            path = this.cookieService.get(CookieKey.gemlpb_explorer_currdir);
            if (this.cookieService.get('gemlpb-explorer-currdir'))
            if (!path){
                if (this.curruser.pref?.autodir?.projs) path = '/home/'+this.curruser.pref.autodir.projs;
                else path = '/home';
            }
        }
        this.cookieService.set(CookieKey.gemlpb_explorer_currdir,path,10);
        this.sortby = this.cookieService.get(CookieKey.gemlpb_explorer_sortby);
        this.sortinc = this.cookieService.get(CookieKey.gemlpb_explorer_sortinc) === "true";
        this.get_fent(path);
    }
    logout() {
        this.acctService.logout();
        this.router.navigate(['/loggedout']);
    }
    matSortChange(sort:Sort):void{
        this.sortby = sort.active;
        this.sortinc = sort.direction === 'asc';
        this.cookieService.set(CookieKey.gemlpb_explorer_sortby,this.sortby,10);
        this.cookieService.set(CookieKey.gemlpb_explorer_sortinc,this.sortinc.toString(),10);
        this.refresh_pressed();
        // this.currdir.sort(this.sortby,this.sortinc);
    }
    chdir(subdirname:string):void{
        this.alertService.clear();
        if (subdirname==='..'){
            if (this.currdir.par) this.currdir = this.currdir.par;
        }else if (subdirname.startsWith('/')){
            this.currdir = this.rootdir.findbypath(subdirname);
        }else{
            this.currdir = this.currdir.findbypath('/'+subdirname);
        }
        // debugger // pause on debugger
        this.cookieService.set(CookieKey.gemlpb_explorer_currdir,this.currdirpath,10);
        // 2020-06-27
        // you may want use router to help browser's 'back'/'forward' buttons,
        // but doing like followings does not help.
        // this.router.navigate(['/file/explorer'],{ queryParams: { path: '/home/projs/myproj1' }});
        // this.router.navigate(['/file/explorer/home/projs/myproj1'] }});
        // because the router doesn't care about the parameter difference, or
        // the unmatched part of the url.
    }
    get allchecked():boolean{
        return this.currdir?.children?.length>0 &&
            this.currdir.children.every(fent=>fent.checked)
    }
    get somechecked():boolean{
        return this.currdir?.children?.filter(fent=>fent.checked).length>0 &&
            !this.allchecked;
    }
    sel_pressed(b:boolean):void{
        this.alertService.clear();
        this.currdir.children.forEach(fent=>fent.checked = b);
    }
    cut_pressed(){
        this.alertService.clear();
        if (this.cutbuf.iscut && this.autodir_dies_when_checked_files_die()) return;
        this.cutbuf.fnames = this.currdir.childnames('checked');
        this.cutbuf.dirpath = this.currdirpath;
        this.cutbuf.iscut = true;

    }
    refresh_pressed(){
        this.loading++;
        // Util.sleep(2000).then(()=>{
            this.loading--;
            this.alertService.clear();
            this.get_fent(this.currdirpath);
            if (!this.updnloading) this.fwraps = [];
            this.cutbuf.fnames = [];
        // });
    }
    private get_fent(path:string){
        const checked_children = (this.currdir)? this.currdir.childnames('checked'): null;
        this.loading = 1;
        this.fileService.get_fent()
        .subscribe((fent: Fent) => {  
            this.rootdir = fent;
            this.currdir = this.rootdir.findbypath(path);
            // this.fentTable = this.currdir.children.map(fent=>new FentTable(fent));
            if (checked_children) this.currdir.check_children(checked_children);
            if (this.sortby) this.currdir.sort(this.sortby,this.sortinc);
        },(error)=>{
            this.alertService.error(error);
            this.loading = 0;
        },()=>{
            this.loading = 0;
        });
    }
    copy_pressed(){
        this.alertService.clear();
        this.cutbuf.dirpath = this.currdir.path();
        this.cutbuf.fnames = this.currdir.childnames('checked');
        this.cutbuf.iscut = false;
    }
    clone_pressed(){
        this.alertService.clear();
        const orgfnames = this.currdir.childnames('checked');
        if (orgfnames.length<=0) return;
        this.loading = 1;
        const allnames = this.currdir.childnames('all');
        const newfnames:string[] = [];
        for(let i = 0 ; i <orgfnames.length ; i++){
            newfnames.push(Util.newname(orgfnames[i],allnames,newfnames));
        }
        this.fileService.copy(this.currdirpath,this.currdirpath,orgfnames,newfnames)
        .pipe(
            finalize(()=>{
                this.loading = 0;
                this.get_fent(this.currdirpath);
            })
        )
        .subscribe(()=>{
        },(error)=>{
            this.alertService.error(error);
        },()=>{
        });
    }

    paste_pressed(){
        this.alertService.clear();
        if (!this.cutbuf.dirpath) return;
        if (this.cutbuf.fnames.length<=0) return;
        if (this.currdirpath === this.cutbuf.dirpath && this.cutbuf.iscut) return;

        const allnames = this.currdir.childnames('all');
        const dstfnames:string[] = [];
        for(let i = 0 ; i <this.cutbuf.fnames.length ; i++){
            const fname = this.cutbuf.fnames[i];
            if (allnames.includes(fname)){
                dstfnames.push(Util.newname(fname,allnames,dstfnames));
            }else{
                dstfnames.push(fname);
            }
        }
        this.loading = 1;
        this.fileService.move_or_copy(this.cutbuf.iscut,
            this.cutbuf.dirpath,this.currdirpath,this.cutbuf.fnames,dstfnames)
        .pipe(
            finalize(()=>{
                this.loading = 0;
                this.get_fent(this.currdirpath);
            })
        )
        .subscribe(()=>{
        },(error)=>{
            this.alertService.error(error);
        },()=>{
        });
        this.cutbuf.fnames = [];
    }
    delete_pressed(){
        this.alertService.clear();
        const fnames = this.currdir.childnames('checked');
        if (fnames.length<=0) return;
        if (this.autodir_dies_when_checked_files_die()) return;
        if (!this.is_trash || this.now_in_trash){
            // delete case
            this.loading = 1;
            this.fileService.delete(this.currdirpath,fnames)
            .pipe(
                finalize(()=>{
                    this.loading = 0;
                    this.get_fent(this.currdirpath);
                })
            )
            .subscribe(()=>{
            },
            (error)=>{
                this.alertService.error(error);
            },()=>{
            });
        }else{
            // trash case
            let trash:Fent = this.rootdir.findbypath('/home/'+this.curruser.pref.autodir.trash);
            const allnames = trash.childnames('all');
            const dstfnames:string[] = [];
            for(let i = 0 ; i < fnames.length ; i++){
                const fname = fnames[i];
                if (allnames.includes(fname)){
                    dstfnames.push(Util.newname(fname,allnames,dstfnames));
                }else{
                    dstfnames.push(fname);
                }
            }
            this.loading = 1;
            this.fileService.move(this.currdirpath,trash.path(),fnames,dstfnames)
            .pipe(
                finalize(()=>{
                    this.loading = 0;
                    this.get_fent(this.currdirpath);
                })
            )
            .subscribe(()=>{
            },(error)=>{
                this.alertService.error(error);
            },()=>{
            });
        }
        // delete clears the copy/paste operation.
        this.cutbuf.dirpath = null;
        this.cutbuf.fnames = null;
    }
    rename_pressed(){
        this.alertService.clear();
        if (this.autodir_dies_when_checked_files_die()) return;
        let fnames = this.currdir.childnames('checked');
        if (fnames.length<=0) return;
        const dialog = this.dialogFactory.open(TxinDialogComponent,{
            width: '400px',
            data:<TxinDialogI>{
                title: "Rename",
                labels:fnames,
                values:fnames.slice()
                    /* slice() makes a shallow copy. when the user changes the old name with new name
                    on the dialog, a new string is made because string is immutable in js,
                    and then the pointer of the new string replaces the old pointer.*/
            }
        });
        dialog.afterClosed().subscribe((model:TxinDialogI|null)=>{
            if (!model) return; // canceled
            const others  = this.currdir.childnames('unchecked');
            let dupname = null;
            for(let i =0 ; i < model.labels.length && !dupname ; i++){
                const oldfname = model.labels[i];
                const newfname = model.values[i];
                if (others.includes(newfname)){ dupname = newfname;}
                for(let j = 0 ; j < model.labels.length && !dupname ; j++){
                    if (i==j) continue;
                    const oldfname2 = model.labels[j];
                    const newfname2 = model.values[j];
                    if (newfname === oldfname2 || newfname === newfname2) dupname = newfname;
                }
            }
            if (dupname){
                this.alertService.error("'"+dupname+"' is duplicated.");
                return;
            }
            this.loading = 1;
            this.fileService.move(this.currdirpath,this.currdirpath,
                model.labels,
                model.values)
            .pipe(
                finalize(()=>{
                    this.loading = 0;
                    this.get_fent(this.currdirpath);
                })
            )
            .subscribe(()=>{
            },(error)=>{
                this.alertService.error(error);
            },()=>{
            });

        });
    }
    mkdir_pressed(){
        this.alertService.clear();
        var dirname = "newdir";
        var idx = 0;
        for(let i = 0 ; i < this.currdir.children.length ; i++){
            const name = this.currdir.children[i].name;
            if (name.startsWith("newdir")){
                const arr = /([0-9]+)$/.exec(name);
                if (arr&&arr[1]) idx = Math.max(Number.parseInt(arr[1]));
            }
        }
        dirname += (idx+1);
        const dialog = this.dialogFactory.open(TxinDialogComponent,{
            width: '400px',
            data: <TxinDialogI>{
                title: "Make Directory",
                labels:['new directory name'],
                values:[dirname]
            }
        });
        dialog.afterClosed().subscribe((model:TxinDialogI|null)=>{
            if (!model) return;
            if (this.currdir.childnames('all').includes(model.values[0])){
                this.alertService.error("'"+model.values[0]+"' duplicated.");
                return;
            }
            this.loading = 1;
            this.fileService.mkdir(this.currdirpath,model.values[0])
            .pipe(
                finalize(()=>{
                    this.loading = 0;
                    this.get_fent(this.currdirpath);
                })
            )
            .subscribe(()=>{
            },(error)=>{
                this.alertService.error(error);
            },()=>{
            });
        });
    }
    upload_pressed(){
        this.alertService.clear();
        if (this.updnloading) return;
        this.fwraps = [];
        const hidden = this.hidden.nativeElement;
        hidden.onchange = () => {
            for (let i = 0; i < hidden.files.length; i++){  
                const file:File = hidden.files[i];
                const fwrap = new FileWrap(file.name);
                fwrap.upfile = file;
                this.fwraps.push(fwrap);
            }  
            this.upload_files();
        };
        hidden.click();
    }
    private upload_files():void{
        this.hidden.nativeElement.value = '';  
        this.fwraps.forEach((fwrap:FileWrap) => {  
          this.upload_file(fwrap);
        });          
    }
    private upload_file(fwrap:FileWrap):void{
        const dto:UploadDtoI = {
            lastmodified: fwrap.upfile.lastModified,
            dir: (this.curruser.pref?.autodir?.upload)?
                '/home/'+this.curruser.pref.autodir.upload:
                this.currdirpath,
        }
        const formdata = new FormData();
        formdata.append('file',fwrap.upfile);
        fwrap.inprogress = true;
        fwrap.error = null;
        this.fileService.upload(formdata, dto)
        .pipe(
            finalize(()=>{
                if (!this.updnloading){
                    this.fwraps = this.fwraps.filter(function(fwrap,index,err){return fwrap.error;});
                    const save = this.fwraps;
                    this.get_fent(dto.dir);
                    this.fwraps = save; // preserve error files until next refresh.
                }
            })
        )
        .subscribe((event: any) => {
            if (event.type==HttpEventType.UploadProgress){
                fwrap.progress = Math.round(event.loaded * 100 / event.total);
            }
        },(error)=>{
            fwrap.inprogress = false;
            fwrap.error = error.status == 413? error.status+" "+error.statusText:
                (error.error)? error.status+" "+error.error.message:
                error.status+" "+error.message;
        },()=>{
            fwrap.inprogress = false;
        });
    }
    download_pressed(){
        this.alertService.clear();
        if (this.updnloading) return;
        let unchecked = false;
        for(let fent of this.currdir.children){
            if (fent.checked && fent.type==='D'){
                fent.checked = false;
                unchecked = true;
            }
        }
        if (unchecked){
            this.alertService.success('directory cannot be downloaded, thus unchecked.');
            return;
        }
        const fnames = this.currdir.childnames('checked');
        if (fnames.length<=0) return;
        this.fwraps = [];
        this.download_files(this.currdirpath,fnames);
    }
    private download_files(dir:string, fnames:string[]){
        for(let i = 0 ; i < fnames.length ; i++){
            this.download_file(dir,fnames[i]);
        }
    }
    private download_file(dir:string, fname:string){
        const dto:DownloadDtoI = {
            dir: dir,
            fname: fname
        }
        const fwrap = new FileWrap(fname);
        fwrap.inprogress = true;
        fwrap.progress = 0;
        fwrap.error = null;
        this.fwraps.push(fwrap);
        this.fileService.download(dto)
        .pipe(
            finalize(()=>{
                if (!this.updnloading){
                    this.fwraps = this.fwraps.filter(function(fwrap,index,err){return fwrap.error;});
                    const save = this.fwraps;
                    this.get_fent(this.currdirpath);
                    this.fwraps = save; // preserve error files until next refresh.
                }
            })
        )
        .subscribe((event)=>{
            if (event.type===HttpEventType.DownloadProgress){
                if (event.total){
                    fwrap.progress = Math.round(event.loaded * 100 / event.total);
                }
            }else if (event.type===HttpEventType.Response){
                if (!event.body){
                    fwrap.error = "seems too big to download, for this browser";
                }else{
                    const data = new Uint8Array(event.body.data);
                    const blob = new Blob([data],{type:'application/octet-stream'});
                    saveAs(blob, fwrap.fname);
                }
            }
        },(error)=>{
            fwrap.error = error.message;
            fwrap.inprogress = false;
        },()=>{
            fwrap.inprogress = false;
        });
    }
    backup_pressed(){
        if (!this.curruser.pref.autodir.projs ||
            !this.curruser.pref.autodir.backup){
            this.alertService.error('backup cannot work without setting "project folder" and "backup folder"');
            return;
        }
        this.loading = 1;
        this.fileService.backup(
            '/home/'+this.curruser.pref.autodir.projs,
            '/home/'+this.curruser.pref.autodir.backup
        )
        .pipe(
            finalize(()=>{
                this.loading = 0;
                this.get_fent(this.currdirpath);
            })
        )
        .subscribe(()=>{
            this.alertService.success('/home/'+this.curruser.pref.autodir.projs+
                ' is backed up into /home/'+this.curruser.pref.autodir.backup);
        },(error)=>{
            this.alertService.error(error);
        },()=>{
        });
    }
    private autodir_dies_when_checked_files_die():boolean{
        const autodirs:string[] = [];
        if (this.curruser.pref.autodir.projs){
            autodirs.push('/home/'+this.curruser.pref.autodir.projs);
            if (this.curruser.pref.autodir.trash){
                autodirs.push('/home/'+this.curruser.pref.autodir.trash);
            }
            if (this.curruser.pref.autodir.backup){
                autodirs.push('/home/'+this.curruser.pref.autodir.backup);
            }
        }
        if (this.curruser.pref.autodir.upload){
            autodirs.push('/home/'+this.curruser.pref.autodir.upload);
        }
        if (autodirs.length<=0) return false;
        let sels = this.currdir.childnames('checked');
        for(let i = 0 ; i < autodirs.length ; i++){
            for(let fent = this.rootdir.findbypath(autodirs[i]); (fent.par); fent = fent.par){
                if (fent.par===this.currdir){
                    if (sels.includes(fent.name)){
                        this.alertService.error("'"+autodirs[i]+"' cannot be moved/removed");
                        return true;
                    }
                }
            }
        }
        return false;
    }
    ggv_pressed(){
        const infiles = this.currdir.childnames('checked');
        if (infiles.length<=0){
            this.alertService.error("select a file and run viewer");
            return;
        }else if (infiles.length>1){
            this.alertService.error("select only one .xfl file, please.");
            return;
        }
        this.cookieService.set(CookieKey.gemlpb_explorer_currdir,this.currdirpath,10);
        this.cookieService.set(CookieKey.gemlpb_explorer_sortby,this.sortby,10);
        this.cookieService.set(CookieKey.gemlpb_explorer_sortinc,this.sortinc?"true":"false",10);
        this.router.navigate(['/tool/gemgv'],
            {queryParams:{
                dir:this.currdirpath,
                infile:infiles[0],
                outfile:Util.newname(infiles[0],this.currdir.childnames('all'))
                }});

        // let url = '/tool/gemgv?dir='+this.path2param(this.currdirpath);
        // url += '&infile='+infiles[0]+'&outfile='+Util.newname(infiles[0],this.currdir.childnames('all'));
        // window.open(url); // opens the url in a new tab '_blank' is not needed as the 2nd param
    }
    gck_pressed(){
        const infiles = [];
        for(let i = 0 ; i < this.currdir.children.length ; i++){
            const chd = this.currdir.children[i];
            if (chd.checked && chd.type!=='D') infiles.push(chd.name);
        }
        if (infiles.length<=0){
            this.alertService.error("no file selected");
            return;
        }
        if (infiles.length==1) infiles.push(""); // array of size 1 is not well understood by the router.
        this.cookieService.set(CookieKey.gemlpb_explorer_currdir,this.currdirpath,10);
        this.cookieService.set(CookieKey.gemlpb_explorer_sortby,this.sortby,10);
        this.cookieService.set(CookieKey.gemlpb_explorer_sortinc,this.sortinc?"true":"false",10);
        this.router.navigate(['/tool/gemcheck'],
            {queryParams:{
                dir:this.currdirpath,
                files:infiles,
                }});
        // we can do as follow to open a new tab, but it's a kind of non-standared.
        // let url = '/tool/gemcheck?dir='+this.path2param(this.currdirpath);
        // if (infiles.length==1){
        //     url += '&files='+infiles[0]+'&files=';
        //         // ugly way to differentiate with non-array parameter.
        //         // the router.navigate() works fine without such dummy parameter,
        //         // but it also fails when re-loading the page.
        // }else{
        //     for(let i = 0 ; i < infiles.length ; i++){
        //         url += '&files='+infiles[i]
        //     }
        // }
        // window.open(url);
    }
    private path2param(path:string):string{
        return path.replace(/\//g,'%2F');
    }
}