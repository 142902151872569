import { LicenseeStatusDroI, LicPlanDroI, UserDroI } from 'gemlib/dist_fe/dtodro';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, Validators, ValidationErrors } from '@angular/forms';
import { AlertService} from '@app/helper/alert.service';
import { Router } from '@angular/router';
import { DataService } from '@app/dataservice/data.service';
import { AcctService, Group } from '@app/acctservice/acct.service';
import { LicService } from '@app/licservice/lic.service';


class PeriodPlan{
    constructor(
        public planDescr:string
    ){}
}

@Component({
    templateUrl: 'gemlpbapp.component.html',
    styleUrls:['gemlpbapp.component.css']
})
export class GemLpbAppComponent implements OnInit {
    curruser: UserDroI;

    // lic plan table
    gemlpbLicPlans: LicPlanDroI[] = [];
    gemlpbLicPlanTableCols=['planDescr','priceDescr']; // period, price
    seldLicPlan: LicPlanDroI; // maintained in template

    // lic count
    lic_count: string = "1";

    // licensee table
    readonly new_licensee = "New Licensee";
    licensees: string[] = [];
    licenseeTableCols=['licensee'];
    seldLicensee: string = this.new_licensee; // maintained in template
    new_licensee_name: string = '';

    constructor(
        private alertService: AlertService,
        private acctService: AcctService,
        private dataService: DataService,
        private licService: LicService,
        private route: Router,
        ){
            this.curruser = acctService.curruser;
        }
    ngOnInit() {
        this.dataService.get_gemlpb_licplans().subscribe((plans:LicPlanDroI[])=>{
            this.gemlpbLicPlans = plans;
            this.seldLicPlan = plans[0];
        },(err:Error)=>{
            this.alertService.error(err);
            console.log('error for get_gemlpb_licplans')
        },()=>{
        });
        this.licService.licensee_status(this.curruser.email).subscribe((dro:LicenseeStatusDroI[])=>{
            // collect necessary info of all licensees under his management.
            // i.e. license file, server licenses, client licenses, online users, managers.
            // statistics info is not collected, which can be shown in end-user tool.
            this.licensees = dro.map(x=>{return x.licensee;});
            this.licensees.push(this.new_licensee);
            this.seldLicensee = this.licensees.length>0? this.licensees[0]: this.new_licensee;
        },(err:Error)=>{
            this.alertService.error(err);
            console.log('error returned from licservice.find_licensees()')
        },()=>{
        });
    }
    contactUsPressed(){
        this.alertService.clear();
        const licensee_name = this.seldLicensee === this.new_licensee? this.new_licensee_name: this.seldLicensee;
        const liccnt = this.lic_count? Number.parseInt(this.lic_count): 1;
        console.log(licensee_name);
        console.log(this.seldLicPlan.product);
        console.log(this.seldLicPlan.feature);
        console.log(this.seldLicPlan.planDescr);
        console.log(liccnt);
        this.acctService.lic_purchase_inquiry(
            licensee_name,
            this.seldLicPlan.product,
            this.seldLicPlan.feature,
            this.seldLicPlan.planDescr,
            liccnt)
        .subscribe(()=>{
            this.alertService.success('an email is sent to '+this.curruser.email,true);
        },
        (err)=>{
            this.alertService.error(err);
        });
    }
    activate(buf:string) {
        this.alertService.clear();
        console.log(buf);
        if (!buf){
            this.alertService.error("service code is not found");
            return;
        }
        const idx1 = buf.indexOf('info=');
        const idx2 = buf.indexOf('signature=');
        if (idx1<0){
            this.alertService.error('keyword "info=" not found in the service code.');
            return;
        }
        if (idx2<0){
            this.alertService.error('keyword "signature=" not found in the service code.');
            return;
        }
        const info:string = buf.substring(idx1+'info='.length).split(/\n/)[0];
            // GEMPROD$Gem Design Tech$gemdt$diskquota/10G$network$2020-11-01$2021-10-31$2021-10-31$
        const sign:string = buf.substring(idx2+'signature='.length).split(/\n/)[0];
            // $2b$10$GI7sxRo/2ebyY/u5m3Moeel1nGSCxUCqc3mJ6IN1AFAL5WYP6mWeq
        this.acctService.change_groupdiskplan(info,sign).
        subscribe((group:Group)=>{
            this.alertService.success('diskplan activated.');

        },(err)=>{
            this.alertService.error(err);
        });
    }
    downloadGemLMD(){
        const hidden = document.getElementById("hidden");
        hidden.click();
    }
}
