<header1></header1>
<div class="page-body">
    <div class="page-container-small">
        <mat-grid-list cols="1">
            <mat-card>
                <mat-card-title class="mat-title">
                    Change Username
                </mat-card-title>
                <mat-card-content>
                    <form [formGroup]="form" (onSubmit)="onSubmit()">
                        <div>
                            <mat-form-field>
                                <mat-label>Old username</mat-label>
                                <input readonly matInput type="text" formControlName="olduname">
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field>
                                <mat-label>New username</mat-label>
                                <input matInput type="text" formControlName="newuname">
                                <mat-hint>
                                    ex: abc, abc123, abc_123
                                </mat-hint>
                                <mat-error *ngIf="fieldError('newuname')">
                                    {{fieldError('newuname')}}
                                </mat-error>
                            </mat-form-field>
                            
                        </div>
                    </form>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button color="primary"
                        [disabled]="loading" (click)="onSubmit()">
                        <mat-spinner *ngIf="loading"></mat-spinner>
                        Change
                    </button>
                </mat-card-actions>
            </mat-card>
        </mat-grid-list>
    </div>
</div>
<div class="footer">
	<a class="footer" href="https://gemdt.com" target="_top">Gem Design Technologies, Inc.</a>
</div>
