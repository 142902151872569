<button mat-icon-button class="dialog-close-button" (click)="cancel()">
    <mat-icon>close</mat-icon>
</button>
<div mat-dialog-title>
    License Manager Registration
</div>
<div mat-dialog-content>
    <form [formGroup]="form" (onSubmit)="ok()">
        <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput formControlName="email1">
            <mat-hint>Must have login account to this site</mat-hint>
            <mat-error *ngIf="fieldError('email1')">
                {{fieldError('email1')}}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput formControlName="email1">
            <mat-hint>Must have login account to this site</mat-hint>
            <mat-error *ngIf="fieldError('email2')">
                {{fieldError('email2')}}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput formControlName="email3">
            <mat-hint>Must have login account to this site</mat-hint>
            <mat-error *ngIf="fieldError('email3')">
                {{fieldError('email3')}}
            </mat-error>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="ok()" cdkFocusInitial>
        OK
    </button>
</div>
