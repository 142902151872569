
export class Gem{
  // license-full message, returned from LMD.
  static readonly license_full:string = "license-full";
  static readonly one_month = "1 month";
  static readonly three_months = "3 months";
  static readonly one_year = "1 year";
  static readonly three_years = "3 years";
  // license code fields 
  static readonly GEMSLIC = {
    GEMSLIC : 'GEMSLIC',
    HDR : 0,
    SIGVER : 1,
    PRODUCT : 2,
    FEATURE : 3,
    SEQ : 4,
    LICENSEE : 5,
    FROMDATE : 6,
    TODATE : 7,
    SUPPORT : 8,
    KEY : 9,
    SIZE : 10,
  }
  static readonly GEMCLIC = {
    GEMCLIC : 'GEMCLIC',
    HDR : 0,
    SIGVER : 1,
    PRODUCT : 2,
    FEATURE : 3,
    LICENSEE : 4,
    USER_EMAIL : 5,
    USER_SECCODE : 6,
    SERVER_URL : 7,
    KEY: 8,
    SIZE : 9
  }
  static readonly GEMSVC = {
    GEMSVC : 'GEMSVC',
    HDR : 0,
    SIGVER : 1,
    SER : 2,
    LICENSEE : 3,
    GROUP : 4,
    PRODUCT : 5,
    FEATURE : 6,
    FROMDATE : 7,
    TODATE : 8,
    SIZE : 9
  }
}
