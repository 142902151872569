import { UserDroI } from 'gemlib/dist_fe/dtodro';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators,AbstractControl, ValidationErrors } from '@angular/forms';
import { AlertService} from '@app/helper/alert.service';
import { AcctService } from '@app/acctservice/acct.service';

@Component({
    templateUrl: 'chguname.component.html',
    styleUrls:['chguname.component.css']
})
export class ChangeUnameComponent implements OnInit {
    form: FormGroup;
    userDro: UserDroI;
    loading = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private acctService: AcctService,
        private alertService: AlertService
    ){
        this.userDro = acctService.curruser;
    }
    ngOnInit() {

        const unamevalidator = function(name:AbstractControl):ValidationErrors|null{
            return new RegExp('^[a-z][a-z0-9_]{0,29}$').test(name.value)?null:{'formaterr':true};
        };

        this.form = this.formBuilder.group({
            olduname: [this.userDro.username],
            newuname: ['', [Validators.required, unamevalidator]]
        });
    }
    fieldError(field:string):string{
        if (!this.form.controls[field].errors) return '';
        if (field==='newuname'){
            if (this.form.controls[field].errors.required) return 'required';
            else return 'unacceptable format, sorry';
        }else return '';
    }
    onSubmit() {
        this.form.markAllAsTouched();
        this.alertService.clear();
        if (this.form.invalid) {
            return;
        }
        this.loading = true;
        this.acctService.change_username(this.userDro.email,this.form.controls.olduname.value,
            this.form.controls.newuname.value)
            .subscribe(
                () => {
                    this.loading = false;
                    this.router.navigate(['/setting/profile']);
                },
                (error:HttpErrorResponse|Error) => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
}
