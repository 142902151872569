import { UserDroI } from 'gemlib/dist_fe/dtodro';
import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import { AcctService } from '@app/acctservice/acct.service';
@Component({
    selector: 'header2',
    templateUrl: 'header2.component.html',
    styleUrls:['header2.component.css']
})
export class Header2Component{
    curruser:UserDroI;
    @Input('file-path-str') filePathStr:string = "";
    constructor(
        private acctService: AcctService,
        private router:Router
    ){
        this.acctService.curruser_obse.subscribe(x => this.curruser = x);
    }
    logout() {
        this.acctService.logout();
        this.router.navigate(['/loggedout']);
    }
}