<header2 [file-path-str]="filepath_str"></header2>
<div class="page-body">
	<mat-spinner *ngIf="loading"></mat-spinner>
	<mat-toolbar class="ggv-toolbar1">
		<button mat-button disableRipple>
			<mat-icon>star</mat-icon>
			G-Viewer
		</button>
		<mat-button-toggle-group multiple>
			<mat-button-toggle [checked]="see_subst" (change)="see_subst=!see_subst"
				matTooltip=".board_geom section">
				.board_geom
			</mat-button-toggle>
			<mat-button-toggle [checked]="see_comp" (change)="see_comp=!see_comp"
				matTooltip=".component section">
				.component
			</mat-button-toggle>
			<mat-button-toggle [checked]="see_pinpos" (change)="see_pinpos=!see_pinpos"
				matTooltip="pin position in .component">
				pinpos
			</mat-button-toggle>
			<mat-button-toggle [checked]="see_routing" (change)="see_routing=!see_routing"
				matTooltip=".route section">
				.route
			</mat-button-toggle>
			<mat-button-toggle [checked]="see_via" (change)="see_via=!see_via"
				matTooltip="via data in .route">
				via
			</mat-button-toggle>
			<mat-button-toggle [checked]="see_viapos" (change)="see_viapos=!see_viapos"
				matTooltip="via position in via data">
				viapos
			</mat-button-toggle>

		</mat-button-toggle-group>
		<button mat-button disableRipple matTooltip="current layer">
			layer {{currlayer}} : {{currlayername}}
		</button>
		<span class="spacer"></span>

		<mat-form-field class="search-text">
			<mat-label>Search</mat-label>
			<input matInput type="text" [(ngModel)]="searchtext">
			<button mat-button matSuffix mat-icon-button (click)="search_text()">
				<mat-icon>search</mat-icon>
			</button>
		</mat-form-field>
		<button mat-icon-button (click)="search_next()" matTooltip="next">
			<mat-icon>keyboard_arrow_down</mat-icon>
		</button>
		<button mat-icon-button (click)="search_prev()" matTooltip="previous">
			<mat-icon>keyboard_arrow_up</mat-icon>
		</button>
		<button mat-button disableRipple matTooltip="mouse pos" class="coord-field">
			{{coordstr}}
		</button>
	</mat-toolbar>
	<mat-toolbar class="ggv-toolbar2">
		<!-- mouse mode -->
		<mat-button-toggle-group [value]="mouse_mode" (change)="mouse_mode=$event.value">
			<mat-button-toggle value="select" matTooltip="drag mouse for selection">
				<mat-icon>north_west</mat-icon>
			</mat-button-toggle>
			<mat-button-toggle value="pan" matTooltip="drag mouse for pan">
				<mat-icon>pan_tool</mat-icon>
			</mat-button-toggle>
			<mat-button-toggle value="zoom" matTooltip="drag mouse for zoom">
				<mat-icon>crop</mat-icon>
			</mat-button-toggle>
		</mat-button-toggle-group>

		<mat-divider vertical></mat-divider>

		<!-- zoom -->
		<button mat-icon-button matTooltip="view fit (F, Home)"
			(click)="view_fit()">
			<mat-icon>zoom_out_map</mat-icon>
		</button>
		<button mat-icon-button matTooltip="zoom in (Z, wheel-down, double-click)"
			(click)="view_zoomin()">
			<mat-icon>zoom_in</mat-icon>
		</button>
		<button mat-icon-button matTooltip="zoom out (X, wheel-up)"
			(click)="view_zoomout()">
			<mat-icon>zoom_out</mat-icon>
		</button>

		<mat-divider vertical></mat-divider>

		<!-- pan -->
		<button mat-icon-button matTooltip="pan left (left-arrow)"
			(click)="pan_left()">
			<mat-icon>arrow_back</mat-icon>
		</button>
		<button mat-icon-button matTooltip="pan bottom (bottom-arrow)"
			(click)="pan_bottom()">
			<mat-icon>arrow_downward</mat-icon>
		</button>
		<button mat-icon-button matTooltip="pan top (top-arrow)"
			(click)="pan_top()">
			<mat-icon>arrow_upward</mat-icon>
		</button>
		<button mat-icon-button matTooltip="pan right(right-arrow)"
			(click)="pan_right()">
			<mat-icon>arrow_forward</mat-icon>
		</button>

		<mat-divider vertical></mat-divider>

		<!-- clear selection -->
		<button mat-icon-button matTooltip="clear selection (DEL)"
			(click)="clear_selection()">
			<mat-icon>clear</mat-icon>
		</button>

		<mat-divider vertical></mat-divider>

		<!-- current layer -->
		<button mat-icon-button matTooltip="go to top layer"
			(click)="goto_toplay()">
			<mat-icon>keyboard_capslock</mat-icon>
		</button>
		<button mat-icon-button matTooltip="go to upper layer"
			(click)="goto_upperlay(1)">
			<mat-icon>keyboard_arrow_up</mat-icon>
		</button>
		<button mat-icon-button matTooltip="go to lower layer"
			(click)="goto_lowerlay(1)">
			<mat-icon>keyboard_arrow_down</mat-icon>
		</button>
		<button mat-icon-button matTooltip="goto bottom layer"
			(click)="goto_bottomlay()">
			<mat-icon style="transform: rotate(180deg)">keyboard_capslock</mat-icon>
		</button>
		<mat-slider value="100" (change)="alpha_curr=$event.value"
			matTooltip="current layer brightness">
		</mat-slider>

		<mat-divider vertical></mat-divider>

		<!-- other layers -->
		<button mat-icon-button matTooltip="other layer visibility" (click)="see_otherlays()">
			<mat-icon>layers</mat-icon>
		</button>
		<mat-button-toggle-group multiple>
			<mat-button-toggle [checked]="see_farupperlay" (change)="see_farupperlay=!see_farupperlay" matTooltip="see far upper layers">
				<mat-icon style="transform:rotate(-90deg)">fast_forward</mat-icon>
			</mat-button-toggle>
			<mat-button-toggle [checked]="see_upperlay" (change)="see_upperlay=!see_upperlay" matTooltip="see upper layer">
				<mat-icon style="transform:rotate(-90deg)">play_arrow</mat-icon>
			</mat-button-toggle>
			<mat-button-toggle [checked]="see_lowerlay" (change)="see_lowerlay=!see_lowerlay" matTooltip="see lower layer">
				<mat-icon style="transform:rotate(90deg)">play_arrow</mat-icon>
			</mat-button-toggle>
			<mat-button-toggle [checked]="see_farlowerlay" (change)="see_farlowerlay=!see_farlowerlay" matTooltip="see far lower layers">
				<mat-icon style="transform:rotate(90deg)">fast_forward</mat-icon>
			</mat-button-toggle>
		</mat-button-toggle-group>
		<mat-slider value="20" (change)="alpha_noncurr=$event.value"
			matTooltip="other layers brightness">
		</mat-slider>

		<mat-divider vertical></mat-divider>

		<!-- text data -->
		<mat-button-toggle-group multiple>
			<mat-button-toggle [checked]="see_objinfo" (change)="see_objinfo=!see_objinfo" matTooltip="selected obj's info">
				INFO
			</mat-button-toggle>
			<mat-button-toggle [checked]="see_compname" (change)="see_compname=!see_compname" matTooltip="component name">
				IC1
			</mat-button-toggle>
			<mat-button-toggle [checked]="see_pinname" (change)="see_pinname=!see_pinname" matTooltip="pin name">
				P1
			</mat-button-toggle>
			<mat-button-toggle [checked]="see_netname" (change)="see_netname=!see_netname" matTooltip="net name">
				SIG1
			</mat-button-toggle>
			<mat-button-toggle [checked]="see_lineno" (change)="see_lineno=!see_lineno" matTooltip="line number">
				123
			</mat-button-toggle>
		</mat-button-toggle-group>

	</mat-toolbar>
	<div class="ggv-layvisbar-n-canvas">
		<div class="ggv-layvisbar">
			<div>
				<button mat-button (click)="set_alllayvis()">
					Layers
				</button>
			</div>
			<mat-button-toggle-group value="0" vertical (change)="goto_layer($event.value)">
				<div class="ggv-layvisgrid">
					<ng-container *ngFor="let layer of layers; let idx = index;">
						<mat-checkbox
							[checked]="layer.visible"
							(change)="layer.visible=!layer.visible">
						</mat-checkbox>
						<mat-button-toggle
							[checked]="currlayer==idx+1"
							[value]="idx+1">
							{{idx+1}} : {{layer.name}}
						</mat-button-toggle>
					</ng-container>
				</div>
			</mat-button-toggle-group>
		</div>
		<div class="ggv-netvisbar">
			<div>
				<button mat-button (click)="set_allnetvis()">
					Nets
				</button>
			</div>
			<mat-button-toggle-group multiple>
				<div class="ggv-netvisgrid">
					<ng-container *ngFor="let net of nets; let idx = index;">
						<mat-button-toggle
							[checked]="net.visible"
							(change)="net.visible=!net.visible">
							{{net.name}}
						</mat-button-toggle>
					</ng-container>
				</div>
			</mat-button-toggle-group>
		</div>
		<div class="ggv-canvas-par">
			<canvas id="ggv_canvas">
			</canvas>
		</div>
	</div>
</div>
<div class="footer">
	<a class="footer" href="https://gemdt.com" target="_top">Gem Design Technologies, Inc.</a>
</div>
