import { GetNewsDroI } from 'gemlib/dist_fe/dtodro';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AcctService } from '@app/acctservice/acct.service';
import { AlertService} from '@app/helper/alert.service';
import { DataService } from '@app/dataservice/data.service';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit {
    form: FormGroup;
    loading = false;
    news:GetNewsDroI = {news:[]};


    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private acctService: AcctService,
        private dataService: DataService,
        private alertService: AlertService
    ) {
        // redirect to explorer if already logged in
        if (this.acctService.curruser) {
            this.router.navigate(['/explorer']);
        }
    }
    ngOnInit() {
        this.dataService.get_news().subscribe((news)=>{this.news = news;});
        this.form = this.formBuilder.group({
            email: [this.route.snapshot.queryParams['email']||'',
                [Validators.required,Validators.email]],
            password: ['', Validators.required],
        });
    }
    fieldError(field:string):string{
        if (field==='email'){
            if (this.form.controls.email.errors?.required) return 'required';
            else if (this.form.controls.email.errors) return 'doesn\'t seem like an email address';
        }else if (field==='password'){
            if (this.form.controls.password.errors) return 'required';
        }
        return '';
    }



    ngOnSubmit() {
        this.form.markAllAsTouched();
            // let 'required' message appear in un-touched fields.
        this.alertService.clear();
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.acctService.login(this.form.controls.email.value, this.form.controls.password.value)
            .subscribe(
                () => {
                    this.loading = false;
                    this.router.navigate(['/explorer']);
                },
                (error:HttpErrorResponse|Error) => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
    onForgotPassword(){
        this.router.navigate(['/forgotpw1'],{ queryParams: { email: this.form.controls.email.value }});
    }
    onRegister(){
        this.router.navigate(['/register1'],{ queryParams: { email: this.form.controls.email.value }});
    }
}
