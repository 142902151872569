import { RegUserI, LicenseeStatusDtoI, LicenseeStatusDroI,
    MakeNewLicenseeDtoI, ReviseEndUserDtoI, ReviseLicCodeDtoI,
    IssueCLicCodeDtoI, IssueCLicCodeDroI, ReviseLicMgrDtoI, LicMgrI,
    ReportUsageDtoI, ReportUsageDroI} from 'gemlib/dist_fe/dtodro';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class LicService{
    constructor(private http: HttpClient){
    }
    licensee_status(licmgr_email:string):Observable<LicenseeStatusDroI[]>{
        const dto:LicenseeStatusDtoI = {licmgr_email: licmgr_email};
        return this.http.post<LicenseeStatusDroI[]>(environment.apiUrl+"/lmd/licensee_status",dto);
    }
    make_newlicensee(licmgr_email:string, server_liccode:string):Observable<void>{
        const dto:MakeNewLicenseeDtoI = {licmgr_email: licmgr_email, server_liccode: server_liccode};
        return this.http.post<void>(environment.apiUrl+"/lmd/make_newlicensee",dto);
    }
    revise_liccode(licmgr_email:string, licensee:string, server_liccode:string):Observable<void>{
        const dto:ReviseLicCodeDtoI = {licmgr_email: licmgr_email, licensee: licensee, server_liccode: server_liccode};
        return this.http.post<void>(environment.apiUrl+"/lmd/revise_liccode",dto);
    }
    revise_enduser(licmgr_email:string, licensee:string, regusers:RegUserI[]):Observable<void>{
        const dto:ReviseEndUserDtoI = {licmgr_email: licmgr_email, licensee: licensee, regusers:regusers};
        return this.http.post<void>(environment.apiUrl+"/lmd/revise_enduser",dto);

    }
    issue_cliccode(licmgr_email:string, licensee:string, regusers:RegUserI[]):Observable<IssueCLicCodeDroI>{
        const dto:IssueCLicCodeDtoI = {licmgr_email: licmgr_email, licensee: licensee, regusers:regusers};
        return this.http.post<IssueCLicCodeDroI>(environment.apiUrl+"/lmd/issue_cliccode",dto);
    }
    revise_licmgr(licmgr_email:string, licensee:string, licmgrs:LicMgrI[]):Observable<void>{
        const dto:ReviseLicMgrDtoI = {licmgr_email: licmgr_email, licensee: licensee, licmgrs:licmgrs};
        return this.http.post<void>(environment.apiUrl+"/lmd/revise_licmgr",dto);
    }
    report_usage(licmgr_email:string, licensee:string):Observable<ReportUsageDroI>{
        const dto:ReportUsageDtoI = {licmgr_email: licmgr_email, licensee: licensee};
        return this.http.post<ReportUsageDroI>(environment.apiUrl+'/lmd/report_usage',dto);
    }
}