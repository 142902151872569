<header1></header1>
<div class="page-body">
    <div class="page-container-medium">
        <mat-grid-list cols="1">
            <mat-card>
                <mat-card-title>
                    <mat-icon>shopping_cart</mat-icon>
                    Disk Quota Plan
                </mat-card-title>
                <mat-card-content>
                    <div style="padding:3px;">
                        <h3>
                            Disk Quota Plan
                            <span class="mat-caption">(w/o tax)</span>
                        </h3>
                        <div style="margin-left:2rem;">
                            <table mat-table [dataSource] = "quotaPlans" class="mat-elevation-z8">
                                <ng-container matColumnDef="planDescr">
                                    <mat-radio-group [(ngModel)]="seldQuotaPlan" >
                                        <td mat-cell *matCellDef="let plan;">
                                            <mat-radio-button [value]="plan">
                                                {{plan.planDescr}}
                                            </mat-radio-button>
                                        </td>
                                    </mat-radio-group>
                                </ng-container>
                                <ng-container matColumnDef="priceDescr">
                                    <td mat-cell *matCellDef="let plan;">
                                        {{plan.priceDescr}}
                                    </td>
                                </ng-container>
                                <tr mat-row *matRowDef="let row; columns: quotaPlanTableCols;"></tr>"
                            </table>
                        </div>
                    </div>
                    <div style="padding:3px;">
                        <h3>
                            Period
                        </h3>
                        <div style="margin-left:2rem;">
                            <table mat-table [dataSource] = "periodPlans" class="mat-elevation-z8">
                                <ng-container matColumnDef="planDescr">
                                    <mat-radio-group [(ngModel)]="seldPeriodPlan">
                                        <td mat-cell *matCellDef="let plan;">
                                            <mat-radio-button [value]="plan">
                                                {{plan.planDescr}}
                                            </mat-radio-button>
                                        </td>
                                    </mat-radio-group>
                                </ng-container>
                                <tr mat-row *matRowDef="let row; columns: periodPlanTableCols;"></tr>"
                            </table>
                        </div>
                    </div>
                    <div style="padding:3px;">
                        <h3>
                            Select a group to which the plan will be applied
                        </h3>
                        <div style="margin-left:2rem">
                            <mat-radio-group [(ngModel)]="fornewgroup">
                                <mat-radio-button [value]="true">For new group</mat-radio-button>
                                <mat-radio-button [value]="false">For existing group</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div style="margin-left:2rem; margin-top:0.5rem;">
                            <div *ngIf="fornewgroup">
                                <form [formGroup]="newgroupform" (ngOnSubmit)="contactUsPressed()">
                                    <div>
                                        <mat-form-field style="width:80%;">
                                            <mat-label>Group Name</mat-label>
                                            <input matInput type="text" 
                                            formControlName="newgroupname" >
                                            <mat-hint>ex: gemdt, GemDT, Gem_DT, gemdt_01</mat-hint>
                                            <mat-error *ngIf="fieldError('newgroupname')">
                                                {{fieldError('newgroupname')}}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </form>
                            </div>
                            <div *ngIf="!fornewgroup">
                                <group-table (change)="seldGroup=$event;">
                                </group-table>
                            </div>
                        </div>
                    </div>
                    <div style="padding:3px; margin-top:1rem;">
                        <div style="margin-left:2rem">
                            <button mat-raised-button [disabled]="!seldQuotaPlan" color="primary" (click)="contactUsPressed()">
                                Contact Gem Design Tech
                            </button>
                            <div style="margin-left:2em;">
                                <p>
                                    The above button will send an email from the system to you and to us.
                                    We will respond to you asap, so that you can place a purchase order.
                                </p>
                            </div>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div style="padding:3px;">
                        <h3>
                            Activate Service Code
                        </h3>
                        <div style="margin-left:2rem">
                            <div style="width:100%">
                                <mat-form-field style="width:90%">
                                    <mat-label>Service Code</mat-label>
                                    <textarea matInput #code></textarea>
                                </mat-form-field>
                            </div>
                            <button mat-raised-button color="primary" (click)="activate(code.value)">
                                Activate
                            </button>
                            <div style="margin-left:1rem">
                                <p>
                                    When you purchase a disk quota plan,
                                    you will receive a 'service code' from us in an email.
                                    You can paste that code here to activate the new quota plan.
                                </p>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </mat-grid-list>
    </div>
</div>
<div class="footer">
	<a class="footer" href="https://gemdt.com" target="_top">Gem Design Technologies, Inc.</a>
</div>
