<mat-toolbar class="header">
    <img class="header-brand-logo" src="./assets/gem_500x500.svg">
    <div>
        <span class="header-brand-title">gem-lpb.com</span>
    </div>
    <button mat-button disableRipple>
        <mat-icon>person</mat-icon>
        {{curruser.username}}({{curruser.email}})
    </button>
    <button mat-button [matMenuTriggerFor]="homeMenu" matTooltip="current folder">
        <mat-icon>folder</mat-icon>
        {{currdirpath}}
    </button>
    <mat-menu #homeMenu="matMenu">
        <buttoon mat-menu-item (click)="chdir('/home')">
            <mat-icon>person</mat-icon>
            /home
        </buttoon>
        <ng-container *ngIf="curruser.pref?.autodir?.projs">
            <button mat-menu-item (click)="chdir('/home/'+curruser.pref.autodir.projs)">
                <mat-icon>person</mat-icon>
                /home/{{curruser.pref.autodir.projs}}
            </button>
        </ng-container>
        <ng-container *ngFor="let grp of groupfents;">
            <button mat-menu-item (click)="chdir('/groups/'+grp.name)">
                <mat-icon>people</mat-icon>
                /groups/{{grp.name}}
            </button>
        </ng-container>
    </mat-menu>

    <span class="toolbar-spacer"></span>

    <button mat-button (click)="ggv_pressed()">
        <mat-icon>star</mat-icon>
        G-Viewer
    </button>
    <button mat-button (click)="gck_pressed()">
        <mat-icon>star</mat-icon>
        Fmt-Checker
    </button>

    <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="apps">
        <mat-icon>apps</mat-icon>
    </button>
    <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="ggv_pressed()">
            <mat-icon>star</mat-icon>
            G-Viewer
        </button>
        <button mat-menu-item (click)="gck_pressed()">
            <mat-icon>star</mat-icon>
            Fmt-Checker
        </button>
    </mat-menu>
    <button mat-button matTooltip="{{quota_tooltip}}"
        [matMenuTriggerFor]="diskMenu"
        [color]="quota_used_percent>80?'warn':'undefined'">
        <mat-icon>storage</mat-icon>
        {{quota_info}}
    </button>
    <mat-menu #diskMenu="matMenu">
        <button mat-button (click)="chdir('/home')">
            <mat-icon>home</mat-icon>
            Home
        </button>
        <div *ngFor="let grp of groupfents">
            <button mat-button (click)="chdir('/groups/'+grp.name)">
                <mat-icon>people</mat-icon>
                {{grp.name}}
            </button>
        </div>
    </mat-menu>
    <span style="width:3rem;">
        <mat-progress-bar [value]="quota_used_percent"
        [color]="quota_used_percent>80?'warn':'primary'"
        style="width:3rem; height:0.8rem;">
        </mat-progress-bar>
    </span>
    <button mat-icon-button [matMenuTriggerFor]="shoppingMenu"
        matTooltip="shopping">
        <mat-icon>shopping_cart</mat-icon>
    </button>
    <mat-menu #shoppingMenu="matMenu">
        <buttoon mat-menu-item routerLink="/shopping/quota">
            <mat-icon>storage</mat-icon>
            Disk Quota
        </buttoon>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/shopping/gemlpbapp">
            <mat-icon>apps</mat-icon>
            GemLPB
        </button>
    </mat-menu>
    <button mat-icon-button [matMenuTriggerFor]="settingMenu"
        matTooltip="setting">
        <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #settingMenu="matMenu">
        <button mat-menu-item routerLink="/setting/profile">
            <mat-icon>person</mat-icon>
            Profile
        </button>
        <button mat-menu-item routerLink="/setting/preference">
            <mat-icon>settings</mat-icon>
            Preference
        </button>
        <button mat-menu-item routerLink="/setting/mnggroup">
            <mat-icon>people</mat-icon>
            Manage Group
        </button>
        <button mat-menu-item routerLink="/setting/mnglic">
            <mat-icon>star</mat-icon>
            Manage License
        </button>
    </mat-menu>
    <button mat-icon-button (click)="logout()" matTooltip="logout">
        <mat-icon>exit_to_app</mat-icon>
    </button>
</mat-toolbar>

<!-- alert -->
<alert></alert>

<!-- command bar -->
<div class="page-body">
    <div class="page-container-medium">
        <mat-toolbar class="command-toolbar mat-elevation-z8">
            <button mat-stroked-button disableRipple>
                <mat-icon>folder</mat-icon>
                Explorer
            </button>
    
            <button mat-icon-button matTooltip="cut" (click)="cut_pressed()">
                <mat-icon>content_cut</mat-icon>
            </button>
            <button mat-icon-button matTooltip="copy" (click)="copy_pressed()">
                <mat-icon>content_copy</mat-icon>
            </button>
            <button mat-icon-button matTooltip="paste" (click)="paste_pressed()">
                <mat-icon>content_paste</mat-icon>
            </button>
    
            <mat-divider vertical></mat-divider>
    
            <button mat-icon-button matTooltip="clone" (click)="clone_pressed()">
                <mat-icon>file_copy</mat-icon>
            </button>
            <button mat-icon-button matTooltip="rename" (click)="rename_pressed()">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button matTooltip="mkdir" (click)="mkdir_pressed()">
                <mat-icon>create_new_folder</mat-icon>
            </button>
    
            <mat-divider vertical></mat-divider>
    
            <span *ngIf="loading">
                <mat-spinner [diameter]="20"></mat-spinner>
            </span>
            <span *ngIf="!loading">
                <button mat-icon-button (click)="refresh_pressed()" matTooltip="refresh">
                    <mat-icon>refresh</mat-icon>
                </button>
            </span>
            
            <mat-divider vertical></mat-divider>
    
            <button mat-icon-button matTooltip="upload" (click)="upload_pressed()">
                <mat-icon>cloud_upload</mat-icon>
            </button>
            <button mat-icon-button matTooltip="download" (click)="download_pressed()">
                <mat-icon>cloud_download</mat-icon>
            </button>
            <button *ngIf="curruser.pref?.autodir?.projs && curruser.pref?.autodir?.backup"
                mat-icon-button matTooltip="backup" (click)="backup_pressed()">
                <mat-icon>library_add</mat-icon>
            </button>
            
            <span class="toolbar-spacer"></span>
    
            <mat-divider vertical></mat-divider>
            <button *ngIf="is_trash && !now_in_trash"
                mat-icon-button matTooltip="move to trash" (click)="delete_pressed()">
                <mat-icon>delete</mat-icon>
            </button>
            <button *ngIf="!is_trash || now_in_trash"
                mat-icon-button matTooltip="DELETE" (click)="delete_pressed()">
                <mat-icon color="warn">delete</mat-icon>
            </button>
        </mat-toolbar>
        <mat-toolbar class="command-toolbar mat-elevation-z8">
            <span class="toolbar-spacer2"></span>
            <button mat-icon-button (click)="chdir('..')"
                matTooltip="to upper dir">
                <mat-icon>arrow_upward</mat-icon>
            </button>
            <button mat-button [matMenuTriggerFor]="homeMenu" matTooltip="current folder">
                <mat-icon>folder</mat-icon>
                {{currdirpath}}
            </button>
        </mat-toolbar>
        <!-- local file selector is used in upload, but hidden because its ugly. -->
        <div>
            <input type="file" #hidden
            id="hidden"
            name="hidden"
            multiple="multiple" style="display:none;" />
        </div>
        <!-- upload/down progress bars -->
        <div class="upload-download-progress-bars">
            <div *ngFor="let fwrap of fwraps">
                <div *ngIf="fwrap.inprogress">
                    <div>
                        {{fwrap.fname}} {{fwrap.progress}}% complete
                    </div>
                    <div>
                        <mat-progress-bar [value]="fwrap.progress" color="primary">
                        </mat-progress-bar>
                    </div>
                </div>
                <div *ngIf="!fwrap.inprogress && !fwrap.error">
                    <div>
                        {{fwrap.fname}} completed.
                    </div>
                    <div>
                        <mat-progress-bar [value]="100" color="primary">
                        </mat-progress-bar>
                    </div>
                </div>
                <div *ngIf="!fwrap.inprogress && fwrap.error">
                    <div color="warn">
                        {{fwrap.fname}} Error: {{fwrap.error}}
                    </div>
                    <div>
                        <mat-progress-bar [value]="100" color="warn">
                        </mat-progress-bar>
                    </div>
                </div>
            </div>
        </div>
        <!-- table of file entries in current directory -->
        <table *ngIf="currdir" mat-table [dataSource]="currdir.children" 
            matSort (matSortChange)="matSortChange($event)"
            class="mat-elevation-z8">
            <ng-container matColumnDef="size">
                <th mat-header-cell *matHeaderCellDef 
                    mat-sort-header="size"
                    class="cell-align-right">
                    size(KB)
                </th>
                <td mat-cell *matCellDef="let fent" class="cell-align-right">
                    {{fent.sizekbstr}}
                </td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef
                    mat-sort-header="date"
                    >
                    modified
                </th>
                <td mat-cell *matCellDef="let fent">
                    {{fent.modified_yymmdd_hhmm}}
                </td>
            </ng-container>
            <ng-container matColumnDef="check">
                <th mat-header-cell *matHeaderCellDef class="cell-align-right">
                    <mat-checkbox
                        [checked]="allchecked"
                        [indeterminate]="somechecked"
                        (change)="sel_pressed($event.checked)">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let fent" class="cell-align-right">
                    <mat-checkbox
                        [checked]="fent.checked"
                        (change)="fent.checked=!fent.checked">
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef
                    mat-sort-header="name"
                    >
                    name
                </th>
                <td mat-cell *matCellDef="let fent">
                    <div *ngIf="fent.type==='D'; then dircase else leafcase">
                    </div>
                    <ng-template #dircase>
                        <ng-container *ngIf="incutbuf(fent.name)">
                            <button mat-button
                                (click)="chdir(fent.name);$event.stopPropagation();">
                                <b>{{fent.name}}/</b>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="!incutbuf(fent.name)">
                            <button mat-button
                                (click)="chdir(fent.name);$event.stopPropagation();">
                                {{fent.name}}/
                            </button>
                        </ng-container>
                    </ng-template>
                    <ng-template #leafcase>
                        <ng-container *ngIf="incutbuf(fent.name)">
                            <button mat-button
                                (click)="fent.checked=!fent.checked;$event.stopPropagation();">
                                <b>{{fent.name}}</b>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="!incutbuf(fent.name)">
                            <button mat-button
                                (click)="fent.checked=!fent.checked;$event.stopPropagation();">
                                {{fent.name}}
                            </button>
                        </ng-container>
                    </ng-template>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="fentTableCols"></tr>
            <tr mat-row *matRowDef="let fent; columns: fentTableCols;"></tr>
        </table>
    </div>
</div>
<div class="footer">
	<a class="footer" href="https://gemdt.com" target="_top">Gem Design Technologies, Inc.</a>
</div>
