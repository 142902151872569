import { UserDroI, LicenseeStatusDroI, RegUserI,
    IssueCLicCodeDroI, ReportUsageDroI } from 'gemlib/dist_fe/dtodro';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AlertService} from '@app/helper/alert.service';
import { AcctService } from '@app/acctservice/acct.service';
import { LicService } from '@app/licservice/lic.service';
import { Util } from '@app/helper/util';
import { MatDialog } from '@angular/material/dialog';
import { RegUserDialogComponent, RegUserDialogI } from './reguserdialog.component';
import { RegLicMgrDialogComponent, RegLicMgrDialogI } from './reglicmgrdialog.component';
import { HttpEventType } from '@angular/common/http';
import { saveAs } from 'file-saver-es';

class LicenseeInfo{
    licensee:string = '';
    status: LicenseeStatusDroI = null;
    get eff_lic_cnt():number{
        if (!this.status) return 0;
        let cnt = 0;
        for(let slic of this.status.server_lics){
            if (!slic.problem) cnt++;
        }
        return cnt;
    }
    get reg_enduser_cnt():number{
        if (!this.status) return 0;
        return this.status.regusers.length;
    }
    get online_enduser_cnt():number{
        if (!this.status) return 0;
        return this.status.online_users.length;
    }
    get licmgr_cnt():number{
        if (!this.status) return 0;
        return this.status.licmgrs.length;
    }
    constructor(licensee:string, statuss:LicenseeStatusDroI[]){
        for(let status of statuss){
            if (status.licensee === licensee){
                this.licensee = licensee;
                this.status = status;
                return;
            }
        }
    }
    // this.status is left null.
}

@Component({
    templateUrl: 'mnglic.component.html',
    styleUrls:['mnglic.component.css']
})
export class ManageLicenseComponent implements OnInit {
    curruser: UserDroI;
    readonly have_liccode = "I have new license code to manage.";
                                                
    // all license info of the licensees that current user manages.
    licenseeLicInfos: LicenseeStatusDroI[] = [];

    // licensee table
    licensees: string[] = [];
    licenseeTableCols=['licensee'];
    seldLicensee: string = this.have_liccode; // maintained in template

    private _licenseeInfo: LicenseeInfo = null;
    get licenseeInfo():LicenseeInfo{
        if (!this._licenseeInfo || this._licenseeInfo.licensee!==this.seldLicensee)
            this._licenseeInfo = new LicenseeInfo(this.seldLicensee,this.licenseeLicInfos);
        return this._licenseeInfo
    }

    // new liccode input when seldLicensee = "I have a license code to manage."
    server_liccode:string = "";
    server_liccode_error:string = "";

    // Server License Table
    slicTableCols=['product','seq','first-day','last-day','support','licensee','key','problem'];

    // Registered User table
    reguserTableCols=['product','email','seccode','key'];

    // License Manager Table
    licmgrTableCols=['email'];

    constructor(
        private fb: FormBuilder,
        private alertService: AlertService,
        private acctService: AcctService,
        private licService: LicService,
        private dialogFactory: MatDialog,
        ){
            this.curruser = acctService.curruser;
        }
    ngOnInit() {
        this.licService.licensee_status(this.curruser.email).subscribe((dro:LicenseeStatusDroI[])=>{
            // collect necessary info of all licensees under his management.
            // i.e. license file, server licenses, client licenses, online users, managers.
            // statistics info is not collected, which can be shown in end-user tool.
            this.licenseeLicInfos = dro;
            this.licensees = dro.map(x=>{return x.licensee;});
            this.licensees.push(this.have_liccode);
            this.seldLicensee = this.licensees.length>0? this.licensees[0]: this.have_liccode;
            this._licenseeInfo = null; // to force licinfo getter to refresh 
        },(err:Error)=>{
            this.alertService.error(err);
            console.log('error returned from licservice.find_licensees()')
        },()=>{
        });
    }
    makeNewLicensee(){
        this.server_liccode_error = "";
        if (!this.server_liccode){
            this.server_liccode_error = "license code cannot be omitted";
            return;
        }
        this.licService.make_newlicensee(this.curruser.email,this.server_liccode)
        .subscribe(()=>{
            this.ngOnInit();
        },(err:Error)=>{
            this.server_liccode_error = Util.err2msg(err);
        });
    }
    reviseLicFile(){
        this.server_liccode_error = "";
        this.licService.revise_liccode(this.curruser.email,this.seldLicensee,this.licenseeInfo.status.server_liccode)
        .subscribe(()=>{
            this.alertService.success('license code revised. page reload maybe needed.');
            this.ngOnInit();
        },(err:Error)=>{
            this.server_liccode_error = Util.err2msg(err);
        });
    }
    get regenduser_allchecked():boolean{
        return this.licenseeInfo.status?.regusers.every(user=>user.checked);
    }
    get regenduser_somechecked():boolean{
        return this.licenseeInfo.status?.regusers.filter(user=>user.checked).length>0 &&
            !this.regenduser_allchecked;
    }
    regenduser_sel_pressed(b:boolean):void{
        this.alertService.clear();
        this.licenseeInfo.status?.regusers.forEach(user=>user.checked = b);
    }
    addEndUser(){
        this.alertService.clear();
        const model = <RegUserDialogI>{
            product: '',
            product_opts: [],
            email: '',
            seccode: '',
            // hostname_re: '',
            // ipaddr_re: ''
        }
        for(let clic of this.licenseeInfo.status.server_lics){
            const product = clic.product+'/'+clic.feature;
            if (!model.product_opts.includes(product)){
                model.product_opts.push(product);
            }
            if (!model.product) model.product = product;
        }
        if (model.product_opts.length<=0){
            model.product = 'gempkg/pro';
            model.product_opts.push(model.product);
        }
        const dialog = this.dialogFactory.open(RegUserDialogComponent,{
            width: '400px', data: model});
        dialog.afterClosed().subscribe((model:RegUserDialogI|null)=>{
            if (!model) return;
            if (!model.email) return;
            const product_feature = model.product.split('/');
            if (product_feature.length<2) return;
            this.licenseeInfo.status.regusers.push(<RegUserI>{
                product: product_feature[0],
                feature: product_feature[1],
                email: model.email,
                seccode: model.seccode,
                // hostname_re: model.hostname_re,
                // ipaddr_re: model.ipaddr_re
            });
            this.licService.revise_enduser(this.curruser.email,this.seldLicensee,this.licenseeInfo.status.regusers)
            .subscribe(()=>{
                this.alertService.success('user registered. page reload maybe needed.');
                this.ngOnInit();
            },(err:Error)=>{
                this.alertService.error(err);
            });
        });
    }
    private key2type_n_value(key:string):[string,string]{
        if (!key) return ['anypc','']; // key introduced 2021-06-04
        else if (key==='anypc') return ['anypc',''];
        else if (key.startsWith('host:')) return ['host',key.substring('host:'.length)];
        else if (key.startsWith('mac:')) return ['mac',key.substring('mac:'.length)];
        else return ['anypc',''];
    }
    editEndUser(){
        this.alertService.clear();
        const model:RegUserDialogI = {
            product: '',
            product_opts: [],
            email: '',
            keytype: '',
            keyvalue: '',
            seccode: null,
            // hostname_re: null,
            // ipaddr_re: null
        }
        for(let user of this.licenseeInfo.status.regusers){
            if (!user.checked) continue;
            const product = user.product+'/'+user.feature;
            if (!model.product_opts.includes(product)){
                model.product_opts.push(product);
            }
            if (!model.product) model.product = product;
            else if (model.product!=='*'&&model.product!==product){
                model.product = '*';
                model.product_opts.push('*');
            }
            if (!model.email) model.email = user.email;
            else if (model.email!=='*'&&model.email!==user.email) model.email = '*';
            const [keytype,keyvalue] = this.key2type_n_value(user.key);
            if (!model.keytype){
                model.keytype = keytype;
                model.keyvalue = keyvalue;
            }else if (model.keytype!=='*'&&model.keytype!==keytype){
                model.keytype = '*';
                if (model.keyvalue!==keyvalue) model.keyvalue = '*';
            }else if (model.keyvalue!=='*'&&model.keyvalue!==keyvalue){
                model.keyvalue = '*';
            }
            if (model.seccode==null) model.seccode = user.seccode;
            else if (model.seccode!=='*'&&model.seccode!==user.seccode) model.seccode = '*';
            // if (model.hostname_re==null) model.hostname_re = user.hostname_re;
            // else if (model.hostname_re!=='*'&&model.hostname_re!==user.hostname_re) model.hostname_re = '*';
            // if (model.ipaddr_re==null) model.ipaddr_re = user.ipaddr_re;
            // else if (model.ipaddr_re!=='*'&&model.ipaddr_re!==user.ipaddr_re) model.ipaddr_re = '*';
        }
        if (!model.product) return; // no user selected.

        for(let slic of this.licenseeInfo.status.server_lics){
            const product = slic.product+'/'+slic.feature;
            if (!model.product_opts.includes(product)){
                model.product_opts.push(product);
            }
            if (!model.product) model.product = product;
        }
        if (model.product_opts.length<=0){
            model.product = 'gempkg/pro';
            model.product_opts.push(model.product);
        }
        if (!model.seccode) model.seccode = '';
        // if (!model.hostname_re) model.hostname_re = '';
        // if (!model.ipaddr_re) model.ipaddr_re = '';
        const dialog = this.dialogFactory.open(RegUserDialogComponent,{
            width: '400px', data: model});
        dialog.afterClosed().subscribe((model:RegUserDialogI|null)=>{
            if (!model) return;
            for(let user of this.licenseeInfo.status.regusers){
                if (!user.checked) continue;
                if (model.email && model.email!=='*'){
                    user.email = model.email;
                }
                if (model.product && model.product!=='*'){
                    const product_feature = model.product.split('/');
                    user.product = product_feature[0];
                    user.feature = product_feature[1];
                }
                if (model.keytype!=='*'){
                    const [_oldkey,oldvalue] = this.key2type_n_value(user.key);
                    if (model.keytype==='anypc'){
                        user.key = 'anypc';
                    }else{
                        if (model.keyvalue!=='*'){
                            if (model.keytype=='mac'){
                                user.key = 'mac:'+model.keyvalue.replace(/[-:]/g,'');
                            }else{
                                user.key = model.keytype+':'+model.keyvalue;
                            }
                        }else{
                            user.key = model.keytype+':'+oldvalue;
                        }
                    }
                }
                if (model.seccode!=='*'){
                    user.seccode = model.seccode;
                }
                // if (model.hostname_re!=='*'){
                //     user.hostname_re = model.hostname_re;
                // }
                // if (model.ipaddr_re!=='*'){
                //     user.ipaddr_re = model.ipaddr_re;
                // }
            }
            this.licService.revise_enduser(this.curruser.email,this.seldLicensee,this.licenseeInfo.status.regusers)
            .subscribe(()=>{
                this.alertService.success('user registration revised. page reload maybe needed.');
                this.ngOnInit();
            },(err:Error)=>{
                this.alertService.error(err);
            });
        });
    }
    deleteEndUser(){
        this.alertService.clear();
        let cnt = 0;
        for(let i = 0 ; i < this.licenseeInfo.status.regusers.length ; i++){
            const user = this.licenseeInfo.status.regusers[i];
            if (user.checked){
                this.licenseeInfo.status.regusers.splice(i--,1);
                cnt++;
            }
        }
        if (cnt<=0) return;
        this.licService.revise_enduser(this.curruser.email,this.seldLicensee,this.licenseeInfo.status.regusers)
        .subscribe(()=>{
            this.alertService.success(cnt+' users deleted. page reload maybe needed.');
            this.ngOnInit();
        },(err:Error)=>{
            this.alertService.error(err);
        });
    }
    clicCode:string = '';
    issueClicCode(){
        if (!this.licenseeInfo.status.regusers.some(user=>user.checked)) return;
        this.clicCode = '';
        this.licService.issue_cliccode(this.curruser.email,this.seldLicensee,this.licenseeInfo.status.regusers)
        .subscribe((dro:IssueCLicCodeDroI)=>{
            // we need to make DRO object, instead of just receiving a string,
            // because an intercepter (?) seems to do JSON.parse() for incomming data.
            this.clicCode = dro.cliccode;
        },(err:Error)=>{
            this.alertService.error(err);
        });
    }
    clearClicCode(){
        this.clicCode = '';
    }
    get licmgr_allchecked():boolean{
        return this.licenseeInfo.status?.licmgrs.every(licmgr=>licmgr.checked);
    }
    get licmgr_somechecked():boolean{
        return this.licenseeInfo.status?.licmgrs.filter(licmgr=>licmgr.checked).length>0 &&
            !this.regenduser_allchecked;
    }
    licmgr_sel_pressed(b:boolean):void{
        this.alertService.clear();
        this.licenseeInfo.status?.licmgrs.forEach(licmgr=>licmgr.checked = b);
    }
    addLicMgr(){
        this.alertService.clear();
        const model = <RegLicMgrDialogI>{
            email1: '',
            email2: '',
            email3: ''
        };
        const dialog = this.dialogFactory.open(RegLicMgrDialogComponent,{
            width: '400px', data: model});
        dialog.afterClosed().subscribe((model:RegLicMgrDialogI|null)=>{
            if (!model) return;
            const emails = [model.email1,model.email2,model.email3];
            let cnt = 0;
            for(const email of emails){
                if (email){
                    this.licenseeInfo.status.licmgrs.push({email: email, checked: true});
                    cnt++;
                }
            }
            if (cnt>0){
                this.licService.revise_licmgr(this.curruser.email,this.seldLicensee,this.licenseeInfo.status.licmgrs)
                .subscribe(()=>{
                    this.alertService.success('license manager revised. page reload maybe needed.');
                    this.ngOnInit();
                },(err:Error)=>{
                    this.alertService.error(err);
                });
            }
        });
    }
    deleteLicMgr(){
        this.alertService.clear();
        let cnt = 0;
        for(let i = 0 ; i < this.licenseeInfo.status.licmgrs.length ; i++){
            const licmgr = this.licenseeInfo.status.licmgrs[i];
            if (licmgr.checked){
                if (licmgr.email===this.curruser.email.toLocaleLowerCase()){
                    licmgr.checked = false;
                    this.alertService.error('cannot delete yourself. ask other managers to delete you.');
                    return;
                }
                cnt++;
            }
        }
        if (cnt<=0) return;
        for(let i = 0 ; i < this.licenseeInfo.status.licmgrs.length ; i++){
            const licmgr = this.licenseeInfo.status.licmgrs[i];
            if (licmgr.checked){
                this.licenseeInfo.status.licmgrs.splice(i--,1);
            }
        }
        this.licService.revise_licmgr(this.curruser.email,this.seldLicensee,this.licenseeInfo.status.licmgrs)
        .subscribe(()=>{
            this.alertService.success('license manager revised. page reload maybe needed.');
            this.ngOnInit();
        },(err:Error)=>{
            this.alertService.error(err);
        });
    }
    reportUsage(){
        /*
        If the data is larger, we may need to use real 'download' scheme, with progress report,
        like we do in downloading function in Explorer.
        However, the report data here is small enough, so apply simpler way.
        we get csv text content in a string, in a standard POST request with dto/dro object,
        then store the csv text in a local file using saveAs().
        */
        this.licService.report_usage(this.curruser.email,this.seldLicensee)
        .subscribe((dro:ReportUsageDroI)=>{
            const blob1 = new Blob([dro.toolusage_csv], {type: "text/csv;charset=utf-8"});
            saveAs(blob1,'gem_toolusage.csv');
            const blob2 = new Blob([dro.recentuser_csv],{type: "text/csv;charset=utf-8"});
            saveAs(blob2,'gem_recentuser.csv');
        },(error)=>{
            this.alertService.error(error);
        });

    }
}
