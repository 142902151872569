import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Register1Component } from './loginmenu/register1.component';
import { Register2Component } from './loginmenu/register2.component';
import { Forgotpw1Component } from './loginmenu/forgotpw1.component';
import { Forgotpw2Component } from './loginmenu/forgotpw2.component';
import { LoggedoutComponent } from './loginmenu/loggedout.component';
import { ExplorerComponent } from './explorer/explorer.component';
import { LoggedInUserOnly } from './helper/canactivate';
import { ProfileComponent } from './settingmenu/profile/profile.component';
import { ChangeUnameComponent } from './settingmenu/profile/chguname.component';
import { ChangeEmailComponent } from './settingmenu/profile/chgemail.component';
import { ChangePasswordComponent } from './settingmenu/profile/chgpw.component';
import { DeleteAccountComponent } from './settingmenu/profile/deleteacct.component';
import { PreferenceComponent } from './settingmenu/preference.component';
import { LoginComponent } from './loginmenu/login.component';
import { QuotaComponent } from './shoppingmenu/quota.component';
import { ManageGroupComponent } from './settingmenu/mnggrp.component';
import { GgvComponent } from './gemgv/ggv.component';
import { GckComponent } from './gemcheck/gck.component';
import { ManageLicenseComponent } from './settingmenu/mnglic/mnglic.component';
import { GemLpbAppComponent } from './shoppingmenu/gemlpbapp.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
     // ページグループを module 化して、lazy loading する方法が次のページに例があり。
     // https://jasonwatmore.com/post/2020/04/28/angular-9-user-registration-and-login-example-tutorial
  { path: 'register1', component: Register1Component },
  { path: 'register2', component: Register2Component },
  { path: 'forgotpw1', component: Forgotpw1Component },
  { path: 'forgotpw2', component: Forgotpw2Component },
  { path: 'loggedout', component: LoggedoutComponent },
  { path: 'explorer', component: ExplorerComponent, canActivate: [LoggedInUserOnly]},
  { path: 'tool', children:[
    { path: 'gemgv', component: GgvComponent, canActivate: [LoggedInUserOnly]},
    { path: 'gemcheck', component: GckComponent, canActivate: [LoggedInUserOnly]},
  ]},
  { path: 'shopping', children:[
    { path: 'quota', component: QuotaComponent, canActivate: [LoggedInUserOnly]},
    { path: 'gemlpbapp', component: GemLpbAppComponent, canActivate: [LoggedInUserOnly]},
  ]},
  { path: 'setting', children:[
    { path: 'profile', component: ProfileComponent, canActivate: [LoggedInUserOnly]},
    { path: 'chguname', component: ChangeUnameComponent, canActivate: [LoggedInUserOnly]},
    { path: 'chgemail', component: ChangeEmailComponent, canActivate: [LoggedInUserOnly] },
    { path: 'chgpw', component: ChangePasswordComponent, canActivate: [LoggedInUserOnly] },
    { path: 'deleteacct', component: DeleteAccountComponent, canActivate: [LoggedInUserOnly] },
    //
    { path: 'preference', component: PreferenceComponent, canActivate: [LoggedInUserOnly]},
    { path: 'mnggroup', component: ManageGroupComponent, canActivate: [LoggedInUserOnly] },
    //
    { path: 'mnglic', component: ManageLicenseComponent, canActivate: [LoggedInUserOnly] },
  ]},
  { path: '**', redirectTo: '' } // '**' means otherwise
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
