<header1></header1>
<div class="page-body">
    <div class="page-container-medium">
        <mat-grid-list cols="1">
            <mat-card>
                <mat-card-title>
                    Manage Tool License
                </mat-card-title>
                <mat-card-content>
                    <div style="padding:3px;">
                        <div style="margin-left:0;">
                            <table mat-table [dataSource] = "licensees" class="mat-elevation-z8 licenseetbl">
                                <ng-container matColumnDef="licensee">
                                    <mat-radio-group [(ngModel)]="seldLicensee" >
                                        <td mat-cell *matCellDef="let licensee;">
                                            <mat-radio-button [value]="licensee" [checked]="licensee===seldLicensee">
                                                {{licensee}}
                                            </mat-radio-button>
                                        </td>
                                    </mat-radio-group>
                                </ng-container>
                                <tr mat-row *matRowDef="let row; columns: licenseeTableCols;"></tr>
                            </table>
                            <div *ngIf="seldLicensee===have_liccode">
                                <mat-form-field>
                                    <textarea type="text" matInput placeholder="paste server license code here"
                                        [(ngModel)]="server_liccode">
                                    </textarea>
                                </mat-form-field>
                                <mat-error *ngIf="server_liccode_error">
                                    {{server_liccode_error}}
                                </mat-error>
                                <div>
                                    <button mat-raised-button color="accent"
                                        matTooltip="register the license code"
                                        (click)="makeNewLicensee()"> 
                                        Register
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="seldLicensee && seldLicensee!==have_liccode">
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            Manage License File
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            {{licenseeInfo.eff_lic_cnt}} licenses currently effective
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div>
                                        <table mat-table [dataSource] = "licenseeInfo.status.server_lics" class="mat-elevation-z8 wide-table">
                                            <ng-container matColumnDef="product">
                                                <th mat-header-cell *matHeaderCellDef>Tool</th>
                                                <td mat-cell *matCellDef="let slic">{{slic.product}}/{{slic.feature}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="seq">
                                                <th mat-header-cell *matHeaderCellDef>Seq</th>
                                                <td mat-cell *matCellDef="let slic">{{slic.seq}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="first-day">
                                                <th mat-header-cell *matHeaderCellDef>First-day</th>
                                                <td mat-cell *matCellDef="let slic">{{slic.first_date_str}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="last-day">
                                                <th mat-header-cell *matHeaderCellDef>Last-day</th>
                                                <td mat-cell *matCellDef="let slic">{{slic.last_date_str}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="support">
                                                <th mat-header-cell *matHeaderCellDef>Support</th>
                                                <td mat-cell *matCellDef="let slic">{{slic.support_last_date_str}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="licensee">
                                                <th mat-header-cell *matHeaderCellDef>Licensee</th>
                                                <td mat-cell *matCellDef="let slic">{{slic.licensee}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="key">
                                                <th mat-header-cell *matHeaderCellDef>Key</th>
                                                <td mat-cell *matCellDef="let slic">{{slic.key}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="problem">
                                                <th mat-header-cell *matHeaderCellDef>Error</th>
                                                <td mat-cell *matCellDef="let slic">{{slic.problem}}</td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="slicTableCols"></tr>
                                            <tr mat-row *matRowDef="let row; columns: slicTableCols;"></tr>
                                        </table>
                                        <mat-form-field>
                                            <textarea type="text" matInput
                                                [(ngModel)]="licenseeInfo.status.server_liccode">
                                            </textarea>
                                        </mat-form-field>
                                        <mat-error *ngIf="server_liccode_error">
                                            {{server_liccode_error}}
                                        </mat-error>
                                        <div>
                                            <button mat-raised-button color="accent"
                                                (click)="reviseLicFile()"> 
                                                Revise
                                            </button>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            Register End Users
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            {{licenseeInfo.reg_enduser_cnt}} end-users registered
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div>
                                        <table mat-table [dataSource] = "licenseeInfo.status.regusers"
                                            class="mat-elevation-z8">
                                            <ng-container matColumnDef="product">
                                                <th mat-header-cell *matHeaderCellDef fxFlex="100px">
                                                    <mat-checkbox
                                                        [checked]="regenduser_allchecked"
                                                        [indeterminate]="regenduser_somechecked"
                                                        (change)="regenduser_sel_pressed($event.checked)">
                                                        Tool
                                                    </mat-checkbox>
                                                </th>
                                                <td mat-cell mat-cell *matCellDef="let user" fxFlex="100px">
                                                    <mat-checkbox
                                                        [checked]="user.checked"
                                                        (change)="user.checked=!user.checked">
                                                        {{user.product}}/{{user.feature}}
                                                    </mat-checkbox>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="email">
                                                <th mat-header-cell *matHeaderCellDef fxFlex="100px">Email</th>
                                                <td mat-cell *matCellDef="let user" fxFlex="100px">{{user.email}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="seccode">
                                                <th mat-header-cell *matHeaderCellDef>Sec</th>
                                                <td mat-cell *matCellDef="let user">{{user.seccode}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="key">
                                                <th mat-header-cell *matHeaderCellDef>Key</th>
                                                <td mat-cell *matCellDef="let user">{{user.key}}</td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="reguserTableCols"></tr>
                                            <tr mat-row *matRowDef="let row; columns: reguserTableCols;"></tr>
                                        </table>
                                        <div class="button-area">
                                            <button mat-raised-button color="accent"
                                                (click)="addEndUser()"> 
                                                Add
                                            </button>
                                            <button mat-raised-button color="primary"
                                                (click)="editEndUser()"> 
                                                Edit
                                            </button>
                                            <button mat-raised-button color="primary"
                                                (click)="issueClicCode()">
                                                Issue Client License
                                            </button>
                                            <button mat-raised-button color="warn"
                                                (click)="deleteEndUser()"> 
                                                Delete
                                            </button>
                                        </div>
                                        <div *ngIf='clicCode' class="mat-elevation-z8 clic-div">
                                            <div>
                                                <button mat-icon-button class="clic-clear-button" (click)="clearClicCode()">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </div>
                                            <div>
                                                Send the codes to end users, using copy-and-paste operation, please.
                                            </div>
                                            <mat-form-field>
                                                <textarea type="text" matInput readonly [value]="clicCode">
                                                </textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            License Managers
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            {{licenseeInfo.licmgr_cnt}} license managers currently assigned
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div>
                                        <table mat-table [dataSource] = "licenseeInfo.status.licmgrs"
                                            class="mat-elevation-z8 licmgrtbl"
                                            >
                                            <ng-container matColumnDef="email">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    <mat-checkbox
                                                        [checked]="licmgr_allchecked"
                                                        [indeterminate]="licmgr_somechecked"
                                                        (change)="licmgr_sel_pressed($event.checked)">
                                                        Email
                                                    </mat-checkbox>
                                                </th>
                                                <td mat-cell *matCellDef="let licmgr;">
                                                    <mat-checkbox
                                                        [checked]="licmgr.checked"
                                                        (change)="licmgr.checked=!licmgr.checked">
                                                        {{licmgr.email}}
                                                    </mat-checkbox>
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="licmgrTableCols"></tr>
                                            <tr mat-row *matRowDef="let row; columns: licmgrTableCols;"></tr>
                                        </table>
                                        <div class="button-area">
                                            <button mat-raised-button color="accent"
                                                (click)="addLicMgr()"> 
                                                Add
                                            </button>
                                            <button mat-raised-button color="warn"
                                                (click)="deleteLicMgr()">
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            Usage Report
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div>
                                        <div class="button-area">
                                            <button mat-raised-button color="accent"
                                                (click)="reportUsage()"> 
                                                Download Tool Usage Report In CSV File
                                            </button>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </mat-grid-list>
    </div>
</div>
<div class="footer">
	<a class="footer" href="https://gemdt.com" target="_top">Gem Design Technologies, Inc.</a>
</div>
