import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from './alert.service';
    // webpack.config.js > resolve に、@ : src/appとあるので、src/app/_servicesの
    // 下から探される。import {AlertService} from '../_services' より、自分の位置に依存
    // しない分だけ少しスマート。

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['alert.component.css']
})
export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: {type: string, text: string};

    constructor(private alertService: AlertService) { }

    ngOnInit() {
        this.subscription = this.alertService.getAlert()
            .subscribe((message:{type:string, text:string}) => {
                // type = 'success' | 'error'
                this.message = message;
        });
    }

    ngOnDestroy() {
        if (this.subscription){
            this.subscription.unsubscribe();
        }
    }
}