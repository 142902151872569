import { UserDroI } from 'gemlib/dist_fe/dtodro';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AcctService } from '@app/acctservice/acct.service';


@Component({
    templateUrl: 'profile.component.html',
    styleUrls:['profile.component.css']
})
export class ProfileComponent {
    form: FormGroup;
    curruser: UserDroI;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private acctService: AcctService
        ){
        }
    ngOnInit() {
        this.curruser = this.acctService.curruser;
        this.form = this.formBuilder.group({
            email: [this.curruser.email],
            password: ['(undisclosed)'],
            username: [this.curruser.username]
        });
    }
    onSubmit() {
        this.router.navigate(['/']);
    }
}
