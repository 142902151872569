<header0></header0>
<div class="page-body">
    <div class="page-container-small">
        <mat-grid-list cols="1">
            <mat-card>
                <mat-card-title class="mat-title">
                    Registration step 1 of 2
                </mat-card-title>
                <mat-card-content>
                    <form [formGroup]="form" (ngSubmit)="ngOnSubmit()">
                        <div>
                            <mat-form-field>
                                <mat-label>E-mail</mat-label>
                                <input type="email" matInput
                                formControlName="email">
                                <mat-error *ngIf="fieldError('email')">
                                    {{fieldError('email')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div>
                            <!-- mat-checkbox cannot be put in mat-form-field -->
                            <mat-checkbox #agreed>
                                <!--
                                    2 ways to get the status in template.
                                    (1) <mat-checkbox #agreed> ==> agree.checked
                                    (2) <mat-checkbox [formControl]="agreed" ==> agree.value
                                -->
                                Concent to <a href="assets/user_agreement_latest.txt"> gem-lpb.com User Agreement</a>
                            </mat-checkbox>
                        </div>
                    </form>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button color="primary" [disabled]="!agreed.checked"(click)="ngOnSubmit()">
                        Next
                    </button>
                </mat-card-actions>
            </mat-card>
        </mat-grid-list>
    </div>
</div>
<div class="footer">
	<a class="footer" href="https://gemdt.com" target="_top">Gem Design Technologies, Inc.</a>
</div>
