import { QuotaPlanDroI, UserDroI } from 'gemlib/dist_fe/dtodro';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, Validators, ValidationErrors } from '@angular/forms';
import { AlertService} from '@app/helper/alert.service';
import { Router } from '@angular/router';
import { DataService } from '@app/dataservice/data.service';
import { AcctService, Group } from '@app/acctservice/acct.service';
import { Gem } from 'gemlib/dist_fe/gemconst';


class PeriodPlan{
    constructor(
        public planDescr:string
    ){}
}

@Component({
    templateUrl: 'quota.component.html',
    styleUrls:['quota.component.css']
})
export class QuotaComponent implements OnInit {
    curruser: UserDroI;

    // disk quota plan table
    quotaPlans: QuotaPlanDroI[] = [];
    quotaPlanTableCols=['planDescr','priceDescr'];
    seldQuotaPlan: QuotaPlanDroI; // maintained in template

    // period plan
    periodPlans: PeriodPlan[] = [
        new PeriodPlan(Gem.one_month),
        new PeriodPlan(Gem.three_months),
        new PeriodPlan(Gem.one_year),
        new PeriodPlan(Gem.three_years)
    ];
    periodPlanTableCols=['planDescr'];
    seldPeriodPlan = this.periodPlans[2]; // maintained

    // groups
    fornewgroup: boolean = true;
    newgroupform: FormGroup;
    groups: Group[] = [];
    groupTableCols = ["groupname","mbrcnt","usedkb","totalkb","expire"];
    seldGroup:Group = null; // maintained in template

    constructor(
        private fb: FormBuilder,
        private alertService: AlertService,
        private acctService: AcctService,
        private dataService: DataService,
        private route: Router,
        ){
            this.curruser = acctService.curruser;
        }
    ngOnInit() {
        const that = this;
        const grpnamevalidator = function(name:AbstractControl):ValidationErrors|null{
            if (!new RegExp('^[a-zA_Z][a-zA_Z0-9_]{0,15}$').test(name.value)){
                return {'formaterr':true};
            }else if (that.groups && that.groups.filter(grp=>grp.groupname===name.value).length>0){
                return {'conflict':true};
            }else return null;
        };
        this.newgroupform = this.fb.group({
            newgroupname: ['',[Validators.required,grpnamevalidator]]
        });
        this.dataService.get_quota_plans().subscribe((plans:QuotaPlanDroI[])=>{
            this.quotaPlans = plans;
            this.acctService.get_groups().subscribe((groups:Group[])=>{
                this.groups = groups;
                this.fornewgroup=false;
                this.seldGroup = groups[0];
                this.quotaPlans.forEach(plan=>{
                    if (plan.quotakb===this.seldGroup.diskplan.quotakb){
                        this.seldQuotaPlan = plan;
                    }
                });
            },(err:Error)=>{
                this.alertService.error(err);
                console.log('error for get_group')
            },()=>{
            });
        },(err:Error)=>{
            this.alertService.error(err);
            console.log('error for get_quota_plans')
        },()=>{
        });
    }
    fieldError(field:string):string{
        const control =  this.newgroupform.controls[field];
        if (!control.errors) return '';
        if (field==='newgroupname'){
            if (control.errors.required) return 'required';
            else if (control.errors.conflict) return 'conflict to existing group';
            else return 'format error';
        }else return 'bug: unexpected field = '+field;
    }

    contactUsPressed(){
        if (this.fornewgroup){
            if (!this.newgroupform.controls.newgroupname.value){
                this.newgroupform.controls.newgroupname.markAsTouched();
                // to show 'required' error if user selects 'for new group',
                // not touching 'group name', and press 'contact us'
                this.alertService.error('group name is required');
                return;
            }
        }
        this.alertService.clear();
        
        const groupname:string = this.fornewgroup?
            this.newgroupform.controls.newgroupname.value:
            this.seldGroup.groupname;
        if (this.seldQuotaPlan.priceDescr === 'Free' && groupname !== this.curruser.username){
            this.alertService.error('Free plan is for private use only, sorry.');
            return;
        }
        this.acctService.quota_purchase_inquiry(
            this.fornewgroup,groupname,
            this.seldQuotaPlan.planDescr,
            this.seldPeriodPlan.planDescr)
        .subscribe(()=>{
            this.alertService.success('an email is sent to '+this.curruser.email,true);
        },
        (err)=>{
            this.alertService.error(err);
        });
    }
    activate(buf:string) {
        this.alertService.clear();
        console.log(buf);
        if (!buf){
            this.alertService.error("service code is not found");
            return;
        }
        const idx1 = buf.indexOf('info=');
        const idx2 = buf.indexOf('signature=');
        if (idx1<0){
            this.alertService.error('keyword "info=" not found in the service code.');
            return;
        }
        if (idx2<0){
            this.alertService.error('keyword "signature=" not found in the service code.');
            return;
        }
        const info:string = buf.substring(idx1+'info='.length).split(/\n/)[0];
            // GEMPROD$Gem Design Tech$gemdt$diskquota/10G$network$2020-11-01$2021-10-31$2021-10-31$
        const sign:string = buf.substring(idx2+'signature='.length).split(/\n/)[0];
            // $2b$10$GI7sxRo/2ebyY/u5m3Moeel1nGSCxUCqc3mJ6IN1AFAL5WYP6mWeq
        this.acctService.change_groupdiskplan(info,sign).
        subscribe((group:Group)=>{
            this.alertService.success('diskplan activated.');

        },(err)=>{
            this.alertService.error(err);
        });
    }
}
